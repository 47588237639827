<template lang="pug">
include ../../../../Bemto
row
  cell(
    :cols="['12', '6-sm', '4-lg']"
  )
    d-control-input(
      :clue='tooltips.senderCourierService'
      :name="'senderCourierService'"
      :type="'text'"
      :input-label="_('Укажите название курьерской службы')"
      @input="$emit('courier-service', senderCourierService)"
      v-model='senderCourierService'
    )
  cell(
    :cols="['12', '6-sm', '4-lg']"
  )
    d-control-input(
      :clue='tooltips.senderTrack'
      :name="'senderTrack'"
      :type="'text'"
      :input-label="_('Укажите трек номер')"
      @input="$emit('sender-track', senderTrack)"
      v-model='senderTrack'
    )
  cell(
    :cols="['12', '6-sm', '4-lg']"
  )
    //- d-control-input(
    //-   :name="'senderPickupDate'"
    //-   :type="'datetime-local'"
    //-   :rules="'required'"
    //-   :input-label="_('Укажите дату забора груза *')"
    //-   @input="$emit('pickup-date', senderPickupDate)"
    //-   v-model='senderPickupDate'
    //- )
    label(
      for='senderPickupDate'
    )
      row(
        :space="'xs'"
      )
        cell
          text-bemto.pa--b_xxs(
            :weight="'medium'"
            :size="['xs']"
            :tag="'p'"
          ) {{ _('Укажите дату забора груза') }}
        cell
          +b.el-clue(
              v-if='tooltips.senderPickupDate'
              @mouseenter='clueText = true'
              @mouseleave='clueText = false'
            ) ?
              +e.clue-text(
                v-if='clueText'
              )
                slot {{ tooltips.senderPickupDate }}
    label.error-date-label(
      for='senderPickupDate'
      v-if='dataError'
    )
      text-bemto(
        :weight="'medium'"
        :size="['xs']"
        :tag="'p'"
      ) {{ _('senderPickupDate is not valid') }}
    date-picker.datepick(
      :default-value="new Date()"
      :format="'YYYY-MM-DD'"
      :value-type="'format'"
      :disabled-date="disabledBeforeToday"
      :class="{'date-error': dataError}"
      :name="'senderPickupDate'"
      v-model='senderPickupDate'
      @input="$emit('pickup-date', senderPickupDate)"
      type='date'
    )
      template(#icon-calendar)
        +b.P.icon-calendar

</template>

<script>
export default {
  props: ['courier', 'track', 'date', 'dataError', 'tooltips'],
  name: 'courier-form',
  data() {
    return {
      clueText: false,
      senderCourierService: this.courier,
      senderTrack: this.track,
      senderPickupDate: this.date,
    }
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
  },
}
</script>
