import ADMINISTRATOR_ROUTES from './administrator/router'
import MANAGER_ROUTES from './manager/router'
import USER_ROUTES from './user/router'
import { COMMON_ROUTES } from './common'

export const checkPermissions = router => {
  router.beforeEach((to, from, next) => {
    const timeout = 100
    setTimeout(() => {
      const { isProfile, role } = window
      if (isProfile) {
        const { meta: { permissions = [] } } = to
        const isAccessed = permissions.includes(role)
        if (isAccessed) {
          next()
        } else {
          next({ name: 'person-info' })
        }
      }
    }, timeout)
  })
}

export function createRouter({ Router }) {
  return new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }
      return { x: 0, y: 0 }
    },
    routes: [
      ...ADMINISTRATOR_ROUTES,
      ...MANAGER_ROUTES,
      ...USER_ROUTES,
      ...COMMON_ROUTES,
    ],
  })
}
