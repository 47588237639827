<template lang="pug">
include ../../../../Bemto
div
  +b.el-zebra__element--black.ma--t_sm
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Страна') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderCountry || '-' }}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Область/штат') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderRegion || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Город') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderCity || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Улица') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderStreet || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Номер здания') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderHouse || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Контактное лицо') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderContactPerson || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Телефон') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderPhone || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Дата готовности груза') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderDate || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Наличие дока') }}
      cell
        text-bemto(
          :style="{transform: 'scale(2)'}"
          :weight="'bold'"
          :c="'green'"
          :tag="'p'"
        ) {{ senderDockAvailability ? '+' : '-'}}
</template>

<script>
export default {
  name: 'pickup-panel',
  props: ['senderCountry', 'senderRegion', 'senderCity', 'senderStreet', 'senderHouse', 'senderContactPerson', 'senderPhone', 'senderDate', 'senderDockAvailability'],
}
</script>
