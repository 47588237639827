<template lang="pug">
include ../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.TEXT-BEMTO(
      :weight="'bold'"
      :size="'md'"
      :tag="'i'"
    )
  +e.body
    +b.ROW(
      :appearance='["spaced"]'
      :space="'sm'"
      :align="'center'"
      :justify="'center'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['9']"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :height="'md'"
          :size="'lg'"
          :align="'center'"
          :c="'black'"
          :tag="'H4'"
        ) {{ title }}
      +b.CELL(
        :cols="['9']"
      )
        +b.TEXT-BEMTO(
          :weight="'medium'"
          :height="'md'"
          :size="'md'"
          :align="'center'"
          :c="'hask'"
          :tag="'H4'"
        ) {{ order }}
      +b.CELL(
        :cols="['9']"
      )
        +b.TEXT-BEMTO(
          :weight="'medium'"
          :height="'md'"
          :size="'md'"
          :align="'center'"
          :c="'black'"
          :tag="'H5'"
        ) {{ text }}
</template>

<script>
export default {
  name: 'SuccessBidModal',
  props: {
    /**
     * An should be shown text, but
     * the backend did not send a
     * message. Backend forgot send
     * text, but I need to say
     * something.
     */
    objectItem: {},
  },
  computed: {
    /**
     * Handlers that affect the
     * display of the component.
     */
    title() {
      return this.objectItem.title
    },
    order() {
      return this.objectItem.order
    },
    text() {
      return this.objectItem.text
    },
  },
}
</script>
