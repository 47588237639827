import CommonAvatar from './avatars/CommonAvatar'
import FileDemonstration from './uploads/FileDemonstration'
import FileUploader from './uploads/FileUploader'
import ElChatBtn from './chat/ElChatBtn'
import ElChatField from './chat/ElChatField'
import ElHeader from './ElHeader'
import ElNavigations from './ElNavigations'

export default function install(Vue) {
  Vue.component(CommonAvatar.name, CommonAvatar)
  Vue.component(FileDemonstration.name, FileDemonstration)
  Vue.component(FileUploader.name, FileUploader)
  Vue.component(ElHeader.name, ElHeader)
  Vue.component(ElNavigations.name, ElNavigations)
  Vue.component(ElChatBtn.name, ElChatBtn)
  Vue.component(ElChatField.name, ElChatField)
}
