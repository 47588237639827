import commons from './commons'
import controls from './controls'
import forms from './forms'
import modules from './modules'
import pages from './pages'

import EmptyComponent from './EmptyComponent'

export default function iComponents(Vue) {
  Vue.use(commons)
  Vue.use(controls)
  Vue.use(forms)
  Vue.use(modules)
  Vue.use(pages)

  Vue.component(EmptyComponent.name, EmptyComponent)
}
