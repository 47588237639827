<template lang="pug">
include ../Bemto
+b.freshworks(
  :class="freshworksSL(side)"
)
  +e.side--left
    +e.side--move
      +e.logo
        //- +b.picture.PICTURE
        //-   +b.freshworks__side--mobile-logo.IMG(
        //-     :src="'/static/pictures/tb.png'" alt="MB"
        //-   )
        //-   +b.freshworks__side--tablet-logo.IMG(
        //-     v-if="side"
        //-     :src='logo' alt="PC"
        //-   )
        +b.button--vision_fulllink.ROUTER-LINK(
          to="active-orders"
        )
      +e.router-wrapper
        +b.EL-ROUTER(
          :side="side"
        )
  +e.side--top
    +b.EL-HEADER(
      :side="side"
      @toggle-side="toggleSL()"
    )
  +e.content
    +b.freshworks__body
      slot(name="content")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SampleProfile',
  async created() {
    const { isAuthenticated, isProfile } = window

    await this.getStorageValue()

    if (isAuthenticated && isProfile) {
      await this.getAccountDetailsAPI()
    }
  },
  mounted() {
    this.toggleFooter()
  },
  data() {
    return {
      side: true,
      logo: window.LOGO,
    }
  },
  computed: {},
  methods: {
    getStorageValue(from = 'side', to = 'side') {
      const value = sessionStorage.getItem(
        from
      )
      const PARSE = JSON.parse(value)

      if ('boolean' === typeof PARSE) {
        this[to] = PARSE
      }
    },
    toggleSL(to = 'side') {
      this.side = !this.side
      sessionStorage.setItem(to, this.side)
      this.toggleFooter()
    },
    toggleFooter() {
      const footer = document.getElementById('footer')
      if (this.side) {
        footer.classList.add('footer-indents')
      } else {
        footer.classList.remove('footer-indents')
      }
    },
    freshworksSL(side) {
      /* eslint-disable */
      return (side) ? 'freshworks--isactive'
                    : 'freshworks--inactive'
    },
    ...mapActions('personalInfo', [
      'getAccountDetailsAPI',
    ]),
  },
}
</script>
