<template lang="pug">
include ../../../../Bemto
+b.VALIDATION-OBSERVER(
  ref="observer"
  tag="form"
)
  +b.ROW.ma--t_sm(
    :appearance='["spaced"]'
    :space="'sm'"
    :tag="'div'"
  )
    +b.CELL(
      :cols="['12','6-lg']"
      :ref="'res'"
    )
      label(
        for='requisites'
      )
        +b.pa--b_xxs.TEXT-BEMTO(
          :weight="'medium'"
          :size="'xs'"
          :tag="'p'"
        ) {{ _('Укажите Ваши реквизиты *') }}
      label.error-sign-message(
        for='requisites'
        v-if='error'
      )
        +b.pa--b_xxs.TEXT-BEMTO(
          :weight="'medium'"
          :c="'red'"
          :size="'xs'"
          :tag="'p'"
        ) {{ _('This field is required') }}
      textarea.requisites(
        :class="{'sign-error': error}"
        :rules="'required'"
        name='requisites'
        :id="'requisites'"
        v-model='requisites'
      )
    +b.CELL(
      :cols="['12','6-lg']"
    )
      +b.el-canvas--bg_grays.CANVAS(
        style='display: none'
        :ref="'empty'"
        height='140'
        :width='width'
      )
      label
        +b.pa--b_xxs.TEXT-BEMTO(
          :weight="'medium'"
          :size="'xs'"
          :tag="'p'"
        ) {{ _('Место для подписи *') }}
      label.error-sign-message(
        v-if='error'
      )
        +b.pa--b_xxs.TEXT-BEMTO(
          :weight="'medium'"
          :c="'red'"
          :size="'xs'"
          :tag="'p'"
        ) {{ _('This field is required') }}
      +b.el-canvas--bg_grays.CANVAS(
        :class="{'sign-error': error}"
        :ref="'signature'"
        :id="title"
        height='140'
        :width='width'
        @mousedown="beginDrawing"
        @mousemove="keepDrawing"
        @mouseleave="stopDrawing"
        @mouseup="stopDrawing"
        @touchstart="beginDrawing"
        @touchmove="keepDrawing"
      )
      +b.ma--t_sm.BUTTON-BEMTO(
        :config="['anima', 'radius-xs', 'style-grn']"
        :vision="'default'"
        :align="'center'"
        :tag="'button'"
        @event="resetCanvas()"
      )
        +b.pointer--initial.TEXT-BEMTO(
          :config="'uppercase'"
          :weight="'bold'"
          :size="'sm'"
          :c="'white'"
          :tag="'p'"
        ) {{ _("Reset") }}
    +b.CELL(
      :cols="['12', '10-md']"
    )
    +b.CELL(
      :cols="['12', '2-lg']"
    )
      +b.BUTTON-BEMTO(
        :config="['anima', 'radius-xs', 'style-grn']"
        :vision="'default'"
        :align="'center'"
        :tag="'button'"
        @event="preparedBeforeSending()"
      )
        +b.pointer--initial.TEXT-BEMTO(
          :config="'uppercase'"
          :weight="'bold'"
          :size="'sm'"
          :c="'white'"
          :tag="'p'"
        ) {{ _("Подписать") }}
</template>

<script>

import { createContractAPI } from '@/javascript/requests/services/bid.service'

export default {
  name: 'Signature',
  props: {
    objectItem: {},
    title: {},
    id: {},
  },
  mounted() {
    this.canvas = this.$refs.signature.getContext(
      '2d' // Canvas specification
    )
    this.emptyCanvas = this.$refs.empty.getContext('2d')
    this.setWidth()
  },
  data() {
    return {
      error: false,
      width: null,
      emptyCanvas: null,
      requisites: '',
      /** Componen state. */
      isDrawing: false,
      canvas: null,
      x: 0,
      y: 0,
    }
  },
  methods: {
    setWidth() {
      /* eslint-disable prefer-template */
      this.width = Number(window.getComputedStyle(this.$refs.res).width.substring(0, window.getComputedStyle(this.$refs.res).width.length - 2)) - 20 + 'px'
      window.addEventListener('resize', () => {
        this.width = Number(window.getComputedStyle(this.$refs.res).width.substring(0, window.getComputedStyle(this.$refs.res).width.length - 2)) - 20 + 'px'
      })
    },
    preparedBeforeSending() {
      const base64 = this.canvas.canvas.toDataURL()
      if (this.emptyCanvas.canvas.toDataURL() !== base64 && '' !== this.requisites) {
        const data = {
          contract: this.id,
          requisites: this.requisites,
          signature: base64,
        }
        createContractAPI.execute({}, data).then(() => {
          window.location.reload()
        })
      } else {
        this.error = true
      }
    },
    keepDrawing(event) {
      if (true === this.isDrawing) {
        if ('mousemove' === event.type) {
          this.drawLine(
            this.x,
            this.y,
            event.offsetX,
            event.offsetY
          )
          this.x = event.offsetX
          this.y = event.offsetY
        }
        if ('touchmove' === event.type) {
          event.preventDefault()
          event.stopPropagation()
          this.drawLine(
            this.x,
            this.y,
            event.touches[0].clientX - event.target.getBoundingClientRect().x,
            event.touches[0].clientY - event.target.getBoundingClientRect().y
          )
          this.x = event.touches[0].clientX - event.target.getBoundingClientRect().x
          this.y = event.touches[0].clientY - event.target.getBoundingClientRect().y
        }
      }
    },
    stopDrawing(event) {
      if (true === this.isDrawing) {
        this.isDrawing = false
        this.drawLine(
          this.x,
          this.y,
          event.offsetX,
          event.offsetY
        )
        this.x = 0
        this.y = 0
      }
    },
    beginDrawing(event) {
      this.isDrawing = true
      if ('mousedown' === event.type) {
        this.x = event.offsetX
        this.y = event.offsetY
      }
      if ('touchstart' === event.type) {
        event.preventDefault()
        event.stopPropagation()
        this.x = event.touches[0].clientX - event.target.getBoundingClientRect().x
        this.y = event.touches[0].clientY - event.target.getBoundingClientRect().y
      }
    },
    drawLine(x1, y1, x2, y2) {
      this.canvas.beginPath()
      this.canvas.strokeStyle = 'black'
      this.canvas.lineWidth = 1
      this.canvas.moveTo(x1, y1)
      this.canvas.lineTo(x2, y2)
      this.canvas.stroke()
      this.canvas.closePath()
    },
    resetCanvas() {
      this.canvas.clearRect(
        0,
        0,
        this.canvas.canvas.width,
        this.canvas.canvas.height
      )
    },
  },
}
</script>
