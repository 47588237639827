import { getAccountDetailsAPI } from '@/javascript/requests/services/account.service'

export const personalInfo = {
  namespaced: true,
  state: {
    accountDetails: null,
  },
  getters: {
    getAccountDetails: state => state.accountDetails,
  },
  actions: {
    getAccountDetailsAPI({ commit }) {
      return getAccountDetailsAPI.execute().then(({ data: { item } }) => {
        commit('setAccountDetails', item)
      })
    },
  },
  mutations: {
    // eslint-disable-next-line
    setAccountDetails: (state, response) => state.accountDetails = response,
  },
}
