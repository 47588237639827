import { prefixLanguage } from './LANG'

const restPrefixAPI = url => prefixLanguage(`/api/v1${ url}`)

const ENDPOINTS = {
  AUTHORIZATION: restPrefixAPI('/auth/login/'),
  LOGOUT: restPrefixAPI('/auth/logout/'),
  REGISTRATION: restPrefixAPI('/auth/register/'),
  RESTORE_ACCOUNT: restPrefixAPI('/auth/password-reset/'),
  CREATE_PASSWORD: restPrefixAPI('/auth/password-reset-confirm/'),
  VERIFICATION_CODE: restPrefixAPI('/auth/verification-code/'),
  CHANGE_PASSWORD: restPrefixAPI('/account/change-password/'),
  CREATE_EMAIL: restPrefixAPI('/auth/send-code-email/'),
  CHANGE_EMAIL: restPrefixAPI('/account/change-email/'),
  COUNTRY_LIST: restPrefixAPI('/countries/get-countries/'),
  USER_TYPE: restPrefixAPI('/auth/get-user-type/'),
  TOKEN: restPrefixAPI('/account/get-token/'),

  /** Account. */
  ACCOUNT_DETAILS: restPrefixAPI('/account/detail/'),
  SET_AVATAR: restPrefixAPI('/account/change-avatar/'),
  ADD_PHONE: restPrefixAPI('/account/create-phone/'),
  DEL_PHONE: restPrefixAPI('/account/delete-phone/{id}/'),

  /** Balance. */
  WITHDRAW_REQUEST: restPrefixAPI('/balance/create-payments/'),
  GET_BALANCE: restPrefixAPI('/balance/history/'),

  /** Notifications. */
  GET_NOTIFICATIONS: restPrefixAPI('/notifications/get-notifications/'),
  GET_NOTIFICATIONS_NEW: restPrefixAPI('/notifications/get-notifications/?is_new=1'),
  PATCH_NOTIFICATIONS: restPrefixAPI('/notifications/read-notifications/{id}/'),

  /** Bids. */
  CREATE_BID_SEARCH: restPrefixAPI('/bids/create-bid-search/'),
  CREATE_REDEMPTION: restPrefixAPI('/bids/create-bid-redemption/'),
  CREATE_PAYMENT: restPrefixAPI('/bids/create-bid-payment/'),
  GET_ACTIVE_BID: restPrefixAPI('/bids/get-active-bids/'),
  GET_COMPLETED_BID: restPrefixAPI('/bids/get-completed-bids/'),
  GET_ORDER_INFO: restPrefixAPI('/bids/get-bid/{id}/'),
  CREATE_TRANSPORTATION: restPrefixAPI('/bids/create-bid-transportation/'),
  GET_COUNTRIES: restPrefixAPI('/countries/get-countries/'),
  GET_AMAZON_COUNTRIES: restPrefixAPI('/amazon/get-countries/{destination}/'),
  GET_AMAZON_REGIONS: restPrefixAPI('/amazon/get-regions/{country}/'),
  GET_REGION_WAREHOUSES: restPrefixAPI('/amazon/get-warehouses-by-region/{region}/'),
  GET_WAREHOUSES: restPrefixAPI('/amazon/get-warehouses-by-country/{country}/?wt={type}'),
  DELETE_BID: restPrefixAPI('/bids/delete-bid/{id}/'),

  /** Text. */
  TEXT_SEARCH: restPrefixAPI('/bids/page-search/'),
  TEXT_RANSOM: restPrefixAPI('/bids/page-redemption/'),
  TEXT_PAYMENT: restPrefixAPI('/bids/page-payment/'),
  TEXT_TRANSPORT: restPrefixAPI('/bids/page-transportation/'),

  /** Selector. */
  GET_SELECTOR: restPrefixAPI('/get-selects/'),

  /** Contracts. */
  GET_CONTRACTS: restPrefixAPI('/contracts/get-contracts/'),
  GET_SIGNED_CONTRACTS: restPrefixAPI('/contracts/get-signed-contracts/'),
  CREATE_CONTRACT: restPrefixAPI('/contracts/create/'),

  /** Chat. */
  CHAT_LIST: restPrefixAPI('/chat/list/'),
  CREATE_CHAT: restPrefixAPI('/chat/create/'),
  GET_CHAT: restPrefixAPI('/chat/get-chat/{bid}/'),
  MESSAGE_CREATE: restPrefixAPI('/chat/messages/create/'),
  GET_MESSAGES_LIST: restPrefixAPI('/chat/messages/list/{chat}/'),
  GET_MESSAGES_READ: restPrefixAPI('/chat/messages/read/{chat}/'),
}

export { ENDPOINTS }
