import { createResource } from '../resources/resource'
import { ENDPOINTS } from '../ENDPOINTS'

export const getTokenAPI = createResource(
  ENDPOINTS.TOKEN
)
export const getCountryListAPI = createResource(
  ENDPOINTS.COUNTRY_LIST
)
export const getSelectListAPI = createResource(
  ENDPOINTS.GET_SELECTOR
)
export const getUserTypeAPI = createResource(
  ENDPOINTS.USER_TYPE
)
export const getContractsAPI = createResource(
  ENDPOINTS.GET_CONTRACTS
)
export const getChatListAPI = createResource(
  ENDPOINTS.CHAT_LIST
)
export const getSignedContractsAPI = createResource(
  ENDPOINTS.GET_SIGNED_CONTRACTS
)
export const getMessagesReadAPI = createResource(
  ENDPOINTS.GET_MESSAGES_READ
)
