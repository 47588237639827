import { createResource, postResource, dynamicUrl } from '../resources/resource'
import { ENDPOINTS } from '../ENDPOINTS'

export const postCreateWithdrawRequestAPI = createResource(
  ENDPOINTS.WITHDRAW_REQUEST,
  postResource
)
export const getEnrollmentListAPI = dynamicUrl(
  ENDPOINTS.GET_BALANCE
)
