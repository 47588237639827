<template lang="pug">
include ../../../../Bemto
div
  +b.el-zebra__element--black.ma--t_sm
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Страна') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderCountry || '-' }}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _(' Контактное лицо') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderContactPerson || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Телефон') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ senderPhone || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Дата готовности груза') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ senderDate || '-'}}
</template>

<script>
export default {
  name: 'provider-panel',
  props: ['senderCountry', 'senderPhone', 'senderContactPerson', 'senderDate'],
}
</script>
