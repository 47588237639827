<template lang="pug">
  component(
    :is="tag"
    :class="genetatedClass"
  )
    slot
</template>
<script>
import BemtoMixin from './mixins/BemtoMixin'

export default {
  name: 'IconBemto',
  mixins: [
    BemtoMixin,
  ],
  props: {
    block: {
      default: () => 'icon',
    },
  },
}
</script>
