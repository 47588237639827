<template lang="pug">
include ../../../../Bemto
div
  div(
    v-if="!Object.keys(objectList).length"
  )
    +b.TEXT-BEMTO(
      :weight="'medium'"
      :height="'sm'"
      :size="'sm'"
      :c="'hask'"
      :tag="'p'"
    ) {{ _('Notification not found') }}
  div(
    v-if="Object.keys(objectList).length"
  )
    +b.el-notification(
      v-for="(item, cIndex) in objectList"
      :key="cIndex"
    )
      +b.TEXT-BEMTO(
        :weight="'medium'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ cIndex }}
      +e.content
        +e.element(
          v-for="(element, eIndex) in item"
          :class="elementClassHandler(element)"
          :key="eIndex"
        )
          +b.ROW(
            :appearance="['spaced']"
            :space="'xs'"
            :align="'center'"
            :tag="'div'"
          )
            +b.CELL(
              :cell="['narrow']"
              :tag="'div'"
            )
              +b.el-notification__date-at
                +b.icon-cclock.ma--r_xxs.TEXT-BEMTO(
                  :size="['sm']"
                  :c="'black'"
                  :tag="'i'"
                )
                +b.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'black'"
                  :tag="'p'"
                ) {{ setLocalTimeHandler(element.datetime) }}
            +b.CELL(
              :cols="['auto']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'sm'"
                :size="['sm']"
                :c="'black'"
                :tag="'p'"
              ) {{ element.text }}
            +b.pa--r_sm.bell-inamation.CELL(
              v-if="element.isNew"
              @click.prevent="$emit('read-notify', element.pk)"
              :cell="['narrow']"
              :tag="'div'"
            )
              +b.el-notification__bell.pointer.ROW(
                :appearance='["spaced"]'
                :align="'center'"
                :space="'none'"
                :tag="'div'"
              )
                +b.CELL(
                  :cols="['narrow']"
                )
                  +b.icon-bell.TEXT-BEMTO(
                    :weight="'medium'"
                    :size="'2md'"
                    :c="'grub'"
                    :tag="'i'"
                  )
                  +b.el-notification__dots
</template>

<script>

export default {
  name: 'JointNotificationsTable',
  props: {
    objectList: {},
  },
  methods: {
    setLocalTimeHandler: dateTime => new Date(dateTime).toLocaleTimeString(navigator.language, { timeStyle: 'short' }),
    elementClassHandler(value) {
      if (value.isNew) {
        return 'el-notification__element--inactive'
      }

      return 'el-notification__element--isactive'
    },
  },
}
</script>
