import { createResource, dynamicUrl } from '../resources/resource'
import { ENDPOINTS } from '../ENDPOINTS'

export const getNotificationsListAPI = dynamicUrl(
  ENDPOINTS.GET_NOTIFICATIONS
)

export const notificationsList = createResource(
  ENDPOINTS.GET_NOTIFICATIONS_NEW
)
