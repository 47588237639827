import Vuex from 'vuex'
import { commonElement } from './modules/common-element/common-element'
import { personalInfo } from './modules/personal-info/personal-info'

export function createStore() {
  return new Vuex.Store({
    modules: {
      commonElement,
      personalInfo,
    },
    deep: true,
  })
}
