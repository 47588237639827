<template lang="pug">
include ../../../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.text--size_1sm.--weight_bold
  +e.body.VALIDATION-OBSERVER(
    ref="observer"
    tag="form"
  )
    +b.ROW(
      :appearance='["spaced"]'
      :space="'sm'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['12']"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :c="'black'"
          :tag="'p'"
        ) {{ _('Change password') }}
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.password"
          :input-label="_('Current password *')"
          :rules="'required|min:8'"
          :name="'password'"
          :type="'password'"
          :id="'password'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.newPassword"
          :input-label="_('New password *')"
          :rules="'required|min:8'"
          :name="'newPassword'"
          :type="'password'"
          :id="'newPassword'"
        )
        div(
          v-if='errorMessages'
        )
          +b.P.file-error-text(
            v-if='errorMessages.nonFieldErrors'
          ) {{ errorMessages.nonFieldErrors[0].message }}
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.newPassword1"
          :input-label="_('Repeat new password *')"
          :rules="'required|min:8|confirmed:newPassword'"
          :name="'newPassword1'"
          :type="'password'"
          id="newPassword1"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _('Save') }}
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'

import { patchEditPassAPI } from '@/javascript/requests/services/account.service'

export default {
  name: 'EditPassInfo',
  mixins: [
    FormsMixin,
    PopupMixin,
  ],
  data() {
    return {
      errorMessages: null,
      /** Componen state. */
      formData: {
        password: null,
        newPassword: null,
        newPassword1: null,
      },
      /** Request. */
      isRequest: false,
    }
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return patchEditPassAPI.execute({}, data).then(() => {
        this.toggleToster(this._('Password change successfuly.'))
        this.$emit('close')
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
      this.errorMessages = errors
    },
  },
}
</script>
