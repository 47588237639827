<template lang="pug">
include ../../../Bemto
+b.TAG-BEMTO(
  :config="['radius-xs']"
  :spaces="'xs'"
  :size="'xs'"
  :bg="'white'"
  :block="'common'"
  :tag="'div'"
)
  +e.VALIDATION-OBSERVER(
    :ref="'observer'"
    :tag="'form'"
  )
    +b.ROW(
      :appearance="['spaced']"
      :space="'md'"
      :justify="'between'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['narrow']"
        :tag="'div'"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :align="'center'"
          :c="'black'"
          :tag="'H1'"
        ) {{ _('Login to your personal account') }}
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.login"
          :input-label="_('Enter your Email*')"
          :rules="'required'"
          :name="'login'"
          :type="'text'"
          :modal="true"
          :id="'login'"
        )
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.password"
          :input-label="_('Enter your password *')"
          :rules="'required|min:8'"
          :name="'password'"
          :type="'password'"
          :modal="true"
          :id="'password'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.VALIDATION-PROVIDER(
          :name="'nonFieldErrors'"
          v-slot="{ errors }"
        )
          +b.ma--b_sm.TEXT-BEMTO(
            v-if="errors.length"
            :weight="'medium'"
            :size="'xs'"
            :c="'red'"
            :tag="'p'"
          ) {{ errors[0] }}
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Login") }}
      +b.CELL(
        :cols="['narrow']"
      )
        +b.c--hover_hask.LINK-BEMTO(
          :config="'pointer'"
          :weight="'medium'"
          :size="'sm'"
          :c="'green'"
          :tag="'a'"
          :href="'/registration/'"
        ) {{ _('Registration') }}
      +b.CELL(
        :cols="['narrow']"
      )
        toggle-mixin-modal(
          :component-name="'RSPassword'"
          :modal-class="'modal--size_xs'"
        )
          template(
            #default="{handler}"
          )
            +b.c--hover_hask.TEXT-BEMTO(
              :config="'pointer'"
              :weight="'medium'"
              :size="'sm'"
              :c="'green'"
              :tag="'p'"
              @event="handler"
            ) {{ _('Forgot password?') }}
      +b.CELL(
        :cols="['12']"
      )
        slot(
          name="social-registration"
        )
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import { postAuthorizationAPI } from '@/javascript/requests/services/auth.service'

export default {
  name: 'CabinetAuthorization',
  mixins: [
    FormsMixin, // &&VALIDATES
  ],
  data() {
    return {
      /** Component state. */
      formData: {
        login: null,
        password: null,
      },
    }
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return postAuthorizationAPI.execute({}, data).then(() => {
        window.location = '/cabinet/joint/active-orders/'
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
