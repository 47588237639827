<template lang="pug">
include ../../../../Bemto
+b.ROW(
  :appearance="['spaced']"
  :justify="'between'"
  :align="'center'"
  :space="'xs'"
  :tag="'div'"
)
  +b.el-zebra__element--black
    +b.CELL(
      :cols="['auto']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'regular'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('First name') }}
    +b.CELL(
      :cols="['narrow']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'bold'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ objectItem.firstName || '-' }}
    +b.CELL(
      :style="'display: flex'"
      :cols="['narrow']"
      :tag="'div'"
    )
      toggle-mixin-modal(
        :component-name="'EditCommonInfo'"
        :object-item="objectItem"
        :parent-a-p-i="parentAPI"
      )
        template(
          #default="{handler}"
        )
          +b.icon-edit.pointer.TEXT-BEMTO(
            :size="'md'"
            :c="'green'"
            :tag="'i'"
            @event="handler"
          )
  +b.el-zebra__element--white
    +b.CELL(
      :cols="['auto']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'regular'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('Last name') }}
    +b.ma--r_4sm.CELL(
      :cols="['narrow']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'bold'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ objectItem.lastName || '-' }}
  +b.el-zebra__element--black
    +b.CELL(
      :cols="['auto']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'regular'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('Country') }}
    +b.ma--r_4sm.CELL(
      :cols="['narrow']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'bold'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ objectItem.countryRepr || '-' }}
  +b.el-zebra__element--white
    +b.CELL(
      :cols="['auto']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'regular'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('Street') }}
    +b.ma--r_4sm.CELL(
      :cols="['narrow']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'bold'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ objectItem.street || '-' }}
</template>

<script>
export default {
  name: 'CommonPanel',
  props: {
    objectItem: {},
    parentAPI: {},
  },
  mounted() {
    this.permission()
  },
  data() {
    return {
      role: null,
    }
  },
  methods: {
    /**
     * Depending on what role is currently
     * assigned to us, we must erroneously
     * indicate the component that we need.
     */
    permission() {
      const { role } = window
      this.role = role
    },
  },
}
</script>
