import elements from './elements'
import menus from './menus'
import panels from './panels'
import tables from './tables'

export default function install(Vue) {
  Vue.use(elements)
  Vue.use(menus)
  Vue.use(panels)
  Vue.use(tables)
}
