<template lang="pug">
include ../../../Bemto
+b.TAG-BEMTO(
  :config="['radius-xs']"
  :spaces="'xs'"
  :size="'xs'"
  :bg="'white'"
  :block="'common'"
  :tag="'div'"
)
  +e.VALIDATION-OBSERVER(
    :ref="'observer'"
    :tag="'form'"
  )
    +b.ROW(
      :appearance="['spaced']"
      :space="'sm'"
      :justify="'center'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['narrow']"
        :tag="'div'"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :align="'center'"
          :c="'black'"
          :tag="'H1'"
        ) {{ _("Create a new password") }}
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.newPassword1"
          :input-label="_('Enter your password *')"
          :rules="'required|min:8'"
          :name="'newPassword1'"
          :type="'password'"
          :id="'newPassword1'"
        )
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.newPassword2"
          :input-label="_('Repeat password *')"
          :rules="'required|min:8|confirmed:newPassword1'"
          :name="'newPassword2'"
          :type="'password'"
          :id="'newPassword2'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.VALIDATION-PROVIDER(
          :name="'nonFieldErrors'"
          v-slot="{ errors }"
        )
          +b.ma--b_sm.TEXT-BEMTO(
            v-if="errors.length"
            :weight="'medium'"
            :size="'xs'"
            :c="'red'"
            :tag="'p'"
          ) {{ errors[0] }}
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Save") }}
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'

import { postCreatePasswordAPI } from '@/javascript/requests/services/auth.service'

export default {
  name: 'CabinetSetPassword',
  props: {
    token: {},
    uid: {},
  },
  mixins: [
    FormsMixin, // &&VALIDATES
  ],
  data() {
    return {
      /** Component state. */
      formData: {
        token: this.token,
        uid: this.uid,
        newPassword1: null,
        newPassword2: null,
      },
    }
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return postCreatePasswordAPI.execute({}, data).then(() => {
        window.location = '/success-set-password/'
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
