<template lang="pug">
include ../../../../Bemto
+b.VALIDATION-OBSERVER(
  ref="observer"
  tag="form"
)
  +b.ROW(
    :appearance='["spaced"]'
    :justify="'end'"
    :align="'center'"
    :space="'sm'"
    :tag="'div'"
  )
    +b.CELL(
      :cols="['12']"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :space="'sm'"
        :tag="'div'"
      )
        +b.CELL(
          :cols="['12', '6-sm', '4-lg', '3-mac']"
        )
          +b.D-CONTROL-INPUT(
            v-model="formData.title"
            :input-label="_('Order name *')"
            :rules="'required'"
            :name="'title'"
            :id="'title'"
          )
        +b.CELL(
          :cols="['12', '6-sm', '4-lg', '3-mac']"
        )
          +b.D-CONTROL-INPUT(
            v-model="formData.bidRedemption[0].productName"
            :input-label="_('Product name')"
            :name="'productName'"
            :id="'productName'"
          )
        +b.CELL(
          :cols="['12', '6-sm', '4-lg', '3-mac']"
        )
          +b.D-CONTROL-INPUT(
            v-model="formData.bidRedemption[0].sku"
            :input-label="_('Order code (SKU)')"
            :name="'sku'"
            :id="'sku'"
          )
        +b.CELL(
          :cols="['12', '6-sm', '4-lg', '3-mac']"
        )
          +b.D-CONTROL-INPUT(
            v-model="formData.bidRedemption[0].amount"
            v-mask="['################']"
            :input-label="_('Product counter')"
            :name="'amount'"
            :id="'amount'"
          )
        +b.CELL(
          :cols="['12', '6-sm', '4-lg', '3-mac']"
        )
          +b.D-CONTROL-INPUT(
            v-model="formData.bidRedemption[0].price"
            v-mask="['################']"
            :input-label="_('Product price')"
            :name="'price'"
            :id="'price'"
          )
        +b.CELL(
          :cols="['12', '6-sm', '4-lg', '3-mac']"
        )
          +b.D-CONTROL-INPUT(
            v-model="formData.bidRedemption[0].linkProduct"
            :input-label="_('Product link *')"
            :name="'linkProduct'"
            :rules="'required|url'"
            :id="'linkProduct'"
          )
    +b.CELL(
      :cols="['12']"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :align="'center'"
        :space="'sm'"
        :tag="'div'"
      )
        +b.CELL(
          :cols="['12', '6-sm', 'narrow-lg']"
        )
          +b.radio-wrapper--transfer(
            :class="{'br--grn': formData.bidRedemption[0].deliveryType === 1}"
          )
            +b.d-control-input--row-initial()
              +b.icon-shipping.ma--r_sm.TEXT-BEMTO(
                :config="'pointer-none'"
                :size="['xl']"
                :c="'difr'"
                :tag="'i'"
              )
              +e.element.INPUT(
                v-model="formData.bidRedemption[0].deliveryType"
                :name="'deliveryType1'"
                :value="48"
                :type="'radio'"
                :id="'deliveryType1'"
              )
              +e.label.LABEL(
                :for="'deliveryType1'"
              )
                +b.TEXT-BEMTO(
                  :config="'pointer-none'"
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['md']"
                  :c="'hask'"
                  :tag="'p'"
                ) {{ _('Auto') }}
        +b.CELL(
          :cols="['12', '6-sm', 'narrow-lg']"
        )
          +b.radio-wrapper--transfer(
            :class="{'br--grn': formData.bidRedemption[0].deliveryType === 2}"
          )
            +b.d-control-input--row-initial()
              +b.icon-ship.ma--r_sm.TEXT-BEMTO(
                :config="'pointer-none'"
                :size="['xl']"
                :c="'difr'"
                :tag="'i'"
              )
              +e.element.INPUT(
                v-model="formData.bidRedemption[0].deliveryType"
                :name="'deliveryType2'"
                :value="46"
                :type="'radio'"
                :id="'deliveryType2'"
              )
              +e.label.LABEL(
                :for="'deliveryType2'"
              )
                +b.TEXT-BEMTO(
                  :config="'pointer-none'"
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['md']"
                  :c="'hask'"
                  :tag="'p'"
                ) {{ _('Sea') }}
        +b.CELL(
          :cols="['12', '6-sm', 'narrow-lg']"
        )
          +b.radio-wrapper--transfer(
            :class="{'br--grn': formData.bidRedemption[0].deliveryType === 3}"
          )
            +b.d-control-input--row-initial()
              +b.icon-air.ma--r_sm.TEXT-BEMTO(
                :config="'pointer-none'"
                :size="['xl']"
                :c="'difr'"
                :tag="'i'"
              )
              +e.element.INPUT(
                v-model="formData.bidRedemption[0].deliveryType"
                :name="'deliveryType3'"
                :value="44"
                :type="'radio'"
                :id="'deliveryType3'"
              )
              +e.label.LABEL(
                :for="'deliveryType3'"
              )
                +b.TEXT-BEMTO(
                  :config="'pointer-none'"
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['md']"
                  :c="'hask'"
                  :tag="'p'"
                ) {{ _('Air') }}
    +b.CELL(
      :cols="['12']"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :align="'center'"
        :space="'sm'"
        :tag="'div'"
      )
        +b.CELL(
          :cols="['12', '6-lg']"
        )
          +b.D-CONTROL-TEXTAREA(
            v-model="formData.bidRedemption[0].comment"
            :input-label="_('Comments')"
            :name="'comment'"
            :id="'comment'"
          )
        +b.CELL(
          :cols="['12', '6-lg']"
        )
          +b.ROW(
            :appearance='["spaced"]'
            :space="'sm'"
            :tag="'div'"
          )
            +b.CELL(
              :cols="['12']"
            )
              +b.radio-wrapper
                +b.d-control-input--row-initial()
                  +e.element.INPUT(
                    v-model="formData.bidRedemption[0].redeem"
                    :name="'redeem'"
                    :type="'checkbox'"
                    :id="'redeem'"
                  )
                  +e.label.LABEL(
                    :for="'redeem'"
                  )
                    +b.TEXT-BEMTO(
                      :config="'pointer-none'"
                      :weight="'medium'"
                      :height="'sm'"
                      :size="['sm']"
                      :c="'dared'"
                      :tag="'p'"
                    ) {{ _('Redeem if available without confirmation') }}
            +b.CELL(
              :cols="['12']"
            )
              +b.radio-wrapper
                +b.d-control-input--row-initial()
                  +e.element.INPUT(
                    v-model="formData.bidRedemption[0].recalculate"
                    :name="'recalculate'"
                    :type="'checkbox'"
                    :id="'recalculate'"
                  )
                  +e.label.LABEL(
                    :for="'recalculate'"
                  )
                    +b.TEXT-BEMTO(
                      :config="'pointer-none'"
                      :weight="'medium'"
                      :height="'sm'"
                      :size="['sm']"
                      :c="'dared'"
                      :tag="'p'"
                    ) {{ _('Recalculate the number of units of goods ($ 3 / box)') }}
    +b.CELL(
      :cols="['12', 'narrow-sm']"
    )
      +b.BUTTON-BEMTO(
        :config="['anima', 'radius-xs', 'style-grn']"
        :vision="'default'"
        :align="'center'"
        :tag="'button'"
        :disabled='nonActive'
        @event="validateBeforeAPI(formData)"
      )
        +b.pa--r_md.--l_md.pointer--initial.TEXT-BEMTO(
          :config="'uppercase'"
          :weight="'bold'"
          :size="'sm'"
          :c="'white'"
          :tag="'p'"
          v-if='!nonActive'
        ) {{ _("Send request") }}
        +b.pa--r_md.--l_md.pointer--initial.TEXT-BEMTO(
          :config="'uppercase'"
          :weight="'bold'"
          :size="'sm'"
          :c="'white'"
          :tag="'p'"
          v-else
        ) {{ _("Loading...") }}
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'

import { postCreateRedemptionAPI } from '@/javascript/requests/services/bid.service'

import { mask } from 'vue-the-mask'

export default {
  name: 'RedemptionRequest',
  props: {
    objectItem: {},
  },
  directives: {
    mask,
  },
  mixins: [
    FormsMixin,
    PopupMixin,
  ],
  data() {
    return {
      nonActive: false,
      /** Componen state. */
      formData: {
        title: null,
        bidRedemption: [{
          productName: null,
          sku: null,
          amount: null,
          price: null,
          linkProduct: null,
          comment: null,
          redeem: false,
          recalculate: false,
        }],
      },
      /** Request. */
      isRequest: false,
    }
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      this.nonActive = true
      return postCreateRedemptionAPI.execute({}, data).then(({ data: { messages } }) => {
        const popupObjectItem = {
          title: messages.title, order: messages.order, text: messages.text,
        }
        this.toggleModal('SuccessBidModal', popupObjectItem)
        setTimeout(() => {
          window.location = '/cabinet/joint/active-orders/'
        }, 3500)
        this.$refs.observer.reset()
        this.clearHandler()
        this.nonActive = false
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
    /**
     * We bring the state of the component back to normal.
     */
    clearHandler() {
      this.formData = {
        title: null,
        bidRedemption: [{
          productName: null,
          sku: null,
          amount: null,
          price: null,
          linkProduct: null,
          comment: null,
          redeem: false,
          recalculate: false,
        }],
      }
    },
  },
}
</script>
