import { ADMINISTRATOR_ENDPOINTS } from './ENDPOINTS'

const ADMINISTRATOR_ROUTES = [
  {
    component: () => import('@/javascript/components/pages/roles/administrator/test/Test'),
    path: ADMINISTRATOR_ENDPOINTS.TEST,
    name: 'administrator-test',
    meta: { permissions: ['administrator'] },
  },
]

export default ADMINISTRATOR_ROUTES
