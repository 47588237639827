import { createResource, postResource, dynamicUrl } from '../resources/resource'
import { ENDPOINTS } from '../ENDPOINTS'

export const postCreateBidSearchAPI = createResource(
  ENDPOINTS.CREATE_BID_SEARCH,
  postResource
)
export const postCreateRedemptionAPI = createResource(
  ENDPOINTS.CREATE_REDEMPTION,
  postResource
)
export const messageCreateAPI = createResource(
  ENDPOINTS.MESSAGE_CREATE,
  postResource
)
export const postCreatePaymentAPI = createResource(
  ENDPOINTS.CREATE_PAYMENT,
  postResource
)
export const postCreateChatAPI = createResource(
  ENDPOINTS.CREATE_CHAT,
  postResource
)
export const getActiveBidItemsAPI = dynamicUrl(
  ENDPOINTS.GET_ACTIVE_BID
)
export const getCompletedBidItemsAPI = dynamicUrl(
  ENDPOINTS.GET_COMPLETED_BID
)
export const getOrderDetailsAPI = createResource(
  ENDPOINTS.GET_ORDER_INFO
)
export const getChatAPI = createResource(
  ENDPOINTS.GET_CHAT
)
export const getChatMessageListAPI = createResource(
  ENDPOINTS.GET_MESSAGES_LIST
)
export const getSearchTextAPI = createResource(
  ENDPOINTS.TEXT_SEARCH
)
export const getRansomTextAPI = createResource(
  ENDPOINTS.TEXT_RANSOM
)
export const getPaymentTextAPI = createResource(
  ENDPOINTS.TEXT_PAYMENT
)
export const getTransportTextAPI = createResource(
  ENDPOINTS.TEXT_TRANSPORT
)
export const transport = createResource(
  ENDPOINTS.CREATE_TRANSPORTATION,
  postResource
)
export const createContractAPI = createResource(
  ENDPOINTS.CREATE_CONTRACT,
  postResource
)
export const getCountriesApi = createResource(
  ENDPOINTS.GET_COUNTRIES
)
export const getAmazonCountriesApi = createResource(
  ENDPOINTS.GET_AMAZON_COUNTRIES
)
export const getAmazonRegionsApi = createResource(
  ENDPOINTS.GET_AMAZON_REGIONS
)
export const getRegionsWarehouseApi = createResource(
  ENDPOINTS.GET_REGION_WAREHOUSES
)
export const getWarehouseApi = createResource(
  ENDPOINTS.GET_WAREHOUSES
)
export const getDangerMaterialsApi = createResource(
  ENDPOINTS.GET_MATERIALS
)
