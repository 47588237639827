<template lang="pug">
include ../../../../Bemto
+b.div Administrator test
</template>

<script>
export default {
  name: 'AdministratorTest',
}
</script>
