<template lang="pug">
include ../../../../Bemto
+b.BUTTON-BEMTO.btn--chat(
  :vision="'default'"
  :align="'center'"
  :tag="'button'"
  @event="$emit('toggle-chat'), ($emit('create-chat'))"
)
  +b.bell-inamation.BUTTON-BEMTO(
    :config="['anima', 'radius-xs', 'style-blue-anim']"
    :vision="'default'"
    :align="'center'"
    :tag="'button'"
  )
    +b.el-notification__bell.pointer--initial.ROW(
      :appearance='["spaced"]'
      :align="'center'"
      :space="'none'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['narrow']"
      )
        +b.icon-chat.TEXT-BEMTO(
          :weight="'medium'"
          :size="'2md'"
          :c="'white'"
          :tag="'i'"
        )
        +b.el-header-bell__counter
          +e.text.TEXT-BEMTO(
            :weight="'medium'"
            :height="'md'"
            :size="'xxs'"
            :c="'white'"
            :tag="'p'"
          ) {{ countMessage }}
    +b.ma--l_sm.pointer--initial.TEXT-BEMTO(
      :config="'uppercase'"
      :weight="'bold'"
      :size="'sm'"
      :c="'white'"
      :tag="'p'"
    ) {{ _("Chat") }}
</template>

<script>
export default {
  name: 'chat-btn',
  props: {
    countMessage: Number,
  },
}
</script>
