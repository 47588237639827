<template lang="pug">
include ../../../Bemto
+b.TAG-BEMTO(
  :config="['radius-xs']"
  :spaces="'xs'"
  :size="'xs'"
  :bg="'white'"
  :block="'common'"
  :tag="'div'"
)
  +e.VALIDATION-OBSERVER(
    :ref="'observer'"
    :id="'observer'"
    :tag="'form'"
    :method="'POST'"
    :action="actionAPI"
  )
    +b.ROW(
      v-show="!toggle"
      :appearance="['spaced']"
      :space="'sm'"
      :justify="'center'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['narrow']"
        :tag="'div'"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :align="'center'"
          :c="'black'"
          :tag="'H1'"
        ) {{ _("To register on the site you must specify Email") }}
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData1.email"
          :input-label="_('Enter your Email *')"
          :rules="'required|email'"
          :name="'email'"
          :type="'text'"
          :id="'email'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData1)"
        )
          +b.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Save") }}
    +b.ROW(
      v-show="toggle"
      :appearance="['spaced']"
      :space="'sm'"
      :justify="'center'"
      :align="'end'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['narrow']"
        :tag="'div'"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :align="'center'"
          :c="'black'"
          :tag="'H1'"
        ) {{ _("Enter the code that came to you at the specified mail.") }}
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.ROW(
          v-show="toggle"
          :appearance="['spaced']"
          :space="'sm'"
          :justify="'center'"
          :align="'end'"
          :tag="'div'"
        )
          +b.CELL(
            :cols="['3']"
            :tag="'div'"
          )
            +b.input--center.D-CONTROL-INPUT(
              v-model="formData2.code1"
              maxlength="1"
              :name="'code1'"
              :type="'text'"
              :id="'code1'"
            )
          +b.CELL(
            :cols="['3']"
            :tag="'div'"
          )
            +b.input--center.D-CONTROL-INPUT(
              v-model="formData2.code2"
              maxlength="1"
              :name="'code2'"
              :type="'text'"
              :id="'code2'"
            )
          +b.CELL(
            :cols="['3']"
            :tag="'div'"
          )
            +b.input--center.D-CONTROL-INPUT(
              v-model="formData2.code3"
              maxlength="1"
              :name="'code3'"
              :type="'text'"
              :id="'code3'"
            )
          +b.CELL(
            :cols="['3']"
            :tag="'div'"
          )
            +b.input--center.D-CONTROL-INPUT(
              v-model="formData2.code4"
              maxlength="1"
              :name="'code4'"
              :type="'text'"
              :id="'code4'"
            )
      +b.CELL(
        :cols="['12']"
      )
        +b.VALIDATION-PROVIDER(
          :name="'nonFieldErrors'"
          v-slot="{ errors }"
        )
          +b.ma--b_sm.TEXT-BEMTO(
            v-if="errors.length"
            :weight="'medium'"
            :size="'xs'"
            :c="'red'"
            :tag="'p'"
          ) {{ errors[0] }}
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData2)"
        )
          +b.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Validate") }}
    input(
      :type="'hidden'" :name="'email'"
      v-model="formData1.email"
    )
    input(
      :type="'hidden'" :name="'partial_token'"
      :value="token"
    )
</template>

<script>
import { postCreateEmailAPI, postVerificationCodeAPI } from '@/javascript/requests/services/auth.service'
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import { asyncForEach } from '@/javascript/utils/foreach.util.js'

export default {
  name: 'CabinetEmailNotFound',
  props: {
    actionAPI: {},
    token: {},
  },
  mixins: [
    FormsMixin, // &&VALIDATES
  ],
  data() {
    return {
      /** Component state. */
      formData1: {
        email: null,
      },
      formData2: {
        code1: null,
        code2: null,
        code3: null,
        code4: null,
      },
      toggle: false,
    }
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    // eslint-disable-next-line consistent-return
    async sendHandlerAPI(data) {
      if (!this.toggle) {
        return postCreateEmailAPI.execute({}, data).then(({ data: { item } }) => {
          this.toggle = item.success
        })
      }
      const code = await this.mergeVerificationCodeHandler(data)
      const option = { email: this.formData1.email, code }
      return postVerificationCodeAPI.execute({}, option).then(() => {
        document.getElementById('observer').submit()
      })
    },
    async mergeVerificationCodeHandler(data) {
      let code = ''
      /**
       * The some () method checks if any element of
       * the array satisfies the condition specified
       * in the passed function.
       */
      await asyncForEach(
        Object.keys(data), async key => {
          code += data[key]
        }
      )
      return code
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
