<template lang="pug">
include ../../../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.text--size_1sm.--weight_bold
  +e.body.VALIDATION-OBSERVER(
    ref="observer"
    tag="form"
  )
    +b.ROW(
      :appearance='["spaced"]'
      :space="'sm'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['12']"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :c="'black'"
          :tag="'p'"
        ) {{ _("Withdraw request") }}
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.firstName"
          :input-label="_('First name *')"
          :type="'text'"
          @input="prettyString('firstName')"
          :rules="'required'"
          :name="'firstName'"
          :modal="true"
          :id="'firstName'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.lastName"
          :input-label="_('Last name *')"
          :rules="'required'"
          @input="prettyString('lastName')"
          :name="'lastName'"
          :modal="true"
          :id="'lastName'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.cardNumber"
          :input-label="_('Card number *')"
          :type="'number'"
          :rules="'required'"
          :name="'cardNumber'"
          :modal="true"
          :id="'cardNumber'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.amount"
          :input-label="_('Amount *')"
          :type="'number'"
          :rules="'required'"
          :name="'amount'"
          :modal="true"
          :id="'amount'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Send request") }}
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'
import { mapActions, mapGetters } from 'vuex'

import { postCreateWithdrawRequestAPI } from '@/javascript/requests/services/balance.service'

export default {
  name: 'WithdrawRequest',
  props: {
    objectItem: {},
  },
  mixins: [
    FormsMixin,
    PopupMixin,
  ],
  data() {
    return {
      errorMessages: null,
      /** Componen state. */
      formData: {
        firstName: null,
        lastName: null,
        cardNumber: null,
        amount: null,
      },
      /** Request. */
      isRequest: false,
    }
  },
  computed: {
    ...mapGetters('personalInfo', ['getAccountDetails']),
  },
  mounted() {
    this.setDataToFormData()
  },
  methods: {
    setDataToFormData() {
      if (this.getAccountDetails) {
        this.formData.firstName = this.getAccountDetails.firstName
        this.formData.lastName = this.getAccountDetails.lastName
      }
    },
    prettyString(key) {
      this.formData[key] = this.formData[key].replace(/[^a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ-]/g, '')
    },
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return postCreateWithdrawRequestAPI.execute({}, data).then(({ data: { messages } }) => {
        const popupObjectItem = {
          title: messages[0].title, text: messages[0].text,
        }
        this.toggleModal('SuccessModal', popupObjectItem)
        this.$emit('update-parent-a-p-i')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },

    ...mapActions('personalInfo', [
      'getAccountDetailsAPI',
    ]),
  },
}
</script>
