<template lang="pug">
include ../../../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.text--size_1sm.--weight_bold
  +e.body.VALIDATION-OBSERVER(
    ref="observer"
    tag="form"
  )
    +b.ROW(
      :appearance='["spaced"]'
      :space="'sm'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['12']"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :c="'black'"
          :tag="'p'"
        ) {{ _("Change email info") }}
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.email"
          :input-label="_('Email *')"
          :rules="'required'"
          :name="'email'"
          :id="'email'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Save") }}
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'

import { postEditEmailAPI } from '@/javascript/requests/services/account.service'
import { asyncForEach } from '@/javascript/utils/foreach.util'

export default {
  name: 'EditEmailInfo',
  props: {
    objectItem: {},
  },
  mixins: [
    FormsMixin,
    PopupMixin,
  ],
  async mounted() {
    await this.preparedFormData()
  },
  data() {
    return {
      /** Componen state. */
      formData: {
        email: null,
      },
      /** Request. */
      isRequest: false,
    }
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return postEditEmailAPI.execute({}, data).then(({ data: { item } }) => {
        const popupObjectItem = {
          title: item.title, text: item.text,
        }
        this.toggleModal('SuccessModal', popupObjectItem)
      })
    },
    /**
     * Pre-fill values before editing them.
     */
    async preparedFormData() {
      /**
       * The some () method checks if any element of
       * the array satisfies the condition specified
       * in the passed function.
       */
      await asyncForEach(
        Object.keys(this.formData), async key => {
          this.formData[key] = this.objectItem[key]
        }
      )
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
