<template lang="pug">
include ../../../../Bemto
+b.ROW(
  :appearance='["spaced"]'
  :justify="justify"
  :space="space"
  :tag="'div'"
)
  +b.CELL(
    :cols="['12', '6-lg']"
  )
    +b.el-file-uploader__label.LABEL(
      :for="id"
    )
      +b.el-file-uploader__button()
        +b.ROW(
          :appearance='["spaced"]'
          :justify="'start'"
          :space="'xs'"
          :align="'center'"
          :tag="'div'"
        )
          +b.CELL(
            :style="'display: flex;'"
            :cols="['narrow']"
          )
            +b.TEXT-BEMTO(
              :weight="'bold'"
              :height="'sm'"
              :size="['sm']"
              :tag="'p'"
            ) {{ bttnText }}
          +b.CELL(
            :style="'display: flex;'"
            :cols="['narrow']"
          )
            +b.icon-plus.TEXT-BEMTO(
              :size="['sm']"
              :tag="'i'"
            )
    +b.el-file-uploader__input.INPUT(
      max-uploads=6
      :ref="'fileUploader'"
      :id="id"
      type="file"
      multiple
      @change="uploadSource"
    )
  +b.CELL(
    :cols="['12']"
  )
    +b.el-file-uploader(
      v-if="!formData.length"
    )
      +b.ma--t_xxs.--b_xxs.pa--t_xxs.--b_xxs.TEXT-BEMTO(
        :weight="'medium'"
        :height="'sm'"
        :size="['xs']"
        :c="'black'"
        :tag="'p'"
      ) {{ emptyDataTitle }}
    +b.el-file-uploader(
      :class="formData.length > 5 ? 'test-bor': ''"
      v-if="formData.length"
    )
      +b.P.file-warning(
        v-if='formData.length > 5'
      ) {{ _('Too many files. Maximum: 5')}}
      +b.ROW(
        :appearance='["spaced"]'
        :justify="'start'"
        :space="space"
        :tag="'div'"
      )
        +b.CELL(
          v-for="(item, index) in formData"
          :key="`${item.order}${index}`"
          :cols="['narrow']"
        )
          +b.el-file-uploader__element
            +b.ROW(
              :appearance='["spaced"]'
              :justify="'start'"
              :space="'xs'"
              :align="'center'"
              :tag="'div'"
            )
              +b.CELL(
                :style="'display: flex;'"
                :cols="['narrow']"
              )
                +b.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'hask'"
                  :tag="'p'"
                ) {{ item.name }}
              +b.CELL(
                :cols="['narrow']"
              )
                +b.el-file-uploader__del
                  +b.icon-close.TEXT-BEMTO(
                    :size="['xxs']"
                    :c="'red'"
                    :tag="'i'"
                    @event="deleteSRC(item.id, index)"
                  )
</template>

<script>
export default {
  name: 'FileUploader',
  props: {
    emptyDataTitle: {},
    bttnText: {},
    justify: {},
    space: {},
    type: {},
    id: {},
    value: {},
  },
  data() {
    return {
      formData: [],
      count: 0,
    }
  },
  methods: {
    uploadSource(event) {
      const { ...files } = event.target.files
      Object.entries(files).forEach(file => {
        const fileReader = new FileReader()
        fileReader.onload = e => {
          const result = `${file[1].name};${e.target.result}`
          this.prefillSRC(file[1].name, result)
        }
        fileReader.readAsDataURL(file[1])
        this.$nextTick(() => {
          this.$refs.fileUploader.value = null
        })
      })
    },
    prefillSRC(fName, fData) {
      const resourseMap = {
        images: { photo: fData, name: fName },
        files: { attachedFile: fData, name: fName },
      }
      this.setData(resourseMap[this.type])
    },
    deleteSRC(id, idx) {
      if ('undefined' === typeof id) {
        const COUNT_FOR_REMOVING = 1
        this.formData.splice(idx, COUNT_FOR_REMOVING)
      } else {
        this.formData = this.formData.map(el => {
          if (id === el.id) this.$set(el, 'Delete', true)
          return el
        })
      }
    },
    setData(data) {
      const { ...obj } = data
      this.formData.push(obj)
      this.formData = this.formData.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i)
      console.log(this.formData)
      this.changeValueEvent()
    },
    changeValueEvent() {
      this.$emit('input', this.formData || [])
    },
    clearHandler() {
      this.formData = []
    },
  },
}
</script>
