import ToggleMixinModal from './modal/mixin/ToggleMixinModal'
import SampleModal from './modal/SampleModal'
import SuccessBidModal from './modal/SuccessBidModal'
import SuccessModal from './modal/SuccessModal'
import ConfirmModal from './modal/ConfirmModal'
import TabsUi from './tabs/TabsUi'
import TabUi from './tabs/TabUi'
import ElPagination from './ElPagination'
import ElRouter from './ElRouter'

export default function install(Vue) {
  Vue.component(ToggleMixinModal.name, ToggleMixinModal)
  Vue.component(SampleModal.name, SampleModal)
  Vue.component(SuccessBidModal.name, SuccessBidModal)
  Vue.component(SuccessModal.name, SuccessModal)
  Vue.component(ConfirmModal.name, ConfirmModal)
  Vue.component(TabsUi.name, TabsUi)
  Vue.component(TabUi.name, TabUi)
  Vue.component(ElPagination.name, ElPagination)
  Vue.component(ElRouter.name, ElRouter)
}
