<template lang="pug">
include ../../../../Bemto
row(
  :appearance="['spaced']"
  :align="'center'"
  :space="'sm'"
  :tag="'div'"
)
  cell(
    :cols="['12']"
    :tag="'div'"
  )
    row(
      :appearance="['spaced']"
      :space="'sm'"
      :tag="'div'"
    )
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :align="'center'"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['narrow']"
            :tag="'div'"
          )
            +b.TEXT-BEMTO(
              :weight="'medium'"
              :size="['lg']"
              :c="'hask'"
              :tag="'p'"
            ) {{objectItem.title }}
          cell(
            :cols="['auto']"
            :tag="'div'"
          )
            row(
              :appearance="['spaced']"
              :align="'center'"
              :space="'xs'"
              :tag="'div'"
            )
              cell(
                :style="'display: flex;'"
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.icon-calendar.TEXT-BEMTO(
                  :size="'md'"
                  :c="'green'"
                  :tag="'i'"
                )
              cell(
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'sans'"
                  :tag="'p'"
                ) {{ objectItem.createAt || '-' }}
          //- cell(
          //-   :cols="['narrow']"
          //-   :tag="'div'"
          //- )
          //-   chat-btn(
          //-     :countMessage='messageNotification'
          //-     @toggle-chat="$emit('sub-toggle')"
          //-     @create-chat="$emit('sub-create', objectItem.id)"
          //-   )
        div(
          v-if='objectItem.track'
          :style="{paddingTop: '60px', paddingBottom: '20px'}"
        )
          +b.shipping-progress-bar
            +b.shipping-point--start.--color-blue(
              :class="{'shipping-point--color-red' : objectItem.track.currentPosition.canceled}"
            )
            +b.shipping-point--end.--color-blue(
              :class="{'shipping-point--color-red' : objectItem.track.currentPosition.canceled}"
            )
            +b.shipping-circle--color-blue(
              :class="{'shipping-circle--color-red': objectItem.track.currentPosition.canceled}"
              :style="{ left: objectItem.track.currentPosition.number ? `${Math.ceil((objectItem.track.currentPosition.number / objectItem.track.endPosition) * 100)}%` : '10%'}"
            )
              text-bemto.ma--b_md(
                :style="{position: 'absolute', top: '-40px'}"
                :align="'center'"
                :size="['sm']"
                :c="objectItem.track.currentPosition.canceled ? 'red' : 'blue'"
                :tag="'p'"
              ) {{ objectItem.track.currentPosition.text }}
              text-bemto(
                :class="'icon-paymen'"
                :tag="'i'"
                :size="'md'"
                :c="'white'"
              )
            +b.PROGRESS.el-progress--color-blue(
              :class="{'el-progress--color-red' : objectItem.track.currentPosition.canceled}"
              v-if='objectItem.track'
              :max="objectItem.track.endPosition"
              :value="objectItem.track.currentPosition.number"
            )
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['12', '6-sm', '3-xl']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Commission") }}
              +e.element.--initial.--green {{ objectItem.bidPayment[0].commission || '-' }}
          cell(
            :cols="['12', '6-sm', '3-xl']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Receipt payment") }}
              +e.element.--initial.--green
                +b.A(
                  download
                  v-if='objectItem.bidPayment[0].receiptPayment'
                  :href='objectItem.bidPayment[0].receiptPayment'
                )
                  +b.TEXT-BEMTO(
                    :weight="'bold'"
                    :config="['pointer-none']"
                    :size="'xs'"
                    :c="'green'"
                    :tag="'p'"
                  ) {{ _('Receipt payment') || '-' }}
          cell(
            :cols="['12', '6-sm', '3-xl']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Invoice") }}
              +e.element.--initial.--green
                row(
                  :space="'xs'"
                  v-if='objectItem.bidPayment[0].bidPaymentInvoices'
                )
                  cell(
                    v-for='(file, index) in objectItem.bidPayment[0].bidPaymentInvoices'
                    :key='index'
                  )
                    +b.A(
                      download
                      :href='file.attachedFileLink'
                    )
                      +b.TEXT-BEMTO(
                        :weight="'bold'"
                        :config="['pointer-none']"
                        :size="'xs'"
                        :c="'green'"
                        :tag="'a'"
                      ) {{ _('File') + (index+1) }}
              //- file-demonstration.search-invoice(
              //-   :empty-data-title="_('Files not found')"
              //-   :object-item="objectItem.bidPayment[0].bidPaymentInvoices"
              //-   :justify="'start'"
              //-   :space="'sm'"
              //-   :type="'files'"
              //- )
          cell(
            :cols="['12', '6-sm', '3-xl']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Payment invoice") }}
              +e.element.--initial.--green {{ (objectItem.bidPayment[0].paymentInvoice || '-') + ' %' }}
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['12', '6-lg']"
            :tag="'div'"
          )
            row(
              :appearance="['spaced']"
              :space="'sm'"
              :tag="'div'"
            )
              cell(
                :cols="['12', '6-sm']"
                :tag="'div'"
              )
                +b.control-input
                  +e.label {{ _("Currency") }}
                  +e.element.--initial.--green {{ objectItem.bidPayment[0].currencyRepr || '-' }}
              cell(
                :cols="['12', '6-sm']"
                :tag="'div'"
              )
                +b.control-input
                  +e.label {{ _("Payment") }}
                  +e.element.--initial.--green {{ objectItem.bidPayment[0].payment || '-' }}
              cell(
                :cols="['12']"
                :tag="'div'"
              )
                +b.control-input
                  +e.label {{ _("Requisites") }}
                  +e.element.--text.--green {{ objectItem.bidPayment[0].requisites || '-' }}
          cell(
            :cols="['12', '6-lg']"
            :tag="'div'"
          )
            +b.control-input--full
              +e.label {{ _("Comment") }}
              +e.element.--text.--full.--green {{ objectItem.bidPayment[0].comment || '-' }}
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Attachment files") }}
          +e.element.--inital.--green
            row(
              :space="'xs'"
            )
              cell(
                v-for='(file, index) in objectItem.bidPayment[0].bidPaymentFiles'
                :key='index'
              )
                +b.A(
                  download
                  :href='file.attachedFileLink'
                )
                  +b.TEXT-BEMTO(
                    :weight="'bold'"
                    :config="['pointer-none']"
                    :size="'xs'"
                    :c="'green'"
                    :tag="'a'"
                  ) {{ _('File') + (index+1) }}
          //- file-demonstration(
          //-   :empty-data-title="_('Files not found')"
          //-   :object-item="objectItem.bidPayment[0].bidPaymentFiles"
          //-   :justify="'start'"
          //-   :space="'sm'"
          //-   :type="'files'"
          //- )
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'PaymentOrderInfo',
  props: {
    objectItem: {},
    messageNotification: Number,
  },
  async created() {
    /** Set header name on the page. */
    await this.setHeaderTitleAction(
      /* eslint-disable prefer-template */
      this.objectItem.crmNumber
    )
  },
  methods: {
    ...mapActions('commonElement', [
      'setHeaderTitleAction',
    ]),
  },
}
</script>
