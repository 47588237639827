<template lang="pug">
include ../Bemto
+b.el-router
  +e.core-menu
    +e.link--core.COMPONENT(
      :is="item.name.startsWith('https') ? 'a' : 'router-link'"
      v-for="item in coreMenu" :key="item.name"
      v-if="item.roles.includes(userRole)"
      :style="`--bg: ${item.bg}`"
      :to="item.name.startsWith('https') ? null : { name: item.name }"
      :href="item.name"
      :target="item.name.startsWith('https') ? '_blank' : null"
    )
      +e.icon
        +b.TEXT-BEMTO(
          :class="item.icon"
          :weight="'medium'"
          :size="'md'"
          :c="'white'"
          :tag="'i'"
        )
      +e.text.TEXT-BEMTO(
        v-if="side"
        :weight="'medium'"
        :height="'md'"
        :size="'xs'"
        :c="'white'"
        :tag="'p'"
      ) {{ item.title }}

  +e.subs-menu.ma--t_sm
    +e.link--subs.ROUTER-LINK(
      active-class="routers__link--active"
      v-for="item in subsMenu" :key="item.name"
      v-if="item.roles.includes(userRole)"
      :style="`--bg: ${item.bg}`"
      :to="{name: item.name}"
    )
      +e.icon
        +b.TEXT-BEMTO(
          :class="item.icon"
          :weight="'medium'"
          :size="'md'"
          :c="'green'"
          :tag="'i'"
        )
      +e.text.TEXT-BEMTO(
        v-if="side"
        :weight="'medium'"
        :height="'md'"
        :size="'xs'"
        :tag="'p'"
      ) {{ item.title }}
</template>

<script>
import {
  CORE_MENU_ITEMS,
  SUBS_MENU_ITEMS,
} from '@/javascript/routers/common'

export default {
  name: 'ElRouter',
  props: {
    side: {},
  },
  data() {
    return {
      userRole: window.role,
      coreMenu: CORE_MENU_ITEMS(this),
      subsMenu: SUBS_MENU_ITEMS(this),
    }
  },
}
</script>
