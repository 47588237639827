<template lang="pug">
include ../Bemto
+b.el-pagination
  row(
    :appearance="['spaced']"
    :space="'none'"
    :justify="'between'"
    :align="'center'"
    :tag="'div'"
  )
    cell(
      v-if="hasPrev()"
      :cols="['narrow']"
      :tag="'div'"
    )
      +e.button--prev.TEXT-BEMTO(
        :weight="'bold'"
        :size="'xs'"
        :tag="'p'"
        @event="changePage(prevPage)"
      )
        +b.icon-prev.I
    cell(
      :cols="['auto']"
      :tag="'div'"
    )
      row(
        :appearance="['spaced']"
        :space="['xs', 'md-mac']"
        :align="'center'"
        :justify="'center'"
        :tag="'div'"
      )
        cell(
          :cols="['narrow']"
          :tag="'div'"
        )
          +e.button--page.TEXT-BEMTO(
            :weight="'bold'"
            :size="'sm'"
            :tag="'p'"
            v-if="hasFirst()"
            @event="changePage(1)"
          ) 1
        cell(
          :cols="['narrow']"
          :tag="'div'"
        )
          row(
            :appearance="['spaced']"
            :space="['xs', 'md-mac']"
            :align="'center'"
            :tag="'div'"
          )
            cell(
              v-if="showDots.prev && currentPage - 1 !== 1 && currentPage - 2 !== 1"
            )
              +b.P ...
            cell(
              v-for="page in pages"
              :key="page"
              :cols="['narrow']"
              :tag="'div'"
            )
              +e.button--page.TEXT-BEMTO(
                :class="{ 'el-pagination__button--isactive': currentPage == page}"
                :weight="'bold'"
                :size="'sm'"
                :tag="'p'"
                @event="changePage(page)"
              ) {{ page }}
            cell(
              v-if="showDots.next && currentPage + 1 !== totalPages && currentPage + 2 !== totalPages"
            )
              +b.P ...
        cell(
          :cols="['narrow']"
          :tag="'div'"
        )
          +e.button--page.TEXT-BEMTO(
            :weight="'bold'"
            :size="'sm'"
            :tag="'p'"
            v-if="hasLast()"
            @event="changePage(totalPages)"
          ) {{ totalPages }}
    cell(
      v-if="hasNext()"
      :cols="['narrow']"
      :tag="'div'"
    )
      +e.button--next.TEXT-BEMTO(
        :weight="'bold'"
        :size="'xs'"
        :tag="'p'"
        @event="changePage(nextPage)"
      )
        +b.icon-next.I
</template>

<script>
export default {
  name: 'ElPagination',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 9,
    },
    pageRange: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page_size: 12,
      limit: 500,
      show: true,
      zero: 0,
      one: 1,
    }
  },
  computed: {
    pages() {
      const pages = []
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }
      return pages
    },
    rangeStart() {
      const start = this.currentPage - this.pageRange
      return (start > this.zero) ? start : this.one
    },
    rangeEnd() {
      const end = this.currentPage + this.pageRange
      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages() {
      return Math.ceil(this.total / this.perPage)
    },
    showDots() {
      const obj = {
        next: this.totalPages - this.currentPage,
        prev: this.one - this.currentPage,
      }
      return obj
    },
    nextPage() {
      return this.currentPage + this.one
    },
    prevPage() {
      return this.currentPage - this.one
    },
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== this.one
    },
    hasLast() {
      return this.rangeEnd < this.totalPages
    },
    hasPrev() {
      return this.currentPage > this.one
    },
    hasNext() {
      return this.currentPage < this.totalPages
    },
    changePage(page) {
      this.$emit('page', page)
    },
  },
}
</script>
