<template lang="pug">
include ../../../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.text--size_1sm.--weight_bold
  +e.body.VALIDATION-OBSERVER(
    ref="observer"
    tag="form"
  )
    +b.ROW(
      :appearance='["spaced"]'
      :space="'sm'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['12']"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :c="'black'"
          :tag="'p'"
        ) {{ _("Add phone") }}
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.phone"
          :input-label="_('Phone *')"
          v-mask="['+## (###) ### ## ##']"
          :rules="'required'"
          :name="'phone'"
          :modal="true"
          :id="'phone'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Save") }}
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'

import { postAddPhoneAPI } from '@/javascript/requests/services/account.service'

import { mask } from 'vue-the-mask'

export default {
  name: 'AddPhone',
  props: {
    objectItem: {},
  },
  directives: {
    mask,
  },
  mixins: [
    FormsMixin,
    PopupMixin,
  ],
  data() {
    return {
      /** Componen state. */
      formData: {
        phone: null,
      },
      /** Request. */
      isRequest: false,
    }
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return postAddPhoneAPI.execute({}, data).then(() => {
        this.$emit('update-parent-a-p-i')
        this.$emit('close')
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
