import { createRouter, checkPermissions } from './javascript/routers'
import { createStore } from './javascript/stores'
import { createI18n } from './i18n'

export function createApp({ Vue, Router, Store, I18n }) {
  const router = createRouter({ Vue, Router })
  const store = createStore({ Vue, Store })
  const i18n = createI18n({ Vue, I18n })
  checkPermissions(router)
  const app = new Vue({
    router,
    store,
    i18n,
    delimiters: ['[[', ']]'],

    mounted() {
      if (window.is_cabinet && window.is_technical_work) {
        this.openTechnicalWorkPopup()
      }
    },

    methods: {
      openTechnicalWorkPopup() {
        const popupObjectItem = {
          title: app._('На сайте ведутся технические работы'),
          text: app._('Извините за неудобства'),
        }

        this.$modal.show(() => import('@/javascript/components/modules/modal/SampleModal'), {
          component: 'SuccessModal',
          objectItem: popupObjectItem,
        }, {
          classes: 'modal modal--container modal--size_sm',
        })
      },
    },
  })

  return { app, router, store, i18n }
}
