<template lang="pug">
include ../../../../Bemto
+b.el-avatar
  +e.overlay
    +b.pointer.LABEL(
      for="avatar"
    )
      +b.icon-add-circle.TEXT-BEMTO(
        :config="'pointer-none'"
        :size="'3xl'"
        :c="'white'"
        :tag="'i'"
      )
    +e.input.INPUT(
      type="file" id="avatar"
      @change="setAvatarHandler"
    )
  +b.el-picture--absolute.PICTURE(
    v-if="!objectItem.avatar"
  )
    +e.element--cover.IMG(
      :src="'/static/pictures/noimage.jpg'"
      :alt="'Profile avatar'"
    )
  +b.el-picture--absolute.PICTURE(
    v-if="objectItem.avatar"
  )
    +e.element--cover.IMG(
      :src="objectItem.avatar"
      :alt="'Profile avatar'"
    )
</template>

<script>
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'

export default {
  name: 'CommonAvatar',
  props: {
    objectItem: {},
  },
  mixins: [
    PopupMixin,
  ],
  data() {
    return {
      /** Component state. */
      formData: {
        avatar: null,
      },
    }
  },
  methods: {
    /**
     * This is where the image is formatted, checked for validity
     * and data is passed on to the parent component.
     * @param {Object} event [description]
     */
    setAvatarHandler(event) {
      const file = event.target.files[0]
      const imageType = file.type.slice(file.type.lastIndexOf('/') + 1)
      const successType = ['png', 'jpeg']
      const reader = new FileReader()

      if (successType.includes(imageType)) {
        reader.onload = e => {
          this.formData.avatar = `${file.name};${e.target.result}`
          this.$emit('transfer-avatar', this.formData)
        }
        reader.readAsDataURL(file)
      } else {
        this.toggleToster(this._('Image must be in jpg / png format'))
      }
    },
  },
}
</script>
