<template lang="pug">
include ../../../Bemto
+b.TAG-BEMTO(
  :config="['radius-xs']"
  :spaces="'xs'"
  :size="'sm'"
  :bg="'white'"
  :block="'common'"
  :tag="'div'"
)
  +e.VALIDATION-OBSERVER(
    :ref="'observer'"
    :tag="'form'"
  )
    +b.ROW(
      :appearance="['spaced']"
      :space="'sm'"
      :justify="'center'"
      :align="'end'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['narrow']"
        :tag="'div'"
      )
        +b.TEXT-BEMTO(
          :weight="'bold'"
          :size="'lg'"
          :align="'center'"
          :c="'black'"
          :tag="'H1'"
        ) {{ _("Registration to your personal account") }}
      +b.CELL(
        :cols="['12', '6-sm']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.firstName"
          :input-label="_('Enter your name *')"
          @input="prettyString('firstName')"
          :rules="'required'"
          :name="'firstName'"
          :type="'text'"
          :id="'firstName'"
        )
      +b.CELL(
        :cols="['12', '6-sm']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.lastName"
          :input-label="_('Enter your last name *')"
          :rules="'required'"
          :name="'lastName'"
          @input="prettyString('lastName')"
          :type="'text'"
          :id="'lastName'"
        )
      +b.CELL(
        :cols="['12', '6-sm']"
        :tag="'div'"
      )
        +b.D-CONTROL-SELECTOR(
          v-if="Object.keys(optionsItem).length"
          v-model="formData.country"
          :object-list="optionsItem.countryList"
          :input-label="_('Select country *')"
          :placeholder="_('Select country *')"
          :rules="'required'"
          :search="true"
          :name="'country'"
          :id="'country'"
        )
      +b.CELL(
        :cols="['12', '6-sm']"
        :tag="'div'"
      )
        +b.D-CONTROL-SELECTOR(
          v-if="Object.keys(optionsItem).length"
          v-model="formData.userType"
          :object-list="optionsItem.userType"
          :input-label="_('Enterprise type')"
          :placeholder="_('Enterprise type')"
          :rules="'required'"
          :search="true"
          :clear="false"
          :name="'userType'"
          :id="'userType'"
        )
      +b.CELL(
        v-if="'2' === formData.userType"
        :cols="['12']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.company"
          :input-label="_('Enter the name of the company *')"
          :rules="'required'"
          :name="'company'"
          :type="'text'"
          :modal="true"
          :id="'company'"
        )
      +b.CELL(
        v-if="'2' === formData.userType"
        :cols="['12']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.companyScope"
          :input-label="_('Indicate the type of activity of the company')"
          :name="'companyScope'"
          :type="'text'"
          :modal="true"
          :id="'companyScope'"
        )
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.phone"
          v-mask="['+## (###) ### ## ##']"
          :input-label="_('Enter your phone number *')"
          :rules="'required'"
          :name="'phone'"
          :type="'text'"
          :modal="true"
          :id="'phone'"
        )
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.email"
          :input-label="_('Enter your Email *')"
          :rules="'required|email'"
          :name="'email'"
          :type="'text'"
          :id="'email'"
        )
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.password1"
          :input-label="_('Enter your password *')"
          :rules="'required|min:8'"
          :name="'password1'"
          :type="'password'"
          :modal="true"
          :id="'password1'"
        )
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.D-CONTROL-INPUT(
          v-model="formData.password2"
          :input-label="_('Repeat password *')"
          :rules="'required|min:8|confirmed:password1'"
          :name="'password2'"
          :type="'password'"
          :modal="true"
          :id="'password2'"
        )
      +b.CELL(
        :cols="['12']"
        :tag="'div'"
      )
        +b.VALIDATION-PROVIDER(
          :name="'nonFieldErrors'"
          v-slot="{ errors }"
        )
          +b.ma--b_sm.TEXT-BEMTO(
            v-if="errors.length"
            :weight="'medium'"
            :size="'xs'"
            :c="'red'"
            :tag="'p'"
          ) {{ errors[0] }}
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Registration") }}
      +b.ma--t_md.--b_md.CELL(
        :cols="['narrow']"
      )
        +b.ROW(
          :appearance="['spaced']"
          :space="'xs'"
          :justify="'center'"
          :align="'end'"
          :tag="'div'"
        )
          +b.CELL(
            :cols="['narrow']"
          )
            +b.LINK-BEMTO(
              :weight="'medium'"
              :size="'sm'"
              :c="'black'"
              :tag="'p'"
            ) {{ _("Already registered?") }}
          +b.CELL(
            :cols="['narrow']"
          )
            +b.c--hover_black.LINK-BEMTO(
              :config="'pointer'"
              :weight="'medium'"
              :size="'sm'"
              :c="'green'"
              :tag="'a'"
              :href="'/authorization/'"
            ) {{ _("Authorization") }}
      +b.CELL(
        :cols="['12']"
      )
        slot(
          name="social-registration"
        )
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'

import { postRegistrationAPI } from '@/javascript/requests/services/auth.service'
import { getCountryListAPI, getUserTypeAPI } from '@/javascript/requests/services/common.service'

import { mask } from 'vue-the-mask'

export default {
  name: 'CabinetRegistration',
  directives: {
    mask,
  },
  props: {
    referralLink: {},
  },
  mixins: [
    FormsMixin, // &&VALIDATES
    PopupMixin,
  ],
  async created() {
    await this.getCountryListAPI()
    await this.getEnterpriseAPI()
  },
  data() {
    return {
      optionsItem: {
        countryList: [],
        userType: [],
      },
      /** Component state. */
      formData: {
        firstName: null,
        lastName: null,
        country: null,
        userType: '1',
        company: null,
        companyScope: null,
        phone: null,
        email: null,
        password1: null,
        password2: null,
        ref: this.referralLink,
      },
    }
  },
  methods: {
    prettyString(key) {
      this.formData[key] = this.formData[key].replace(/[^a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ-]/g, '')
    },
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return postRegistrationAPI.execute({}, data).then(({ data: { item } }) => {
        window.location = item.redirectUrl
      })
    },
    /**
     * In this method, we get the generated countries
     */
    getCountryListAPI() {
      return getCountryListAPI.execute({}).then(({ data: { items } }) => {
        this.optionsItem.countryList = items
      })
    },
    getEnterpriseAPI() {
      return getUserTypeAPI.execute({}).then(({ data: { item: { userType } } }) => {
        this.optionsItem.userType = userType
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
