<template lang="pug">
include ../../../../Bemto
div
  +b.el-zebra__element--black.ma--t_sm
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Страна') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ amazonCountry || '-' }}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Область/штатт') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ amazonRegions || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Склад') }}
      cell
        text-bemto.zebra-indents(
          :size="'sm'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ amazonWarehouse || '-'}}
</template>

<script>
export default {
  name: 'fba-panel',
  props: ['amazonCountry', 'amazonRegions', 'amazonWarehouse'],
}
</script>
