import { createResource, postResource } from '../resources/resource'
import { ENDPOINTS } from '../ENDPOINTS'

export const postAuthorizationAPI = createResource(
  ENDPOINTS.AUTHORIZATION,
  postResource
)
export const postLogoutAPI = createResource(
  ENDPOINTS.LOGOUT,
  postResource
)
export const postRegistrationAPI = createResource(
  ENDPOINTS.REGISTRATION,
  postResource
)
export const postRestoreAccountAPI = createResource(
  ENDPOINTS.RESTORE_ACCOUNT,
  postResource
)
export const postCreatePasswordAPI = createResource(
  ENDPOINTS.CREATE_PASSWORD,
  postResource
)
export const postVerificationCodeAPI = createResource(
  ENDPOINTS.VERIFICATION_CODE,
  postResource
)
export const postChangePasswordAPI = createResource(
  ENDPOINTS.CHANGE_PASSWORD,
  postResource
)
export const postCreateEmailAPI = createResource(
  ENDPOINTS.CREATE_EMAIL,
  postResource
)
export const postChangeEmailAPI = createResource(
  ENDPOINTS.CHANGE_EMAIL,
  postResource
)
