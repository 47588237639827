<template lang="pug">
  include ../Bemto
  +b.control-input(
    :class="{'is-valid': value}"
  )
    +e.wrap-label
      +e.label.COMPONENT(
        v-if="inputLabel"
        :is='labelComponent',
        :input-label='inputLabel',
        :is-required='required',
        :for='id',
      )
      +e.label.clue.ma--l_xs(
        :for='id'
        v-if='clue'
      )
        +b.el-clue(
          @mouseenter='clueText = true'
          @mouseleave='clueText = false'
        ) ?
          +e.clue-text(
            v-if='clueText'
          )
            slot {{ clue }}
    +e.element.COMPONENT(
      ref='input',
      v-model='model',
      v-bind='$attrs',
      v-on="listeners",
      :is="element",
      :checked.prop='checked'
      :value.prop='value',
      :type='type',
      :id='id',
    )
    +e.bttn-search.I(
      v-if='searchBttns',
      @click.prevent='searchEvent()',
    )
    +e.loopa.I(
      v-if='type == "password"',
      :class='loopClass',
      @click.prevent='changeType()',
    )
</template>

<script>
export default {
  name: 'element-input',
  props: [
    'clue',
    'labelComponent',
    'inputLabel',
    'element',
    'required',
    'checked',
    'value',
    'type',
    'searchBttns',
    'id',
  ],
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.input = this.value
      },
    },
  },
  data() {
    return {
      show: false,
      input: '',
      clueText: false,
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: $event => this.$emit(
          'input', this.getEvent($event)
        ),
      }
    },
    loopClass() {
      return (this.show) ?
        'icon-invisible' :
        'icon-isvisible'
    },
    model: {
      get() {
        return this.input
      },
      set(e) {
        this.input = e
      },
    },
  },
  methods: {
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
    getEvent($event) {
      if ($event.timeStamp) {
        return $event.target[this.getKey()]
      }
      return $event
    },
    searchEvent() {
      this.$emit('search-event')
    },
    getKey() {
      return ('checkbox' === this.type) ?
        'checked' :
        'value'
    },
  },
}
</script>
