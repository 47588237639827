<template lang="pug">
include ../../Bemto
component(
  :is="component"
  :object-item="objectItem"
  :title="title"
  @close="$emit('close')"
  @update-parent-a-p-i="callParentAPI()"
)
</template>

<script>
export default {
  name: 'SampleModal',
  props: {
    component: {
      required: false,
    },
    objectItem: {
      required: false,
    },
    title: {
      required: false,
    },
    parentAPI: {
      required: false,
    },
  },
  created() {
    document.addEventListener(
      'keyup', evt => {
        if (27 === evt.keyCode) {
          this.$emit('close')
        }
      }, { passive: true }
    )
  },
  methods: {
    callParentAPI() {
      if (this.parentAPI) {
        this.parentAPI()
      }
    },
  },
}
</script>
