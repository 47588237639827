<template lang="pug">
  include ../../../../Bemto
  row
    cell.ma--b_sm(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
    )
      d-control-input(
        :name="'senderRegion'"
        :type="'text'"
        :clue='tooltips.senderRegion'
        :input-label="_('Укажите область/штат')"
        @input="$emit('sender-region', senderRegion)"
        v-model='senderRegion'
      )
    cell.ma--b_sm(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
    )
      d-control-input(
        :name="'senderCity'"
        :type="'text'"
        :clue='tooltips.senderCity'
        :input-label="_('Укажите область/город')"
        @input="$emit('sender-city', senderCity)"
        v-model='senderCity'
      )
    cell.ma--b_sm(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
    )
      d-control-input(
        :name="'senderStreet'"
        :type="'text'"
        :clue='tooltips.senderStreet'
        :input-label="_('Укажите улицу')"
        @input="$emit('sender-street', senderStreet)"
        v-model='senderStreet'
      )
    cell.ma--b_sm(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
    )
      d-control-input(
        :name="'senderHouse'"
        :type="'text'"
        :clue='tooltips.senderHouse'
        :input-label="_('Укажите номер здания')"
        @input="$emit('sender-house', senderHouse)"
        v-model='senderHouse'
      )
    cell(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
    )
      d-control-input(
        :name="'senderContactPerson'"
        :type="'text'"
        :clue='tooltips.senderContactPerson'
        :input-label="_('Укажите контактное лицо')"
        @input="$emit('sender-person', senderContactPerson)"
        v-model='senderContactPerson'
      )
    cell(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
    )
      d-control-input(
        :name="'senderPhone'"
        v-mask="['+## (###) ### ## ##']"
        :clue='tooltips.senderPhone'
        :input-label="_('Укажите номер телефона')"
        @input="$emit('sender-phone', senderPhone)"
        v-model='senderPhone'
      )
    cell(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
      :style="{'position': 'relative'}"
    )
      //- d-control-input(
      //-   :name="'senderPickupDate'"
      //-   v-model='senderPickupDate'
      //-   :type="'datetime-local'"
      //-   :rules="'required'"
      //-   @input="$emit('sender-date', senderPickupDate)"
      //-   :input-label="_('Укажите дату забора груза *')"
      //- )
      label(
        for='senderPickupDate'
      )
        row(
          :space="'xs'"
        )
          cell
            text-bemto.pa--b_xxs(
              :weight="'medium'"
              :size="['xs']"
              :tag="'p'"
            ) {{ _('Укажите дату забора груза') }}
          cell
            +b.el-clue(
              v-if='tooltips.senderPickupDate'
              @mouseenter='clueText = true'
              @mouseleave='clueText = false'
            ) ?
              +e.clue-text(
                v-if='clueText'
              )
                slot {{ tooltips.senderPickupDate }}
      label.error-date-label(
        for='senderPickupDate'
        v-if='dataError'
      )
        text-bemto(
          :weight="'medium'"
          :size="['xs']"
          :tag="'p'"
        ) {{ _('senderPickupDate is not valid') }}
      label.error-date-label(
        for='senderPickupDate'
        v-if='validError.senderPickupDate'
      )
        text-bemto(
          :weight="'medium'"
          :size="['xs']"
          :tag="'p'"
        ) {{ validError.senderPickupDate[0].message }}
      date-picker.datepick(
        :default-value="new Date()"
        :format="'YYYY-MM-DD'"
        :value-type="'format'"
        :disabled-date="disabledBeforeToday"
        :class="{'date-error': validError.senderPickupDate}"
        :name="'senderPickupDate'"
        v-model='senderPickupDate'
        @change="$emit('sender-date', senderPickupDate)"
        type='date'
      )
        template(#icon-calendar)
          +b.P.icon-calendar
    cell(
      :cols="['12', '6-sm', '4-lg','3-fhd']"
    )
      row(
        :space="'xs'"
      )
        cell
          text-bemto.ma--b_xxs(
            :config="'pointer-none'"
            :weight="'medium'"
            :size="'xs'"
            :tag="'p'"
          ) {{ _('Наличие дока для загрузки') }}
        cell
          +b.el-clue(
              v-if='tooltips.senderDockAvailability'
              @mouseenter='clueTextDock = true'
              @mouseleave='clueTextDock = false'
            ) ?
              +e.clue-text(
                v-if='clueTextDock'
              )
                slot {{ tooltips.senderDockAvailability }}
      +b.dock-wrap
        +b.radio-wrapper--transfer
          +b.d-control-input--row-initial()
            +e.element.INPUT(
              @change="$emit('sender-dock', senderDockAvailability)"
              v-model='senderDockAvailability'
              :name="'senderDockAvailability'"
              :value="true"
              :type="'radio'"
              :id="'senderDockAvailability1'"
            )
            +e.label.LABEL(
              :for="'senderDockAvailability1'"
            )
              text-bemto(
                :config="'pointer-none'"
                :c="'dared'"
                :tag="'p'"
              ) {{ _('Есть') }}
        +b.radio-wrapper--transfer
          +b.d-control-input--row-initial()
            +e.element.INPUT(
              v-model='senderDockAvailability'
              @change="$emit('sender-dock', senderDockAvailability)"
              :name="'senderDockAvailability'"
              :value="false"
              :type="'radio'"
              :id="'senderDockAvailability2'"
            )
            +e.label.LABEL(
              :for="'senderDockAvailability2'"
            )
              text-bemto(
                :config="'pointer-none'"
                :c="'dared'"
                :tag="'p'"
              ) {{ _('Нет') }}
</template>

<script>

import { mask } from 'vue-the-mask'

export default {
  name: 'pick-form',
  props: ['region', 'city', 'street', 'house', 'person', 'phone', 'date', 'dock', 'dataError', 'validError', 'tooltips'],
  directives: {
    mask,
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
  },
  data() {
    return {
      clueText: false,
      clueTextDock: false,
      data: new Date(),
      senderRegion: this.region,
      senderCity: this.city,
      senderStreet: this.street,
      senderHouse: this.house,
      senderContactPerson: this.person,
      senderPhone: this.phone,
      senderPickupDate: this.date,
      senderDockAvailability: this.dock,
    }
  },
}
</script>
