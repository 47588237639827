<template lang="pug">
include ../../Bemto
+b.tab(v-if="isActive")
  slot()
</template>

<script>
export default {
  name: 'TabUi',
  props: {
    title: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  mounted() {
    this.isActive = this.selected
  },
}
</script>
