import { createResource, postResource, patchResource, deleteResource } from '../resources/resource'
import { ENDPOINTS } from '../ENDPOINTS'

export const getAccountDetailsAPI = createResource(
  ENDPOINTS.ACCOUNT_DETAILS
)
export const patchAccountDetailsAPI = createResource(
  ENDPOINTS.ACCOUNT_DETAILS,
  patchResource
)
export const patchAccountAvatarAPI = createResource(
  ENDPOINTS.SET_AVATAR,
  patchResource
)
export const patchNotificationAPI = createResource(
  ENDPOINTS.PATCH_NOTIFICATIONS,
  patchResource
)
export const postEditEmailAPI = createResource(
  ENDPOINTS.CHANGE_EMAIL,
  postResource
)
export const patchEditPassAPI = createResource(
  ENDPOINTS.CHANGE_PASSWORD,
  patchResource
)
export const postAddPhoneAPI = createResource(
  ENDPOINTS.ADD_PHONE,
  postResource
)
export const deleteAccountPhoneAPI = createResource(
  ENDPOINTS.DEL_PHONE,
  deleteResource
)
export const deleteBidAPI = createResource(
  ENDPOINTS.DELETE_BID,
  deleteResource
)
