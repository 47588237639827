<template lang="pug">
include ../../../../Bemto
+b.ROW(
  :appearance="['spaced']"
  :justify="'between'"
  :align="'center'"
  :space="'xs'"
  :tag="'div'"
)
  +b.el-zebra__element--black(
    v-if="!objectItem.phones.length"
  )
    +b.CELL(
      :cols="['auto']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'regular'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('Phones') }}
    +b.CELL(
      :cols="['narrow']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'bold'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) -
    +b.CELL(
      :style="'display: flex'"
      :cols="['narrow']"
      :tag="'div'"
    )
      toggle-mixin-modal(
        :component-name="'AddPhone'"
        :parent-a-p-i="parentAPI"
      )
        template(
          #default="{handler}"
        )
          +b.icon-plus.pointer.TEXT-BEMTO(
            :size="'sm'"
            :c="'green'"
            :tag="'i'"
            @event="handler"
          )
  +b.el-zebra__element(
    v-if="objectItem.phones.length"
    v-for="(phone, index) in objectItem.phones" :key="index"
    :class="{'el-zebra__element--black': index % 2 == 0}"
  )
    +b.CELL(
      :cols="['auto']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        v-if="index < 1"
        :weight="'regular'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('Phones') }}
    +b.CELL(
      :cols="['narrow']"
      :tag="'div'"
    )
      +b.TEXT-BEMTO(
        :weight="'bold'"
        :height="'sm'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ phone.phone }}
    +b.CELL(
      v-if="index < 1"
      :style="'display: flex'"
      :cols="['narrow']"
      :tag="'div'"
    )
      toggle-mixin-modal(
        :component-name="'AddPhone'"
        :parent-a-p-i="parentAPI"
      )
        template(
          #default="{handler}"
        )
          +b.icon-plus.pointer.TEXT-BEMTO(
            :size="'sm'"
            :c="'green'"
            :tag="'i'"
            @event="handler"
          )
    +b.CELL(
      v-if="index > 0"
      :style="'display: flex'"
      :cols="['narrow']"
      :tag="'div'"
    )
      +b.icon-minus.pointer.TEXT-BEMTO(
        :size="'sm'"
        :c="'red'"
        :tag="'i'"
        @event="$emit('delete-phone-action', phone)"
      )
</template>

<script>
export default {
  name: 'PhonePanel',
  props: {
    objectItem: {},
    parentAPI: {},
  },
  mounted() {
    this.permission()
  },
  data() {
    return {
      role: null,
    }
  },
  methods: {
    /**
     * Depending on what role is currently
     * assigned to us, we must erroneously
     * indicate the component that we need.
     */
    permission() {
      const { role } = window
      this.role = role
    },
  },
}
</script>
