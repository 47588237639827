import CabinetAuthorization from './auth-community/CabinetAuthorization'
import CabinetRegistration from './auth-community/CabinetRegistration'
import CabinetEmailNotFound from './auth-community/CabinetEmailNotFound'
import CabinetSetPassword from './auth-community/CabinetSetPassword'
import PersonalInfo from './person-infos/PersonalInfo'

export default function install(Vue) {
  Vue.component(CabinetAuthorization.name, CabinetAuthorization)
  Vue.component(CabinetEmailNotFound.name, CabinetEmailNotFound)
  Vue.component(CabinetRegistration.name, CabinetRegistration)
  Vue.component(CabinetSetPassword.name, CabinetSetPassword)
  Vue.component(PersonalInfo.name, PersonalInfo)
}
