<template lang="pug">
include ../../../../Bemto
+b.el-table(
  :style="{transform: 'scaleY(-1)'}"
)
  +e.table--vision_order.TABLE(
    style='transform: scaleY(-1)'
  )
    +e.thead.THEAD
      +e.tr.TR
        +e.th--minimal.TH
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("Type") }}
        +e.th.TH
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("№ Number") }}
        +e.th.TH
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("Name") }}
        +e.th.TH
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("Date") }}
        +e.th.TH
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("Status") }}
        +e.th.TH(
          colspan="2"
        )
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("From where") }}
        +e.th.TH
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("Where to") }}
        +e.th.TH(
          colspan="2"
        )
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _("Pay") }}
    +e.tbody.TBODY(
      v-if="!objectList.length"
    )
      +b.pa--f_md.TEXT-BEMTO(
        :weight="'regular'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('Items not found') }}
    +e.tbody.TBODY(
      v-if="objectList.length"
    )
      +e.tr.TR(
        v-for="(item, index) in objectList" :key="item.id"
      )
        +e.td--minimal.TD
          +b.el-square--size_lg.--br_sm.--inline.bg--green(
            :class="typeMap.bg[item.bidType]"
          )
            +e.icon.icon-close.TEXT-BEMTO(
              :class="item.bidType === 4 ? typeMap.ic[item.bidType][item.other.deliveryType.id] : typeMap.ic[item.bidType]"
              :size="'lg'"
              :c="'white'"
              :tag="'i'"
            )
        +e.td.TD
          +b.c--black.c--hover_green.ROUTER-LINK(
            :to="routerData(item)"
          )
            +b.TEXT-BEMTO(
              :config="['underline', 'pointer-none']"
              :weight="'medium'"
              :height="'sm'"
              :size="['xs']"
              :tag="'p'"
            ) {{ item.crmNumber || '-' }}
        +e.td.TD
          +b.c--black.c--hover_green.ROUTER-LINK(
            :to="routerData(item)"
          )
            +b.TEXT-BEMTO(
              :config="['underline', 'pointer-none']"
              :weight="'medium'"
              :height="'sm'"
              :size="['xs']"
              :tag="'p'"
            ) {{ item.title || '-' }}
        +e.td.TD
          row.inner(
            :appearance="['spaced']"
            :align="'center'"
            :space="'xs'"
            :tag="'div'"
          )
            cell(
              :style="'display: flex;'"
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.icon-calendar.TEXT-BEMTO(
                :size="'md'"
                :c="'green'"
                :tag="'i'"
              )
            cell(
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'sm'"
                :size="['xs']"
                :c="'sans'"
                :tag="'p'"
              ) {{ item.createAt || '-' }}
        +e.td.TD
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :height="'sm'"
            :size="['xs']"
            :c="'green'"
            :tag="'p'"
          ) {{ item.status || '-' }}
        +e.td.TD
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :height="'sm'"
            :size="['xs']"
            :c="'black'"
            :tag="'p'"
          ) {{ item.other.senderCountry || '-' }}
        +e.td.TD
          +b.TEXT-BEMTO(
            v-if="!Object.keys(item.other).length"
            :weight="'medium'"
            :height="'sm'"
            :size="['xs']"
            :c="'green'"
            :tag="'p'"
          ) -
          +b.TEXT-BEMTO(
            v-if="Object.keys(item.other).length"
            :weight="'medium'"
            :height="'sm'"
            :size="['xs']"
            :c="'green'"
            :tag="'p'"
          ) {{ item.other.deliveryType.label || '-' }}
        +e.td.TD
          +b.TEXT-BEMTO(
            :weight="'medium'"
            :height="'sm'"
            :size="['xs']"
            :c="'black'"
            :tag="'p'"
          ) {{ item.other.recipientCountry || '-' }}
        +e.td.TD
          row(
            :appearance="['spaced']"
            :align="'center'"
            :justify="'between'"
            :space="'xs'"
            :tag="'div'"
          )
            cell(
              :style="'display: flex;'"
              :cols="['auto']"
              :tag="'div'"
            )
              +b.el-progress.PROGRESS(
                :value="item.payment"
                max="100"
              )
            cell(
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'sm'"
                :size="['sm']"
                :c="'black'"
                :tag="'p'"
              ) {{ item.payment }}%
        +e.td--minimal.TD
          +b.bell-inamation(
            v-if="item.message > 0"
          )
            +b.el-notification__bell.ROW(
              :appearance='["spaced"]'
              :align="'center'"
              :space="'none'"
              :tag="'div'"
            )
              +b.CELL(
                :cols="['narrow']"
              )
                +b.icon-chat.TEXT-BEMTO(
                  :weight="'medium'"
                  :size="'2md'"
                  :c="'grub'"
                  :tag="'i'"
                )
                +b.el-header-bell__counter
                  +e.text.TEXT-BEMTO(
                    :weight="'medium'"
                    :height="'md'"
                    :size="'xxs'"
                    :c="'white'"
                    :tag="'p'"
                  ) {{ item.message }}
        +e.td--minimal.TD
          +b.remove-order(
            @click="$emit('remove-table', item.id)"
          )
            +b.P.icon-cross(
              :style="{color: '#9d0308c7', cursor: 'pointer'}"
            )
</template>

<script>
export default {
  name: 'ActiveOrderTable',
  props: {
    objectList: {},
    typeMap: {},
  },
  methods: {
    routerData(item) {
      return {
        name: 'order-details',
        params: {
          id: item.id,
        },
      }
    },
  },
}
</script>
