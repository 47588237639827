<template lang="pug">
  include ../../../../Bemto
  row
    cell(
      :cols="['12', '6-sm', '4-lg']"
    )
      d-control-input(
        :name="'senderContactPerson'"
        :clue='tooltips.senderContactPerson'
        :type="'text'"
        :input-label="_('Укажите контактное лицо')"
        @input="$emit('contact-person', senderContactPerson)"
        v-model='senderContactPerson'
      )
    cell(
      :cols="['12', '6-sm', '4-lg']"
    )
      d-control-input(
        :name="'senderPhone'"
        v-mask="['+## (###) ### ## ##']"
        :clue='tooltips.senderPhone'
        :input-label="_('Укажите номер телефона')"
        @input="$emit('sender-phone', senderPhone)"
        v-model='senderPhone'
      )
    cell(
      :cols="['12', '6-sm', '4-lg']"
    )
      //- d-control-input(
      //-   :name="'senderPickupDate'"
      //-   :type="'datetime-local'"
      //-   :rules="'required'"
      //-   :input-label="_('Укажите дату забора груза *')"
      //-   @input="$emit('pick-date', senderPickupDate)"
      //-   v-model='senderPickupDate'
      //- )
      label(
        for='senderPickupDate'
      )
        row(
          :space="'xs'"
        )
          cell
            text-bemto.pa--b_xxs(
              :weight="'medium'"
              :size="['xs']"
              :tag="'p'"
            ) {{ _('Укажите дату забора груза') }}
          cell
            +b.el-clue(
              v-if='tooltips.senderPickupDate'
              @mouseenter='clueText = true'
              @mouseleave='clueText = false'
            ) ?
              +e.clue-text(
                v-if='clueText'
              )
                slot {{ tooltips.senderPickupDate }}
      label.error-date-label(
        for='senderPickupDate'
        v-if='dataError'
      )
        text-bemto(
          :weight="'medium'"
          :size="['xs']"
          :tag="'p'"
        ) {{ _('senderPickupDate is not valid') }}
      date-picker.datepick(
        :default-value="new Date()"
        :format="'YYYY-MM-DD'"
        :value-type="'format'"
        :disabled-date="disabledBeforeToday"
        :class="{'date-error': dataError}"
        :name="'senderPickupDate'"
        v-model='senderPickupDate'
        @input="$emit('pick-date', senderPickupDate)"
        type='date'
      )
        template(#icon-calendar)
          +b.P.icon-calendar
</template>

<script>

import { mask } from 'vue-the-mask'

export default {
  props: ['person', 'phone', 'date', 'dataError', 'tooltips'],
  name: 'provider-form',
  directives: {
    mask,
  },
  data() {
    return {
      clueText: false,
      senderContactPerson: this.person,
      senderPhone: this.phone,
      senderPickupDate: this.date,
    }
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
  },
}
</script>
