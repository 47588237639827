export default {
  methods: {
    toggleQuestion(componentName, title) {
      return new Promise(res => {
        const pr = arg => new Promise((resolve, reject) => {
          if (arg) resolve(arg)
          if (!arg) reject()
        }).then(result => {
          res(result)
        }, () => {
          this.$emit('close')
        })
        const data = { question: title, promise: pr }
        this.toggleModal(componentName, data)
      })
    },
    toggleModal(componentName, data, action) {
      this.$emit('close')
      this.$modal.show(() => import('../../../modules/modal/SampleModal'), {
        component: componentName,
        objectItem: data,
      }, {
        classes: 'modal modal--container modal--size_sm',
      }, {
        'before-close': () => {
          if (action) {
            console.log('action', action)
          }
        },
      })
    },
    toggleToster(message) {
      this.$toasted.show(message)
    },
  },
}
