<template lang="pug">
include ./Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.TEXT-BEMTO(
      :weight="'bold'"
      :size="'md'"
      :tag="'i'"
    )
  +e.body
    toggle-mixin-modal()
      template(
        #default="{handler}"
      )
        +b.pointer.TEXT-BEMTO(
          :align="'center'"
          :weight="'regular'"
          :height="'md'"
          :size="'sm'"
          :c="'black'"
          :tag="'p'"
          @event="handler"
        ) {{ _("Empty component") }}
</template>

<script>
export default {
  name: 'EmptyComponent',
}
</script>
