<template lang="pug">
include ../../../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.TEXT-BEMTO(
      :weight="'bold'"
      :size="'md'"
      :tag="'i'"
    )
  +e.body
    text-bemto.ma--b_md(
      :weight="'bold'"
      :height="'md'"
      :size="'lg'"
      :align="'start'"
      :c="'black'"
      :tag="'H4'"
    ) {{ _('Добавить товар') }}
    row(
      :justify="'center'"
    )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'title'"
          :input-label="_('Введите название товара')"
          v-model="product.title"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'sku'"
          :input-label="_('Введит артикул (SKU)')"
          v-model="product.sku"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'hts'"
          :input-label="_('HTS код')"
          v-model="product.hts"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-selector(
          :search='true'
          :name="'typePackaging'"
          :input-label="_('Тип упаковки')"
          v-model="product.typePackaging"
          :object-list='packType'
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :type="'number'"
          :name="'amount'"
          :input-label="_('Укажите количество штук в упаковке')"
          v-model="product.amount"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :type="'number'"
          :name="'cost'"
          :input-label="_('Стоимость единицы товара в $')"
          v-model="product.cost"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'applicationArea'"
          :input-label="_('Область приминения')"
          v-model="product.applicationArea"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'characteristics'"
          :input-label="_('Технические характеристики')"
          v-model="product.characteristics"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'composition'"
          :input-label="_('Материал/хим. состав')"
          v-model="product.composition"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'manufacturer'"
          :input-label="_('Производитель')"
          v-model="product.manufacturer"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'trademark'"
          :input-label="_('Торговая марка')"
          v-model="product.trademark"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-selector(
          :search='true'
          :name="'countryOrigin'"
          :input-label="_('Страна происхождения')"
          v-model="product.countryOrigin"
          :object-list='countries'
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'linkPhoto'"
          :input-label="_('Ссылка на фото товара')"
          v-model="product.linkPhoto"
        )
      cell.ma--b_md(
        :cols="['10', '6-sm']"
      )
        d-control-input(
          :name="'linkProduct'"
          :input-label="_('Ссылка на товар')"
          v-model="product.linkProduct"
        )
      cell.ma--b_md(
        :cols="['10', '12-sm']"
        :align="'center'"
      )
        text-bemto.ma--b_xxs(
            :size="'xs'"
            :tag="'p'"
            :weight="'medium'"
          ) {{ _('Прикрепить инвойс') }}
        +b.uploader-files
          +e.file-view {{ invoiceName }}
          input(
            :type="'file'"
            :id="'addFile'"
            :name="'addfile'"
            @input='setInvoice($event)'
          )
          label.add-label(
            for='addFile'
          )
            text-bemto(
              :config="'pointer-none'"
              :weight="'bold'"
              :c="'white'"
              :tag="'p'"
            ) {{ _('Прикрепить') }}
      cell(
        :cols="['10', '12-sm']"
        :align="'center'"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="createProduct"
        )
          +b.pa--r_md.--l_md.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Сохранить") }}
</template>

<script>

import { getSelectListAPI } from '@/javascript/requests/services/common.service'
import { getCountriesApi } from '@/javascript/requests/services/bid.service'

export default {
  name: 'AddProduct',
  props: {
    promise: Function,
  },
  mounted() {
    getSelectListAPI.execute().then(({ data: { item } }) => {
      this.packType = item.typePackaging
    })
    getCountriesApi.execute().then(({ data: countries }) => {
      this.countries = countries.items
    })
  },
  data() {
    return {
      packType: [],
      countries: [],
      invoiceName: '',
      product: {
        title: '',
        sku: '',
        hts: '',
        typePackaging: '',
        amount: '',
        cost: '',
        invoice: null,
        applicationArea: '',
        characteristics: '',
        composition: '',
        manufacturer: '',
        trademark: '',
        countryOrigin: '',
        linkPhoto: '',
        linkProduct: '',
      },
    }
  },
  methods: {
    setInvoice(e) {
      this.invoiceName = e.target.files[0].name
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = event => {
        this.product.invoice = [
          {
            attachedFile: `${this.invoiceName};${event.target.result}`,
            name: this.invoiceName,
          },
        ]
      }
    },
    createProduct() {
      this.promise(this.product)
      this.$emit('close')
    },
  },
}
</script>
