export const commonElement = {
  namespaced: true,
  state: {
    headerTitle: null,
  },
  getters: {
    getHeaderTitle: state => state.headerTitle,
  },
  actions: {
    setHeaderTitleAction({ commit }, value) {
      commit('setHeaderTitleMutation', value)
    },
  },
  mutations: {
    // eslint-disable-next-line
    setHeaderTitleMutation: (state, response) => state.headerTitle = response,
  },
}
