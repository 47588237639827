<template lang="pug">
include ../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.TEXT-BEMTO(
      :weight="'bold'"
      :size="'md'"
      :tag="'i'"
    )
  +e.body
    +b.TEXT-BEMTO.ma--b_md(
      :align="'center'"
      :tag="'p'"
    ) {{ _('Confirm removing') }}
    row
      cell(
        :cols="'6'"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'medium'"
          :align="'center'"
          :tag="'button'"
          @event="removeApp"
        )
          +b.pa--r_md.--l_md.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'xs'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Удалить") }}
      cell(
        :cols="'6'"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'medium'"
          :align="'center'"
          :tag="'button'"
          @event="$emit('close')"
        )
          +b.pa--r_md.--l_md.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'xs'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Закрыть") }}
</template>

<script>

export default {
  name: 'ConfirmModal',
  props: {
    objectItem: {},
  },
  methods: {
    removeApp() {
      this.objectItem.promise(this.objectItem.id)
      this.$emit('close')
    },
  },
}
</script>
