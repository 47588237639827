import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import Toasted from 'vue-toasted'
import GridPlugin from '@aspectus/vue-grid'
import TagPlugin from '@aspectus/vue-tag'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export function install(Vue) {
  Vue.use(DatePicker)
  Vue.use(VueClipboard)
  Vue.use(Notifications)
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(VueI18n)
  Vue.use(VModal, {
    dynamic: true,
    dynamicDefaults: {
      transition: 'nice-modal-fade',
      height: 'auto',
      scrollable: false,
    },
  })
  Vue.use(Toasted, {
    theme: 'toasted-primary',
    position: 'bottom-left',
    duration: 4000,
  })
  Vue.use(GridPlugin)
  Vue.use(TagPlugin)
}

export default { install }
