import SampleComponent from './SampleComponent'
import AdministratorPages from './roles/administrator'
import ManagerPages from './roles/manager'
import UserPages from './roles/user'
import JointPages from './joint'

export default function install(Vue) {
  Vue.component(SampleComponent.name, SampleComponent)
  Vue.use(AdministratorPages)
  Vue.use(ManagerPages)
  Vue.use(UserPages)
  Vue.use(JointPages)
}
