<template lang="pug">
include ../../Bemto
div(:class="tabClass")
  +b.tabs
    +e.elements.UL
      +e.item.LI(
        v-for="(tab, index) in tabs" :key="index"
        :class="{'tabs__item--isactive': tab.isActive}"
        @click="selectTab(tab)"
      )
        +e.title.P {{ tab.title }}
    +e.details
      slot()
</template>

<script>
export default {
  name: 'TabsUi',
  props: {
    tabClass: {
      type: String,
      default: 'tabs',
    },
  },
  created() {
    this.tabs = this.$children
  },
  data() {
    return {
      tabs: [],
    }
  },
  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach(tab => {
        const el = tab
        el.isActive = (el.title === selectedTab.title)
      })
    },
  },
}
</script>
