import './public-path'

import Vue from 'vue'
import Router from 'vue-router'
import Vuex from 'vuex'
import I18n from 'vue-i18n'
import { extend } from 'vee-validate'
import is from 'is_js'
// import { token } from './javascript/utils/token'
import { required, confirmed, email, min } from 'vee-validate/dist/rules'
import { install } from './install'
import { createApp } from './app'

import iComponents from './javascript/components/iComponents'

import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(install)
Vue.use(Vuex)
Vue.use(iComponents)

const { app } = createApp({
  Vue,
  I18n,
  Router,
  Vuex,
})

app.$mount('#app')

extend('url', {
  validate: value => is.url(value),
  message: app._('This filed must be a valid URL'),
})
extend('required', { ...required, message: app._('This field is required.') })
extend('min', { ...min, message: (e, a) => `${app._('Количество символов должно быть не меньше')} ${a.length}` })
extend('email', { ...email, message: app._('Email is invalid.') })
extend('confirmed', { ...confirmed, message: app._('Passwords must match.') })

// document.addEventListener('DOMContentLoaded', () => {
//   token()
// })
