import EditCommonInfo from './personal-info/EditCommonInfo'
import EditEmailInfo from './personal-info/EditEmailInfo'
import EditPassInfo from './personal-info/EditPassInfo'
import EditCompanyInfo from './personal-info/EditCompanyInfo'

export default function install(Vue) {
  Vue.component(EditCommonInfo.name, EditCommonInfo)
  Vue.component(EditEmailInfo.name, EditEmailInfo)
  Vue.component(EditPassInfo.name, EditPassInfo)
  Vue.component(EditCompanyInfo.name, EditCompanyInfo)
}
