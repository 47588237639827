<template lang="pug">
include ../../../Bemto
+b.el-header
  +b.crutch-wrapper(
    v-if="!path.includes('active-orders')"
  )
    +b.ROUTER-LINK.holy-crutch(
      :to="{name: 'active-orders'}"
    )
      +b.picture.PICTURE
        +b.freshworks__side--mobile-logo.IMG(
          src='/static/pictures/tb.png' alt="MB"
        )
        +b.freshworks__side--tablet-logo.IMG(
          v-if="side"
          :src='logo' alt="PC"
        )
  +b.crutch-wrapper(
    v-else
  )
    +b.ROUTER-LINK.holy-crutch(
      :to="{name: 'active-orders'}"
    )
      +b.picture.PICTURE
        +b.freshworks__side--mobile-logo.IMG(
          src='/static/pictures/tb.png' alt="MB"
        )
        +b.freshworks__side--tablet-logo.IMG(
          v-if="side"
          :src='logo' alt="PC"
        )
  +e.toggler.pointer(
    :class="freshworksTG(side)"
    @click.prevent="$emit('toggle-side')"
  )
    +b.TEXT-BEMTO(
      :class="freshworksSL(side)"
      :size="'lg'"
      :tag="'i'"
    )
  +b.ROW(
    :appearance='["spaced"]'
    :space="'none'"
    :justify="['between']"
    :align="['center']"
    :tag="'div'"
  )
    +b.CELL(
      :cols="['narrow']"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :align="['center']"
        :space="'sm'"
        :tag="'div'"
      )
        +b.u-display--block.--hidden-md.CELL(
          :cols="['narrow']"
        )
          +b.EL-ROUTER-MENU()
        +b.CELL(
          :cols="['narrow']"
        )
          +b.TEXT-BEMTO(
            :weight="'bold'"
            :height="'md'"
            :size="['xxs', 'md-sm', 'lg-md', '2md-lg']"
            :c="'hask'"
            :tag="'p'"
          ) {{ getHeaderTitle || _('Empty title') }}
    +b.u-display--hidden.--block-xl.CELL(
      :cols="['narrow']"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :align="'center'"
        :space="'md'"
        :tag="'div'"
      )
        +b.CELL(
          :cols="['narrow']"
        )
          +b.EL-NAVIGATIONS()
    +b.u-display--block.--hidden-xl.CELL(
      :cols="['narrow']"
    )
      +b.EL-COMMON-MENU()
        template(v-slot:personal-info="")
          +b.pa--f_md
            +b.ROW(
              :appearance='["spaced"]'
              :align="'center'"
              :justify="'center'"
              :space="'md'"
              :tag="'div'"
            )
              +b.CELL(
                :cols="['narrow']"
              )
                +b.el-circle--size_large
                  +b.el-picture--absolute.PICTURE
                    +e.element--cover.IMG(
                      v-if='detail.avatar'
                      :src='detail.avatar'
                      alt="Profile avatar"
                    )
                    +e.element--cover.IMG(
                      v-else
                      src='/static/pictures/noimage.jpg'
                      alt="Profile avatar"
                    )
              +b.CELL(
                :cols="['12']"
              )
                +b.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'lg'"
                  :size="'sm'"
                  :align="'center'"
                  :c="'black'"
                  :tag="'p'"
                ) {{ detail.firstName }} {{ detail.lastName }}
        template(v-slot:email="")
          +b.pa--r_md.--b_md.--l_md.u-display--block.--hidden-xl
            +b.ROW(
              :appearance='["spaced"]'
              :align="'center'"
              :justify="'center'"
              :space="'md'"
              :tag="'div'"
            )
              +b.CELL(
                :cols="['narrow']"
              )
                +b.el-circle--size_medium.--align_center
                  +b.icon-mail.TEXT-BEMTO(
                    :size="'md'"
                    :c="'green'"
                    :tag="'p'"
                  )
              +b.CELL(
                :cols="['12']"
              )
                +b.ROW(
                  :appearance='["spaced"]'
                  :align="'center'"
                  :space="'xs'"
                  :tag="'div'"
                )
                  +b.CELL(
                    :cols="['12']"
                  )
                    +b.c--hover_green.LINK-BEMTO(
                      :weight="'medium'"
                      :height="'xs'"
                      :size="'sm'"
                      :align="'center'"
                      :c="'black'"
                      :href="'mailto:' + email"
                      :tag="'a'"
                    ) {{ email }}
        template(v-slot:exit="")
          +b.pa--f_md
            +b.el-router__link--core.pointer(
              :style="'--bg: #46ceac'"
              @click.prevent="postLogoutAPI()"
            )
              +b.TEXT-BEMTO(
                :config="'full'"
                :weight="'medium'"
                :height="'md'"
                :size="'xs'"
                :align="'center'"
                :c="'white'"
                :tag="'p'"
              ) {{ _('Выход') }}
</template>

<script>
import { postLogoutAPI } from '@/javascript/requests/services/auth.service'
import { getAccountDetailsAPI } from '@/javascript/requests/services/account.service'
import { mapGetters } from 'vuex'

export default {
  name: 'ElHeader',
  props: {
    side: {},
  },
  data() {
    return {
      logo: window.LOGO,
      username: window.USERNAME,
      email: window.USER_EMAIL,
      detail: '',
    }
  },
  mounted() {
    this.accDetail()
  },
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    postLogoutAPI() {
      return postLogoutAPI.execute({}).then(() => {
        document.location.reload()
      })
    },
    freshworksTG(side) {
      /* eslint-disable */
      return (side)
        ? 'el-header__toggler--inactive'
        : 'el-header__toggler--isactive'
    },
    freshworksSL(side) {
      /* eslint-disable */
      return (side)
        ? 'icon-sideleft '
        : 'icon-sideright'
    },
    accDetail() {
      getAccountDetailsAPI.execute({}).then(({ data: { item }}) => {
        this.detail = item
      })
    }
  },
  computed: {
    ...mapGetters('commonElement', [
      'getHeaderTitle',
    ]),

    path() {
      return window.location.href
    }
  },
}
</script>
