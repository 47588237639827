<template lang="pug">
include ../../../../Bemto
+b.el-active-order
  +b.remove-order.--panel(
    @click="$emit('remove-panel', objectItem.id)"
  )
    +b.P.icon-cross
  +e.common--first
    +e.common--spaces_sm.--br_bott
      row(
        :appearance="['spaced']"
        :align="'center'"
        :space="'sm'"
        :tag="'div'"
      )
        cell(
          :cols="['auto']"
          :tag="'div'"
        )
          +b.el-active-order__flex
            +b.el-square--size_lg.--br_sm(
              :class="typeMap.bg[objectItem.bidType]"
            )
              +b.TEXT-BEMTO(
                :class="objectItem.bidType === 4 ? typeMap.ic[objectItem.bidType][objectItem.other.deliveryType.id] : typeMap.ic[objectItem.bidType]"
                :size="'lg'"
                :c="'white'"
                :tag="'i'"
              )
            div(
              :style="{display: 'flex', flexDirection: 'column'}"
            )
              +b.ma--l_sm.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm', 'md-xl']"
                  :tag="'p'"
                ) {{ orderTitle[typeMap.bg[objectItem.bidType]] }}
              +b.ROUTER-LINK.c--black.c--hover_green(
                :to='routerData(objectItem)'
              )
                +b.ma--l_sm.TEXT-BEMTO(
                  :config="['pointer-none']"
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm', 'md-xl']"
                  :tag="'p'"
                ) {{ objectItem.crmNumber || '-' }}
        cell.bell-inamation(
          :style="{marginTop: '30px'}"
          v-if="objectItem.message > 0"
          :cols="['narrow']"
          :tag="'div'"
        )
          +b.el-notification__bell.ROW(
            :appearance='["spaced"]'
            :align="'center'"
            :space="'none'"
            :tag="'div'"
          )
            +b.CELL(
              :cols="['narrow']"
            )
              +b.icon-chat.TEXT-BEMTO(
                :weight="'medium'"
                :size="'2md'"
                :c="'grub'"
                :tag="'i'"
              )
              +b.el-header-bell__counter
                +e.text.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'md'"
                  :size="'xxs'"
                  :c="'white'"
                  :tag="'p'"
                ) {{ objectItem.message }}
    +e.common--spaces_sm.--br_bott
      +b.ROUTER-LINK.c--black.c--hover_green(
        :to='routerData(objectItem)'
      )
        +b.TEXT-BEMTO(
          :config="['underline', 'pointer-none']"
          :weight="'medium'"
          :height="'sm'"
          :size="['sm']"
          :tag="'p'"
        ) {{ objectItem.title || '-' }}
    +e.common--spaces_sm
      row(
        :appearance="['spaced']"
        :align="'center'"
        :space="'sm'"
        :tag="'div'"
      )
        cell(
          :cols="['12']"
          :tag="'div'"
        )
          row(
            :appearance="['spaced']"
            :align="'center'"
            :space="'xs'"
            :tag="'div'"
          )
            cell(
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'sm'"
                :size="['sm']"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Status:') }}
            cell(
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'bold'"
                :height="'sm'"
                :size="['sm']"
                :c="'green'"
                :tag="'p'"
              ) {{ objectItem.status || '-' }}
        cell(
          :cols="['12']"
          :tag="'div'"
        )
          row(
            :appearance="['spaced']"
            :align="'center'"
            :justify="'between'"
            :space="'xs'"
            :tag="'div'"
          )
            cell(
              :cols="['12']"
              :tag="'div'"
            )
              row(
                :appearance="['spaced']"
                :align="'center'"
                :justify="'between'"
                :space="'xs'"
                :tag="'div'"
              )
                cell(
                  :cols="['auto']"
                  :tag="'div'"
                )
                  +b.TEXT-BEMTO(
                    :weight="'medium'"
                    :height="'sm'"
                    :size="['sm']"
                    :c="'black'"
                    :tag="'p'"
                  ) {{ _('Payment order') }}
                cell(
                  :cols="['narrow']"
                  :tag="'div'"
                )
                  +b.TEXT-BEMTO(
                    :weight="'medium'"
                    :height="'sm'"
                    :size="['sm']"
                    :c="'black'"
                    :tag="'p'"
                  ) {{ objectItem.payment }}%
            cell(
              :cols="['12']"
              :tag="'div'"
            )
              +b.el-progress.PROGRESS(
                :value="objectItem.payment"
                max="100"
              )
        cell(
          v-if="Object.keys(objectItem.other).length"
          :cols="['12']"
          :tag="'div'"
        )
          row(
            :appearance="['spaced']"
            :align="'center'"
            :justify="'between'"
            :space="'xs'"
            :tag="'div'"
          )
            cell(
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'sm'"
                :size="['xs']"
                :c="'black'"
                :tag="'p'"
              ) {{ objectItem.other.senderCountry }}
            cell(
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'sm'"
                :size="['xs']"
                :c="'green'"
                :tag="'p'"
              ) {{ objectItem.other.deliveryType.label }}
            cell(
              :cols="['narrow']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'sm'"
                :size="['xs']"
                :c="'black'"
                :tag="'p'"
              ) {{ objectItem.other.recipientCountry }}
  +e.common--br_top
    row(
      :appearance="['spaced']"
      :align="'center'"
      :space="'none'"
      :tag="'div'"
    )
      cell.pa--l_1sm(
        :cols="['auto']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :align="'center'"
          :space="'xs'"
          :tag="'div'"
        )
          cell(
            :style="'display: flex;'"
            :cols="['narrow']"
            :tag="'div'"
          )
            +b.icon-calendar.TEXT-BEMTO(
              :size="'md'"
              :c="'green'"
              :tag="'i'"
            )
          cell(
            :cols="['narrow']"
            :tag="'div'"
          )
            +b.TEXT-BEMTO(
              :weight="'medium'"
              :height="'sm'"
              :size="['sm']"
              :c="'sans'"
              :tag="'p'"
            ) {{ objectItem.createAt || '-' }}
      cell(
        :cols="['narrow']"
        :tag="'div'"
      )
        +b.c--green.--hover_hask.ROUTER-LINK(
          :to="routerData(objectItem)"
        )
          +b.el-active-order__open.pointer--initial
            +b.icon-open.TEXT-BEMTO(
              :size="'md'"
              :tag="'i'"
            )
</template>

<script>

export default {
  name: 'ActiveOrderPanel',
  props: {
    objectItem: {},
    typeMap: {},
  },
  data() {
    return {
      orderTitle: {
        'bg--werf': this._('Product search request'),
        'bg--difr': this._('Transport request'),
        'bg--aqua': this._('Product ransom request'),
        'bg--blue': this._('Product pay request'),
      },
    }
  },
  methods: {
    routerData(item) {
      return {
        name: 'order-details',
        params: {
          id: item.id,
        },
      }
    },
  },
}
</script>
