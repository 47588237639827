<template lang="pug">
include ../../Bemto
+b.modal
  +e.close(
    @click.prevent="$emit('close')"
  )
    +b.icon-close.TEXT-BEMTO(
      :weight="'bold'"
      :size="'md'"
      :tag="'i'"
    )
  +e.body.VALIDATION-OBSERVER(
    ref="observer"
    tag="form"
  )
    +b.ROW(
      :appearance='["spaced"]'
      :space="'sm'"
      :align="'center'"
      :justify="'center'"
      :tag="'div'"
    )
      +b.CELL(
        :cols="['12']"
      )
        +b.TEXT-BEMTO(
          :align="'center'"
          :weight="'bold'"
          :size="'lg'"
          :c="'black'"
          :tag="'p'"
        ) {{ _("Restore password") }}
      +b.CELL(
        :cols="['12']"
      )
        +b.input--modal.D-CONTROL-INPUT(
          v-model="formData.email"
          :input-label="_('Enter your Email *')"
          :rules="'required|email'"
          :name="'email'"
          :type="'text'"
          :id="'email'"
        )
      +b.CELL(
        :cols="['12']"
      )
        +b.VALIDATION-PROVIDER(
          :name="'non_field_errors'"
          v-slot="{ errors }"
        )
          +b.ma--b_sm.TEXT-BEMTO(
            v-if="errors.length"
            :weight="'medium'"
            :size="'xs'"
            :c="'red'"
            :tag="'p'"
          ) {{ errors[0] }}
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-grn']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="validateBeforeAPI(formData)"
        )
          +b.pointer--initial.TEXT-BEMTO(
            :config="'uppercase'"
            :weight="'bold'"
            :size="'sm'"
            :c="'white'"
            :tag="'p'"
          ) {{ _("Send request") }}
</template>

<script>
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'

import { postRestoreAccountAPI } from '@/javascript/requests/services/auth.service'

export default {
  name: 'RSPassword',
  mixins: [
    FormsMixin, // &&VALIDATES
    PopupMixin,
  ],
  data: () => ({
    /** Component state. */
    formData: {
      email: null,
    },
  }),
  methods: {
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    sendHandlerAPI(data) {
      return postRestoreAccountAPI.execute({}, data).then(({ data: { item } }) => {
        const popupObjectItem = {
          title: item.title, text: item.text,
        }
        this.toggleModal('SuccessModal', popupObjectItem)
      })
    },
    /**
     * After > error display normalization. Set Observer errors.
     * @param {Object} errors [description]
     */
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
    },
  },
}
</script>
