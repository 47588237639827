import { USER_ENDPOINTS } from './ENDPOINTS'

const USER_ROUTES = [
  {
    component: () => import('@/javascript/components/pages/roles/user/test/Test'),
    path: USER_ENDPOINTS.TEST,
    name: 'user-test',
    meta: { permissions: ['client'] },
  },
]

export default USER_ROUTES
