<template lang="pug">
include ../../../../Bemto
row(
  :appearance="['spaced']"
  :align="'center'"
  :space="'sm'"
  :tag="'div'"
)
  cell(
    :cols="['12']"
    :tag="'div'"
  )
    row(
      :appearance="['spaced']"
      :align="'center'"
      :space="'sm'"
      :tag="'div'"
    )
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :align="'center'"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['narrow']"
            :tag="'div'"
          )
            +b.TEXT-BEMTO(
              :weight="'medium'"
              :size="['lg']"
              :c="'hask'"
              :tag="'p'"
            ) {{objectItem.title }}
          cell(
            :cols="['auto']"
            :tag="'div'"
          )
            row(
              :appearance="['spaced']"
              :align="'center'"
              :space="'xs'"
              :tag="'div'"
            )
              cell(
                :style="'display: flex;'"
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.icon-calendar.TEXT-BEMTO(
                  :size="'md'"
                  :c="'green'"
                  :tag="'i'"
                )
              cell(
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'sans'"
                  :tag="'p'"
                ) {{ objectItem.createAt || '-' }}
          //- cell(
          //-   :cols="['narrow']"
          //-   :tag="'div'"
          //- )
          //-   chat-btn(
          //-     :countMessage='messageNotification'
          //-     @toggle-chat="$emit('sub-toggle')"
          //-     @create-chat="$emit('sub-create', objectItem.id)"
          //-   )
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :align="'center'"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['6', '4-md', '2-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Pay (USD)") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].pay_usd || '-' }}
          cell(
            :cols="['12', '8-md', '3-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Invoice") }}
              file-demonstration.search-invoice(
                :empty-data-title="_('Files not found')"
                :object-item="objectItem.bidRedemption[0].bidRedemptionInvoices"
                :justify="'start'"
                :space="'sm'"
                :type="'files'"
              )
          cell(
            :cols="['12', '4-md', '2-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Payment invoice") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].payment_invoice || '-' }}
          cell(
            :cols="['12', '4-md', '5-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Respond") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].respond || '-' }}
          cell(
            :cols="['6', '4-md', 'auto-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Redemption date") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].redemption_date || '-' }}
          cell(
            :cols="['6', '4-md', 'auto-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Redeemed") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].redeemed || '-' }}
          cell(
            :cols="['6', '4-md', 'auto-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Cost per piece") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].cost_per_piece || '-' }}
          cell(
            :cols="['6', '4-md', 'auto-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Summ (CNY)") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].summ_cny || '-' }}
          cell(
            :cols="['6', '4-md', 'auto-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Delivery (CNY)") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].delivery_cny || '-' }}
          cell(
            :cols="['6', '4-md', 'auto-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Commission (CNY)") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].commission || '-' }}
          cell(
            :cols="['6', '4-md', 'auto-mac']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Rates USD / CNY") }}
              +e.element.--initial.--green {{ objectItem.bidRedemption[0].rates || '-' }}
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :align="'center'"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['12', '6-sm', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Product name") }}
              +e.element.--initial {{ objectItem.bidRedemption[0].productName || '-' }}
          cell(
            :cols="['12', '6-sm', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Order code (SKU)") }}
              +e.element.--initial {{ objectItem.bidRedemption[0].sku || '-' }}
          cell(
            :cols="['12', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              +e.label {{ _("Product link") }}
              +e.element.--initial {{ objectItem.bidRedemption[0].linkProduct || '-' }}
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['12', '6-lg']"
            :tag="'div'"
          )
            row(
              :appearance="['spaced']"
              :space="'sm'"
              :tag="'div'"
            )
              cell(
                :cols="['12', '6-sm', '4-lg']"
                :tag="'div'"
              )
                +b.control-input
                  +e.label {{ _("Amount") }}
                  +e.element.--initial {{ objectItem.bidRedemption[0].amount || '-' }}
              cell(
                :cols="['12', '6-sm', '4-lg']"
                :tag="'div'"
              )
                +b.control-input
                  +e.label {{ _("Price for 1") }}
                  +e.element.--initial {{ objectItem.bidRedemption[0].price || '-' }}
              cell(
                :cols="['12', '4-lg']"
                :tag="'div'"
              )
                +b.control-input
                  +e.label {{ _("Delivery type") }}
                  +e.element.--initial {{ objectItem.bidRedemption[0].deliveryRepr || '-' }}
              cell(
                :cols="['12']"
                :tag="'div'"
              )
                +b.control-input
                  +e.label {{ _("Comment") }}
                  +e.element.--text {{ objectItem.bidRedemption[0].comment || '-' }}
          cell(
            :cols="['12', '6-lg']"
            :tag="'div'"
          )
            row(
              :appearance="['spaced']"
              :space="'sm'"
              :tag="'div'"
            )
              cell(
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.radio-wrapper.ma--t_1md-lg
                  +b.d-control-input--row-initial()
                    +e.element.--initial.pointer--initial.INPUT(
                      v-model="objectItem.bidRedemption[0].redeem"
                      :name="'redeem'"
                      :type="'checkbox'"
                      :id="'redeem'"
                    )
                    +e.label.TEXT-BEMTO(
                      :config="'pointer-none'"
                      :weight="'medium'"
                      :height="'sm'"
                      :size="['sm']"
                      :c="'dared'"
                      :tag="'p'"
                    ) {{ _('Redeem if available.') }}
              cell(
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.radio-wrapper.ma--t_1md-lg
                  +b.d-control-input--row-initial()
                    +e.element.--initial.pointer--initial.INPUT(
                      v-model="objectItem.bidRedemption[0].recalculate"
                      :name="'redeem'"
                      :type="'checkbox'"
                      :id="'redeem'"
                    )
                    +e.label.TEXT-BEMTO(
                      :config="'pointer-none'"
                      :weight="'medium'"
                      :height="'sm'"
                      :size="['sm']"
                      :c="'dared'"
                      :tag="'p'"
                    ) {{ _('Recalculate units') }}
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'RansomOrderInfo',
  props: {
    objectItem: {},
    messageNotification: Number,
  },
  async created() {
    /** Set header name on the page. */
    await this.setHeaderTitleAction(
      /* eslint-disable prefer-template */
      this.objectItem.crmNumber
    )
  },
  methods: {
    ...mapActions('commonElement', [
      'setHeaderTitleAction',
    ]),
  },
}
</script>
