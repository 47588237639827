<template lang="pug">
include ../../../Bemto
sample-profile()
  template(
    v-slot:content=""
  )
    +b.ROW(
      :tag="'div'"
    )
      +b.CELL(
        :cols="['12', '9-fhd']"
        :tag="'div'"
      )
        +b.ma--b_md.TAG-BEMTO(
          :config="'radius-sm'"
          :spaces="'md'"
          :bg="'white'"
          :block="'el-core'"
          :tag="'div'"
        )
          +b.el-zebra.ROW(
            :appearance="['spaced']"
            :space="'md'"
            :justify="'center'"
            :tag="'div'"
          )
            +b.CELL(
              :cols="['narrow']"
              :tag="'div'"
            )
              //- (Avatar [Add, Edit])
              +b.COMMON-AVATAR(
                v-if="getAccountDetails"
                :object-item="getAccountDetails"
                @transfer-avatar="patchAccountAvatarAPI($event)"
              )
            +b.CELL(
              :cols="['12', 'auto-md']"
              :tag="'div'"
            )
              +b.ROW(
                :justify="'center'"
                :tag="'div'"
              )
                +b.CELL(
                  :cols="['12', '6-xl']"
                  :tag="'div'"
                )
                  //- (Name, Country, Street [Edit])
                  +b.COMMON-PANEL(
                    v-if="getAccountDetails"
                    :object-item="getAccountDetails"
                    :parent-a-p-i="getAccountDetailsAPI"
                  )
                  //- (Email [Edit])
                  +b.ma--t_xxs.EMAIL-PANEL(
                    v-if="getAccountDetails"
                    :object-item="getAccountDetails"
                    :parent-a-p-i="getAccountDetailsAPI"
                  )
                  //- (Password [Edit])
                  +b.ma--t_xxs.PASS-PANEL(
                    v-if="getAccountDetails"
                    :object-item="getAccountDetails"
                  )
                +b.CELL(
                  :cols="['12', '6-xl']"
                  :tag="'div'"
                )
                  //- (Phone [Edit])
                  +b.PHONE-PANEL(
                    v-if="getAccountDetails"
                    :object-item="getAccountDetails"
                    :parent-a-p-i="getAccountDetailsAPI"
                    @delete-phone-action="deleteAccountPhoneAPI($event)"
                  )
                  //- (Company [Edit])
                  +b.ma--t_xxs.ABOUT-PANEL(
                    v-if="getAccountDetails"
                    :object-item="getAccountDetails"
                    :parent-a-p-i="getAccountDetailsAPI"
                  )
        +b.ma--b_md.TAG-BEMTO(
          :config="'radius-sm'"
          :spaces="'md'"
          :bg="'white'"
          :block="'el-core'"
          :tag="'div'"
        )
          +b.CELL(
            :cols="['12']"
          )
            +b.TEXT-BEMTO(
              v-if='getAccountDetails.referral'
              :height="'sm'"
              :size="['md']"
              :c="'black'"
              :tag="'p'"
            ) {{ getAccountDetails.referral.referralDescription }}
        +b.ma--b_md.TAG-BEMTO(
          v-if="getAccountDetails.referral.balance >= 0"
          :config="'radius-sm'"
          :spaces="'md'"
          :bg="'white'"
          :block="'el-core'"
          :tag="'div'"
        )
          +b.ROW(
            :appearance="['spaced']"
            :space="'sm'"
            :justify="'between'"
            :align="'center'"
            :tag="'div'"
          )
            +b.CELL(
              :cols="['12', 'auto-md']"
              :tag="'div'"
            )
              +b.c--hover_hask.pointer.TEXT-BEMTO(
                :config="'inline'"
                :weight="'medium'"
                :height="'sm'"
                :align="'center-till-md'"
                :size="['md', '2md-md', 'lg-lg']"
                :c="'green'"
                :tag="'p'"
                v-clipboard:copy="getAccountDetails.referral.referralLink"
                v-clipboard:success="() => notifyLabel()"
              ) {{ getAccountDetails.referral.referralLink }}
            +b.CELL(
              :cols="['12', 'narrow-md']"
              :tag="'div'"
            )
              +b.TEXT-BEMTO(
                :align="'center'"
                :weight="'bold'"
                :height="'sm'"
                :size="['md', '2md-md', 'lg-lg']"
                :c="'hask'"
                :tag="'p'"
              ) {{ _('Balance:') }} {{ getAccountDetails.referral.balance }} {{ _('USD') }}
            +b.CELL(
              :cols="['12', 'narrow-lg', '2-mac']"
              :tag="'div'"
              v-if="getAccountDetails.referral.balance >= 0"
            )
              toggle-mixin-modal(
                :component-name="'WithdrawRequest'"
                :parent-a-p-i="preperedBeforeAPI"
              )
                template(
                  #default="{handler}"
                )
                  +b.BUTTON-BEMTO(
                    :config="['anima', 'radius-xs', 'style-grn']"
                    :vision="'default'"
                    :align="'center'"
                    :tag="'button'"
                    @event="handler"
                  )
                    +b.pointer--initial.pa--l_md.--r_md.TEXT-BEMTO(
                      :config="'uppercase'"
                      :weight="'bold'"
                      :size="'sm'"
                      :c="'white'"
                      :tag="'p'"
                    ) {{ _("Offer") }}
        +b.ma--b_md.TAG-BEMTO(
          v-if="getAccountDetails.referral.balance >= 0"
          :config="'radius-sm'"
          :spaces="'md'"
          :bg="'white'"
          :block="'el-core'"
          :tag="'div'"
        )
          +b.ROW(
            :appearance="['spaced']"
            :space="'md'"
            :justify="'between'"
            :align="'center'"
            :tag="'div'"
          )
            +b.CELL(
              :cols="['12']"
              :tag="'div'"
            )
              +b.ma--b_md.TEXT-BEMTO(
                :weight="'medium'"
                :size="'lg'"
                :c="'hask'"
                :tag="'p'"
              ) {{ _("History") }}
              +b.JOINT-ENROLLMENT-TABLE(
                :object-list="paginateItemList"
              )
            +b.CELL(
              v-if="balance.length > pagination.pageSize"
              :cols="['12']"
              :tag="'div'"
            )
              +b.EL-PAGINATION(
                :current-page="pagination.currentPage"
                :per-page="pagination.pageSize"
                :total="balance.length"
                @page="pageChangedHandler($event)"
              )
</template>

<script>
import PopupMixin from '@/javascript/components/forms/joint/mixins/popup.mixin'
import { asyncForEach } from '@/javascript/utils/foreach.util'
import { mapActions, mapGetters } from 'vuex'

import { getEnrollmentListAPI } from '@/javascript/requests/services/balance.service'
import { deleteAccountPhoneAPI, patchAccountAvatarAPI } from '@/javascript/requests/services/account.service'

export default {
  name: 'JointPersonalInfo',
  mixins: [
    PopupMixin,
  ],
  async mounted() {
    await this.setHeaderTitleAction(
      this._('Person info')
    )
    await this.preperedBeforeAPI()
  },
  data() {
    return {
      balance: {},
      pagination: {
        currentPage: 1,
        pageSize: 10,
      },
      paginationData: {
        items: [],
        count: 0,
      },
    }
  },
  methods: {
    notifyLabel() {
      this.toggleToster(this._('Link copied'))
    },
    /**
     * Getting an up-to-date list of records, pagination
     * with update the following parameters.
     * @param {Object} options [description]
     */
    async getEnrollmentListAPI(options) {
      await getEnrollmentListAPI.execute(options)
        .then(answer => answer.json()).then(answer => {
          this.balance = answer.data.items
        })
    },
    /**
     * We prepare the data before requesting the server.
     */
    async preperedBeforeAPI() {
      /**
       * We will wait until everything is filled correctly.
       */
      const PREPARED_DATA = {
        page: this.paginationData.currentPage, // common
      }

      const READY_DATA = await this.delEmptyHandler(
        PREPARED_DATA // Prepared variable.
      )
      await this.getEnrollmentListAPI(
        READY_DATA
      )
    },
    async delEmptyHandler(data) {
      /**
       * The some () method checks if any element of
       * the array satisfies the condition specified
       * in the passed function.
       */
      await asyncForEach(
        Object.keys(data), async key => {
          if (!data[key]) delete data[key]
        }
      )
      return data
    },
    deleteAccountPhoneAPI(phone) {
      return deleteAccountPhoneAPI.execute(
        { id: phone.id }, { id: phone.id }
      ).then(() => {
        this.getAccountDetailsAPI()
      })
    },
    patchAccountAvatarAPI(element) {
      return patchAccountAvatarAPI.execute(
        {}, { avatar: element.avatar }
      ).then(() => {
        this.toggleToster(this._('Done!'))
        this.getAccountDetailsAPI()
      })
    },
    /**
     * Handler for pushing pagination.
     * Set current pagination.
     * @param {Number} number [description]
     */
    // async pageChangedHandler(number) {
    //   this.pagination.currentPage = number
    //   await this.preperedBeforeAPI()
    // },
    pageChangedHandler(number) {
      this.pagination.currentPage = number
    },
    ...mapActions('commonElement', [
      'setHeaderTitleAction',
    ]),
    ...mapActions('personalInfo', [
      'getAccountDetailsAPI',
    ]),
    paginate(items) {
      let result = []
      if (Array.isArray(items)) {
        const from = (this.pagination.currentPage * this.pagination.pageSize) - this.pagination.pageSize
        const to = this.pagination.currentPage * this.pagination.pageSize
        result = items.slice(from, to)
      }
      return result
    },
  },
  computed: {
    ...mapGetters('personalInfo', [
      'getAccountDetails',
    ]),
    paginateItemList() {
      return this.paginate(this.balance)
    },
  },
}
</script>
