<template lang="pug">
  include ../../../../Bemto
  +b.div Manager test
</template>

<script>
export default {
  name: 'ManagerTest',
}
</script>
