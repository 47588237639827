<template lang="pug">
include ../../../../Bemto
+b.chat-field
  +b.icon-cross.close-chat(
    @click="$emit('close-chat')"
  )
  +b.icon-chat
  +e.message-field(
    :ref="'track'"
  )
    +b.chat-track
      +b.chat-message(
        v-for='msg in story'
        v-if='id === msg.chat'
        :class="[user === msg.author ? 'chat-message--self': '']"
      )
        row
          cell(
            :cols="['12', '7-md']"
          )
            +b.P.msg-author {{ msg.author }}
          cell(
            :cols="['12', '5-md']"
          )
            +b.P.msg-data {{ msg.createdAt }}
        +b.P.msg-text {{ msg.text }}
        +b.A.msg-file(download v-if='msg.attachedFile' :href='msg.attachedFile') {{ msg.attachedFileName }}
  +e.VALIDATION-OBSERVER.message-input(
    ref='observer'
    tag='form'
  )
    +e.P.fileName(v-if='chFile') {{ chFile[0].name }}
    label.chat-label(
      for='chat-file'
    )
      +b.icon-paperclip.TEXT-BEMTO(
        :config="'pointer-none'"
        :c="'green'"
        :size="'md'"
        :tag="'i'"
      )
    input.chat-file(
      :name="'chat-file'"
      :id="'chat-file'"
      :type="'file'"
      @change='setFile($event)'
    )
    input.msg(
      name='msg'
      type='text'
      v-model='msg'
      @keydown.enter.prevent='sendMsg'
    )
    button.chat-btn.anima.radius-xs.style-grn(
      @click.prevent='sendMsg'
    )
      +b.P.icon-send
    +b.P.error-chat(
      v-if="errorMessages.nonFieldErrors"
    ) {{ errorMessages.nonFieldErrors[0].message }}
    +b.P.error-chat(
      v-if="errorMessages.attachedFile"
    ) {{ errorMessages.attachedFile[0].message }}
</template>

<script>

import { messageCreateAPI } from '@/javascript/requests/services/bid.service'
import FormsMixin, { getErrors } from '@/javascript/components/forms/joint/mixins/forms.mixin'

export default {
  name: 'chat-field',
  props: ['chat', 'id', 'story', 'state'],
  data() {
    return {
      user: window.USERNAME,
      msg: '',
      chFile: null,
      errorMessages: '',
    }
  },
  mixins: [
    FormsMixin,
  ],
  methods: {
    setFile(e) {
      const fileName = e.target.files[0].name
      const reader = new FileReader()
      reader.readAsDataURL(e.target.files[0])
      reader.onload = event => {
        this.chFile = [
          {
            attachedFile: `${fileName};${event.target.result}`,
            name: fileName,
          },
        ]
      }
    },
    sendMsg() {
      this.errorMessages = ''
      const message = {
        chat: this.id,
        text: this.msg,
      }
      if (this.chFile) {
        message.attachedFile = this.chFile
      }
      this.validateBeforeAPI(message)
    },
    sendHandlerAPI(data) {
      return messageCreateAPI.execute({}, data).then(() => {
        this.msg = ''
        this.chFile = null
      }).then(() => {
        const { track } = this.$refs
        track.scrollTo({
          top: track.scrollHeight,
          behavior: 'smooth',
        })
      })
    },
    setErrors(errors) {
      this.$refs.observer.setErrors(getErrors(errors))
      /* eslint-disable prefer-destructuring */
      this.errorMessages = errors
      this.chFile = null
    },
  },
}
</script>
