<template lang="pug">
include ../../../../Bemto
+b.ROW(
  :appearance='["spaced"]'
  :justify="justify"
  :space="space"
  :tag="'div'"
)
  +b.CELL(
    :cols="['12']"
  )
    +b.el-file-uploader(
      v-if="!objectItem.length"
    )
      +b.ma--t_xxs.--b_xxs.pa--t_xxs.--b_xxs.TEXT-BEMTO(
        :weight="'medium'"
        :height="'sm'"
        :size="['xs']"
        :c="'black'"
        :tag="'p'"
      ) {{ emptyDataTitle }}
    +b.el-file-uploader(
      v-if="objectItem.length"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :justify="'start'"
        :space="space"
        :tag="'div'"
      )
        +b.CELL(
          v-for="(item, index) in objectItem"
          :key="`${item.order}${index}`"
          :cols="['narrow']"
        )
          +b.el-file-uploader__element
            +b.ROW(
              :appearance='["spaced"]'
              :justify="'start'"
              :space="'xs'"
              :align="'center'"
              :tag="'div'"
            )
              +b.CELL(
                v-if="type === 'images'"
                :style="'display: flex;'"
                :cols="['narrow']"
              )
                +b.LINK-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'hask'"
                  :tag="'a'"
                  :href="item.photoLink"
                  download
                ) {{ item.name }}
              +b.CELL(
                v-if="type === 'files'"
                :style="'display: flex;'"
                :cols="['narrow']"
              )
                +b.LINK-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'hask'"
                  :tag="'a'"
                  :href="item.attachedFileLink"
                  download
                ) {{ item.name }}
</template>

<script>
export default {
  name: 'FileDemonstration',
  props: {
    emptyDataTitle: {},
    objectItem: {},
    justify: {},
    space: {},
    type: {},
  },
}
</script>
