<script>
export default {
  name: 'ToggleMixinModal',
  props: {
    componentName: {
      type: String,
      default: 'EmptyComponent',
    },
    objectItem: {
      required: false,
    },
    title: {
      required: false,
      type: String,
      default: '',
    },
    parentAPI: {
      required: false,
    },
    modalContainer: {
      type: String,
      default: 'modal--container',
    },
    modalClass: {
      type: String,
      default: 'modal--size_sm',
    },
  },
  render() {
    /** Template render. */
    return this.$scopedSlots.default({
      handler: this.toggleModalHandler,
    })
  },
  methods: {
    /** Component display handler. */
    toggleModalHandler() {
      this.$modal.show(() => import('../SampleModal'), {
        component: this.componentName,
        objectItem: this.objectItem,
        title: this.title,
        parentAPI: this.parentAPI,
      }, {
        classes: `modal ${this.modalContainer} ${this.modalClass}`,
      })
    },
  },
}
</script>
