<template lang="pug">
include ../../../../Bemto
div
  +b.ma--b_md.TAG-BEMTO(
    :config="'radius-sm'"
    :spaces="'md'"
    :bg="'white'"
    :block="'el-core'"
    :tag="'div'"
  )
    row(
      :align="'center'"
      :justify="['center','between-lg']"
    )
      cell.ma--b_sm
        row(
          :align="'center'"
        )
          cell
            +b.TEXT-BEMTO(
              :weight="'medium'"
              :size="['2md']"
              :c="'hask'"
              :tag="'p'"
            ) {{ objectItem.title }}
          cell
            row(
              :appearance="['spaced']"
              :align="'center'"
              :space="'xs'"
              :tag="'div'"
            )
              cell(
                :style="'display: flex;'"
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.icon-calendar.TEXT-BEMTO(
                  :size="'md'"
                  :c="'green'"
                  :tag="'i'"
                )
              cell(
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'sans'"
                  :tag="'p'"
                ) {{ objectItem.createAt || '-' }}
      //- cell.ma--b_sm
      //-   cell
      //-     chat-btn(
      //-       :countMessage='messageNotification'
      //-       @toggle-chat="$emit('sub-toggle')"
      //-       @create-chat="$emit('sub-create', objectItem.id)"
      //-     )
  +b.ma--b_md.TAG-BEMTO(
    :config="'radius-sm'"
    :spaces="'md'"
    :bg="'white'"
    :block="'el-core'"
    :tag="'div'"
  )
    row.ma--b_md
      cell(
        :cols="['12', '6-sm', '3-lg']"
      )
        +b.control-input
          +e.label {{ _("Тариф") }}
          +e.element.--initial.--green {{ objectItem.bidTransportation[0].tariff || '-' }}
      cell(
        :cols="['12', '6-sm', '3-lg']"
      )
        +b.control-input
          +e.label {{ _("Сумма счета") }}
          +e.element.--initial.--green {{ objectItem.bidTransportation[0].invoiceAmount || '-' }}
      cell(
        :cols="['12', '6-sm', '3-lg']"
      )
        +b.control-input
          +e.label {{ _("Счет") }}
          +e.element.--initial.--green
            row(
              :space="'xs'"
            )
              cell(
                v-for='(item, index) in objectItem.bidTransportation[0].bidTransportationInvoices'
                :key='index'
              )
                +b.A(
                  download
                  :href='item.attachedFileLink'
                )
                  +b.TEXT-BEMTO(
                    :weight="'bold'"
                    :config="['pointer-none']"
                    :size="'xs'"
                    :c="'green'"
                    :tag="'a'"
                  ) {{ _('File') + (index+1) }}
          //- file-demonstration.search-invoice(
          //-   :empty-data-title="_('Files not found')"
          //-   :object-item="objectItem.bidTransportation[0].bidTransportationInvoices"
          //-   :justify="'start'"
          //-   :space="'sm'"
          //-   :type="'files'"
          //- )
      cell(
        :cols="['12', '6-sm', '3-lg']"
      )
        +b.control-input
          +e.label {{ _("Оплата счета") }}
          +e.element.--initial.--green {{ (objectItem.bidTransportation[0].invoiceStatus || '-') + ' %' }}
    //- text-bemto.ma--b_md(
    //-   :weight="'medium'"
    //-   :size="['md']"
    //-   :c="'hask'"
    //-   :tag="'p'"
    //- ) {{ _("Текущее местоположение груза") }}
    div(
      v-if='objectItem.track'
      :style="{paddingTop: '40px', paddingBottom: '20px'}"
    )
      +b.shipping-progress-bar
        +b.shipping-point--start(
          :class="{'shipping-point--color-red' : objectItem.track.currentPosition.canceled}"
        )
        +b.shipping-point--end(
          :class="{'shipping-point--color-red' : objectItem.track.currentPosition.canceled}"
        )
        +b.shipping-circle(
          :class="{'shipping-circle--color-red': objectItem.track.currentPosition.canceled}"
          :style="{ left: objectItem.track.currentPosition.number ? `${Math.ceil((objectItem.track.currentPosition.number / objectItem.track.endPosition) * 100)}%` : '10%'}"
        )
          text-bemto.ma--b_md(
            :style="{position: 'absolute', top: '-40px'}"
            :size="['sm']"
            :align="'center'"
            :c="objectItem.track.currentPosition.canceled ? 'red' : 'difr'"
            :tag="'p'"
          ) {{ objectItem.track.currentPosition.text }}
          text-bemto(
            :class="deliveryType[objectItem.bidTransportation[0].deliveryType][0]"
            :tag="'i'"
            :size="'md'"
            :c="'white'"
          )
        +b.PROGRESS.el-progress(
          :class="{'el-progress--color-red' : objectItem.track.currentPosition.canceled}"
          v-if='objectItem.track'
          :max="objectItem.track.endPosition"
          :value="objectItem.track.currentPosition.number"
        )
    //- row(
    //-   :justify="'between'"
    //- )
    //-   cell
    //-     text-bemto.ma--t_md(
    //-       :tag="'p'"
    //-       :size="'xs'"
    //-     ) {{ _('Место отправления') }}
    //-   cell
    //-     text-bemto.ma--t_md(
    //-       :tag="'p'"
    //-       :size="'xs'"
    //-     ) {{ _('Место прибытия') }}
  row
    cell(
      :cols="['12', '6-lg']"
    )
      +b.ma--b_md.TAG-BEMTO(
        :config="'radius-sm'"
        :spaces="'xs'"
        :bg="'white'"
        :block="'el-core'"
        :tag="'div'"
      )
        row(
          :align="'center'"
          :justify="'between'"
        )
          cell
            text-bemto(
              :weight="'bold'"
              :size="['md']"
              :c="'hask'"
              :tag="'p'"
            ) {{ _('Отправитель -') }} {{ trasnportationType[objectItem.bidTransportation[0].senderDeliveryMethod] }}
          cell
            +b.accordion-switch(
              @click='state = !state'
            )
              +b.icon-sideleft.accordion-point(
                :class="{'accordion-point--active': state}"
              )
        vue-accordion.el-accordion-item(
          :expanded='state'
        )
          pickup-panel(
            v-if='objectItem.bidTransportation[0].senderDeliveryMethod === 490'
            :senderCountry='objectItem.bidTransportation[0].senderCountry'
            :senderRegion='objectItem.bidTransportation[0].senderRegion'
            :senderCity='objectItem.bidTransportation[0].senderCity'
            :senderStreet='objectItem.bidTransportation[0].senderStreet'
            :senderHouse='objectItem.bidTransportation[0].senderHouse'
            :senderContactPerson='objectItem.bidTransportation[0].senderContactPerson'
            :senderPhone='objectItem.bidTransportation[0].senderPhone'
            :senderDate='objectItem.bidTransportation[0].senderPickupDate'
            :senderDockAvailability='objectItem.bidTransportation[0].senderDockAvailability'
          )
          provider-panel(
            v-if='objectItem.bidTransportation[0].senderDeliveryMethod === 492'
            :senderCountry='objectItem.bidTransportation[0].senderCountry'
            :senderDate='objectItem.bidTransportation[0].senderPickupDate'
            :senderContactPerson='objectItem.bidTransportation[0].senderContactPerson'
            :senderPhone='objectItem.bidTransportation[0].senderPhone'
          )
          courier-panel(
            v-if='objectItem.bidTransportation[0].senderDeliveryMethod === 494'
            :senderCountry='objectItem.bidTransportation[0].senderCountry'
            :senderCourierService='objectItem.bidTransportation[0].senderCourierService'
            :senderTrack='objectItem.bidTransportation[0].senderTrack'
            :senderDate='objectItem.bidTransportation[0].senderPickupDate'
          )
    cell(
      :cols="['12', '6-lg']"
    )
      +b.ma--b_md.TAG-BEMTO(
        :config="'radius-sm'"
        :spaces="'xs'"
        :bg="'white'"
        :block="'el-core'"
        :tag="'div'"
      )
        row(
          :align="'center'"
          :space="'xs'"
        )
          cell
            text-bemto(
              :weight="'bold'"
              :size="['md']"
              :c="'hask'"
              :tag="'p'"
            ) {{ _('Тип перевозки -') }} {{ deliveryType[objectItem.bidTransportation[0].deliveryType][1] }}
          cell
            text-bemto(
              :class="deliveryType[objectItem.bidTransportation[0].deliveryType][0]"
              :tag="'i'"
              :size="'lg'"
              :c="'difr'"
            )
      +b.ma--b_md.TAG-BEMTO(
        :config="'radius-sm'"
        :spaces="'xs'"
        :bg="'white'"
        :block="'el-core'"
        :tag="'div'"
      )
        row(
          :align="'center'"
          :justify="'between'"
        )
          cell
            text-bemto(
              :weight="'bold'"
              :size="['md']"
              :c="'hask'"
              :tag="'p'"
            ) {{ _('Получатель') }} {{ objectItem.bidTransportation[0].recipient === 1 ? recipientType[objectItem.bidTransportation[0].recipientDestination] : '' }}
          cell
            +b.accordion-switch(
              @click='state2 = !state2'
            )
              +b.icon-sideleft.accordion-point(
                :class="{'accordion-point--active': state2}"
              )
        vue-accordion.el-accordion-item(
          :expanded='state2'
        )
          recipient-panel(
            v-if='objectItem.bidTransportation[0].recipient == 0'
            :recipientCountry='objectItem.bidTransportation[0].recipientCountry'
            :recipientRegion='objectItem.bidTransportation[0].recipientRegion'
            :recipientCity='objectItem.bidTransportation[0].recipientCity'
            :recipientAddress='objectItem.bidTransportation[0].recipientAddress'
            :recipientPostIndex='objectItem.bidTransportation[0].recipientPostIndex'
            :recipientContactPerson='objectItem.bidTransportation[0].recipientContactPerson'
            :recipientPhone='objectItem.bidTransportation[0].recipientPhone'
            :recipientDockAvailability='objectItem.bidTransportation[0].recipientDockAvailability'
          )
          ups-panel(
            v-if='objectItem.bidTransportation[0].recipientDestination === 506 && objectItem.bidTransportation[0].recipient == 1'
            :amazonCountry='objectItem.bidTransportation[0].amazonCountry'
            :amazonWarehouse='objectItem.bidTransportation[0].amazonWarehouse'
          )
          fba-panel(
            v-if='objectItem.bidTransportation[0].recipientDestination === 504 && objectItem.bidTransportation[0].recipient == 1'
            :amazonCountry='objectItem.bidTransportation[0].amazonCountry'
            :amazonRegions='objectItem.bidTransportation[0].amazonRegions'
            :amazonWarehouse='objectItem.bidTransportation[0].amazonWarehouse'
          )
  +b.ma--b_md.TAG-BEMTO(
    :config="'radius-sm'"
    :spaces="'xs'"
    :bg="'white'"
    :block="'el-core'"
    :tag="'div'"
  )
    row(
      :align="'center'"
      :justify="'between'"
    )
      cell
        text-bemto(
          :weight="'bold'"
          :size="['md']"
          :c="'hask'"
          :tag="'p'"
        ) {{ _('Данные о грузах') }}
      cell
        +b.accordion-switch(
          @click='state3 = !state3'
        )
          +b.icon-sideleft.accordion-point(
            :class="{'accordion-point--active': state3}"
          )
    vue-accordion.el-accordion-item(
      :expanded='state3'
    )
      +b.test-block
      +b.shipping-table(
        :style="{maxWidth: tableWidth}"
        v-if='objectItem.bidTransportation[0].bidTransportationCargo.length >= 1 && objectItem.bidTransportation[0].bidTransportationCargo[0].amount >= 1'
      )
        +b.line
          row
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('№') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Количество') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Тип контейнера') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Избыточный вес') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Тип упаковки') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Единицы измерения') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Длина') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Высота') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Ширина') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Объем') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Вес Нетто') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Общий вес') }}
        +b.line(
          v-for="(item, index) in objectItem.bidTransportation[0].bidTransportationCargo"
          :key='index'
        )
          row
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ index + 1 }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.amount }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.containerType }}
            cell.product-cell.item
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'green'"
                :tag="'p'"
              ) {{ item.overweight ? '+' : '-' }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.typePackaging }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.units }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.length }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.height }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.width }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.volume }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.netto }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.weight }}
      row.ma--t_xs(
        :align="'center'"
      )
        cell.ma--b_sm(
          :cols="['12', '6-sm', '4-lg']"
        )
          +b.control-input
            +e.label {{ _("Packing List") }}
            +e.element.--initial.--green
              +b.A(
                download
                v-if='objectItem.bidTransportation[0].packingList'
                :href='objectItem.bidTransportation[0].packingList'
              )
                +b.TEXT-BEMTO(
                  :config="['pointer-none']"
                  :weight="'bold'"
                  :size="'sm'"
                  :c="'green'"
                  :tag="'a'"
                ) {{ _('Packing list') }}
              +b.P(
                v-else
              ) -

        cell(
          v-if='objectItem.bidTransportation[0].customsClearance'
        )
          text-bemto(
            :weight="'bold'"
            :c="'green'"
            :size="['xs']"
            :tag="'p'"
          ) {{ _('Растаможить груз') }}
        cell(
          :cols="['2']"
        )
          text-bemto(
            :weight="'bold'"
            :c="'hask'"
            :size="['xs']"
            :tag="'p'"
          ) {{ _('Количество мест: ') + objectItem.bidTransportation[0].totalAmount }}
        cell(
          :cols="['2']"
        )
          text-bemto(
            :weight="'bold'"
            :c="'hask'"
            :size="['xs']"
            :tag="'p'"
          ) {{ _('Общий вес: ') + objectItem.bidTransportation[0].totalWeight }}
        cell(
          :cols="['2']"
        )
          text-bemto(
            :weight="'bold'"
            :c="'hask'"
            :size="['xs']"
            :tag="'p'"
          ) {{ _('Общий объем: ') + objectItem.bidTransportation[0].totalVolume }}
        cell(
          :cols="['12', '6-sm', '4-lg']"
        )
          +b.control-input
            +e.label {{ _("Дата готовности груза") }}
            +e.element.--initial.--green {{ objectItem.bidTransportation[0].dateReadiness || '-' }}
        cell(
          :cols="['12', '6-sm', '4-lg']"
        )
          +b.control-input
            +e.label {{ _("Класс опасных веществ") }}
            +e.element.--initial.--green {{ objectItem.bidTransportation[0].hazardousMaterialClass || '-' }}
        cell(
          :cols="['12', '6-sm', '4-lg']"
        )
          +b.control-input
            +e.label {{ _("MSDS Сертификат") }}
            +e.element.--initial.--green
              +b.LINK-BEMTO(
                :vision="'default'"
                :align="'center'"
                :tag="'a'"
                v-if='objectItem.bidTransportation[0].msds'
                download
                :href='objectItem.bidTransportation[0].msds'
              )
                +b.TEXT-BEMTO(
                  :config="['pointer-none']"
                  :weight="'bold'"
                  :size="'sm'"
                  :c="'green'"
                  :tag="'a'"
                ) {{ _('Sertificate file') }}
              +b.P(
                v-else
              ) -
  +b.ma--b_md.TAG-BEMTO(
    :config="'radius-sm'"
    :spaces="'xs'"
    :bg="'white'"
    :block="'el-core'"
    :tag="'div'"
    v-if='objectItem.bidTransportation[0].bidTransportationProducts.length >= 1'
  )
    row(
      :align="'center'"
      :justify="'between'"
    )
      cell
        text-bemto(
          :weight="'bold'"
          :size="['md']"
          :c="'hask'"
          :tag="'p'"
        ) {{ _('Дополнительные данные о товаре') }}
      cell
        +b.accordion-switch(
          @click='state4 = !state4'
        )
          +b.icon-sideleft.accordion-point(
            :class="{'accordion-point--active': state4}"
          )
    vue-accordion.el-accordion-item(
      :expanded='state4'
    )
      +b.shipping-table(
        v-if='objectItem.bidTransportation[0].bidTransportationProducts.length >= 1'
        :style="{maxWidth: tableWidth}"
      )
        +b.line--product
          row
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('№') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Название товара') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('HTS код') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Артикул (SKU)') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Тип упаковки') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Количество штук в коробке') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Стоимость единицы товара в $') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Область применения') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Технические характеристики') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Материал/хим. состав') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Производитель') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Торговая марка') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Страна происхождения') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Ссылка на фото') }}
            cell.product-cell
              text-bemto(
                :weight="'bold'"
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Ссылка на товар') }}
        +b.line--product(
          v-for="(item, index) in objectItem.bidTransportation[0].bidTransportationProducts"
          :key='index'
        )
          row
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ index + 1 }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.title }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.hts }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.sku }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.typePackaging }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.amount }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.cost }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.applicationArea }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.characteristics }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.composition }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.manufacturer }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.trademark }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.countryOrigin }}
            cell.product-cell.item
              text-bemto.lk(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.linkPhoto }}
            cell.product-cell.item
              text-bemto(
                :size="'2xs'"
                :c="'black'"
                :tag="'p'"
              ) {{ item.linkProduct }}
  +b.ma--b_md.TAG-BEMTO(
    :config="'radius-sm'"
    :spaces="'xs'"
    :bg="'white'"
    :block="'el-core'"
    :tag="'div'"
  )
    div(
      :ref="'accordion'"
    )
    row
      cell.ma--b_xxs(
        :cols="['12', '6-sm', '6-lg']"
      )
        +b.control-input
          +e.label {{ _("Валютa для тарифа") }}
          +e.element.--initial.--green {{ objectItem.bidTransportation[0].currency || '-' }}
      cell.ma--b_xxs(
        :cols="['12', '6-sm', '6-lg']"
      )
        +b.control-input
          +e.label {{ _("Стоимость груза по инвойсу") }}
          +e.element.--initial.--green {{ objectItem.bidTransportation[0].cost || '-' }}
      cell(
        :cols="['12', '6-sm', '6-lg']"
      )
        +b.control-input--full
          +e.label {{ _("Комментарий к заявке") }}
          +e.element.--full.--green(
          ) {{ objectItem.bidTransportation[0].comment || '-' }}
      cell(
        :cols="['12', '6-sm', '6-lg']"
      )
        +b.control-input
          +e.label {{ _("Загруженные файлы") }}
          +e.element.--initial.--green
            row(
              :space="'xs'"
            )
              cell(
                v-for="(item, index) in this.objectItem.bidTransportation[0].bidTransportationFiles"
                :key='index'
              )
                +b.A(
                  download
                  :href='item.attachedFile'
                )
                  +b.TEXT-BEMTO(
                    :weight="'bold'"
                    :config="['pointer-none']"
                    :size="'xs'"
                    :c="'green'"
                    :tag="'a'"
                  ) {{ _('File') + (index+1) }}
</template>

<script>

import VueAccordion from '@ztrehagem/vue-accordion'
import { mapActions } from 'vuex'

export default {
  name: 'ShippingOrderInfo',
  components: {
    VueAccordion,
  },
  props: {
    objectItem: {},
    messageNotification: Number,
  },
  async created() {
    /** Set header name on the page. */
    await this.setHeaderTitleAction(
      /* eslint-disable prefer-template */
      this.objectItem.crmNumber
    )
  },
  mounted() {
    this.getFilesName()
    this.setTableWidth()
  },
  data() {
    return {
      tableWidth: '500px',
      fileNames: [],
      state: true,
      state2: true,
      state3: true,
      state4: true,
      deliveryType: {
        44: ['icon-air', this._('АВИА')],
        46: ['icon-ship', this._('МОРЕ')],
        48: ['icon-shipping', this._('АВТО')],
      },
      trasnportationType: {
        490: this._('PickUp'),
        492: this._('Доставит поставщик'),
        494: this._('Доставит курьерская служба'),
      },
      recipientType: {
        504: this._('Склад FBA'),
        506: this._('UPS Pickup со склада экспедитора'),
      },
    }
  },
  methods: {
    setTableWidth() {
      this.tableWidth = window.getComputedStyle(this.$refs.accordion).width
      window.addEventListener('resize', () => {
        this.tableWidth = window.getComputedStyle(this.$refs.accordion).width
      })
    },
    getFilesName() {
      const files = this.objectItem.bidTransportation[0].bidTransportationFiles
      files.forEach(el => {
        this.fileNames.push(el.attachedFile.split('/')[8])
      })
    },
    ...mapActions('commonElement', [
      'setHeaderTitleAction',
    ]),
  },
}
</script>
