import { MANAGER_ENDPOINTS } from './ENDPOINTS'

const MANAGER_ROUTES = [
  {
    component: () => import('@/javascript/components/pages/roles/manager/test/Test'),
    path: MANAGER_ENDPOINTS.TEST,
    name: 'manager-test',
    meta: { permissions: ['manager'] },
  },
]

export default MANAGER_ROUTES
