import SearchRequest from './bids-order-form/SearchRequest'
import RedemptionRequest from './bids-order-form/RedemptionRequest'
import PaymentRequest from './bids-order-form/PaymentRequest'
import Signature from './cooperations/Signature'
import AddPhone from './personal-info/AddPhone'
import WithdrawRequest from './withdraw/WithdrawRequest'
import CourierForm from './transportation-forms/CourierForm'
import ProviderForm from './transportation-forms/ProviderForm'
import PickForm from './transportation-forms/PickForm'
import AddProduct from './transportation-forms/AddProduct'

export default function install(Vue) {
  Vue.component(SearchRequest.name, SearchRequest)
  Vue.component(RedemptionRequest.name, RedemptionRequest)
  Vue.component(PaymentRequest.name, PaymentRequest)
  Vue.component(Signature.name, Signature)
  Vue.component(AddPhone.name, AddPhone)
  Vue.component(WithdrawRequest.name, WithdrawRequest)
  Vue.component(CourierForm.name, CourierForm)
  Vue.component(ProviderForm.name, ProviderForm)
  Vue.component(PickForm.name, PickForm)
  Vue.component(AddProduct.name, AddProduct)
}
