<template lang="pug">
include ../../../../Bemto
row(
  :appearance="['spaced']"
  :align="'center'"
  :space="'sm'"
  :tag="'div'"
)
  cell(
    :cols="['12']"
    :tag="'div'"
  )
    row(
      :appearance="['spaced']"
      :align="'center'"
      :space="'sm'"
      :tag="'div'"
    )
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :align="'center'"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['narrow']"
            :tag="'div'"
          )
            +b.TEXT-BEMTO(
              :weight="'medium'"
              :size="['lg']"
              :c="'hask'"
              :tag="'p'"
            ) {{objectItem.title }}
          cell(
            :cols="['auto']"
            :tag="'div'"
          )
            row(
              :appearance="['spaced']"
              :align="'center'"
              :space="'xs'"
              :tag="'div'"
            )
              cell(
                :style="'display: flex;'"
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.icon-calendar.TEXT-BEMTO(
                  :size="'md'"
                  :c="'green'"
                  :tag="'i'"
                )
              cell(
                :cols="['narrow']"
                :tag="'div'"
              )
                +b.TEXT-BEMTO(
                  :weight="'medium'"
                  :height="'sm'"
                  :size="['sm']"
                  :c="'sans'"
                  :tag="'p'"
                ) {{ objectItem.createAt || '-' }}
          //- cell(
          //-   :cols="['narrow']"
          //-   :tag="'div'"
          //- )
          //-   chat-btn(
          //-     :countMessage='messageNotification'
          //-     @toggle-chat="$emit('sub-toggle')"
          //-     @create-chat="$emit('sub-create', objectItem.id)"
          //-   )
        div(
          v-if='objectItem.track'
          :style="{paddingTop: '60px', paddingBottom: '20px'}"
        )
          +b.shipping-progress-bar
            +b.shipping-point--start.--color-green(
              :class="{'shipping-point--color-red' : objectItem.track.currentPosition.canceled}"
            )
            +b.shipping-point--end.--color-green(
              :class="{'shipping-point--color-red' : objectItem.track.currentPosition.canceled}"
            )
            +b.shipping-circle--color-green(
              :class="{'shipping-circle--color-red': objectItem.track.currentPosition.canceled}"
              :style="{ left: objectItem.track.currentPosition.number ? `${Math.ceil((objectItem.track.currentPosition.number / objectItem.track.endPosition) * 100)}%` : '10%'}"
            )
              text-bemto.ma--b_md(
                :style="{position: 'absolute', top: '-40px'}"
                :size="['sm']"
                :c="objectItem.track.currentPosition.canceled ? 'red' : 'green'"
                :align="'center'"
                :tag="'p'"
              ) {{ objectItem.track.currentPosition.text }}
              text-bemto(
                :class="'icon-search'"
                :tag="'i'"
                :size="'md'"
                :c="'white'"
              )
            +b.PROGRESS.el-progress--color-green(
              :class="{'el-progress--color-red' : objectItem.track.currentPosition.canceled}"
              v-if='objectItem.track'
              :max="objectItem.track.endPosition"
              :value="objectItem.track.currentPosition.number"
            )
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        row(
          :appearance="['spaced']"
          :align="'center'"
          :space="'sm'"
          :tag="'div'"
        )
          cell(
            :cols="['12', '6-sm', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              //- Оцiнка замовника
              +e.label {{ _("Estimate owner") }}
              +e.element.--initial.--green {{ objectItem.bidSearch[0].estimate || '-' }}
          cell(
            :cols="['12', '6-sm', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              //- Iнвойс
              +e.label {{ _("Invoice") }}
              +e.element.--initial.--green
                row(
                  :space="'xs'"
                )
                  cell(
                    v-for='(file, index) in objectItem.bidSearch[0].bidSearchInvoices'
                    :key='index'
                  )
                    +b.A(
                      download
                      :href='file.attachedFileLink'
                    )
                      +b.TEXT-BEMTO(
                          :weight="'bold'"
                          :config="['pointer-none']"
                          :size="'xs'"
                          :c="'green'"
                          :tag="'a'"
                        ) {{ _('File') + (index+1) }}
          cell(
            :cols="['12', '6-sm', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              //- Сплата товару
              +e.label {{ _("Payment invoice") }}
              +e.element.--initial.--green {{ (objectItem.bidSearch[0].paymentInvoice || '-') + ' %' }}
          cell(
            :cols="['12', '6-sm', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              //- Вiдповiдь замовника
              +e.label {{ _("Respond owner") }}
              +e.element.--text.--full.--green {{ objectItem.bidSearch[0].respond || '-' }}
          cell(
            :cols="['12', '6-sm', '4-lg']"
            :tag="'div'"
          )
            +b.control-input
              //- Звiт замовника
              +e.label {{ _("Report owner") }}
              +e.element.--initial.--green
                +b.A(
                  download
                  v-if='objectItem.bidSearch[0].report'
                  :href='objectItem.bidSearch[0].report'
                )
                  +b.TEXT-BEMTO(
                    :weight="'bold'"
                    :config="['pointer-none']"
                    :size="'xs'"
                    :c="'green'"
                    :tag="'a'"
                  ) {{ _('File') }}
          //- cell(
          //-   :cols="['12', '6-sm', '4-lg']"
          //-   :tag="'div'"
          //- )
          //-   +b.control-input
          //-     //- Витрати на пошук
          //-     +e.label {{ _("Payment search") }}
          //-     +e.element.--initial.--green {{ '-' }}
      cell(
        :cols="['12', '6-sm']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Product name") }}
          +e.element.--initial.--green {{ objectItem.bidSearch[0].productName || '-' }}
      cell(
        :cols="['12', '6-sm']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Product counter") }}
          +e.element.--initial.--green {{ objectItem.bidSearch[0].amount || '-' }}
      cell(
        :cols="['12', '6-sm']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Product characteristics") }}
          +e.element.--text.--green {{ objectItem.bidSearch[0].characteristics || '-' }}
      cell(
        :cols="['12', '6-lg']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Product link") }}
          +e.element.--initial.--green
            +b.LINK-BEMTO(
              :vision="'default'"
              :align="'center'"
              :tag="'a'"
              :href='objectItem.bidSearch[0].linkAnalog'
            )
              +b.TEXT-BEMTO(
                :size="'xs'"
                :c="'green'"
                :tag="'a'"
              ) {{ objectItem.bidSearch[0].linkAnalog }}
      cell(
        :cols="['12']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Comments") }}
          +e.element--text.--green {{ objectItem.bidSearch[0].comment || '-' }}
  cell(
    v-if="Object.keys(objectItem).length"
    :cols="['12']"
    :tag="'div'"
  )
    row(
      :appearance="['spaced']"
      :align="'center'"
      :space="'sm'"
      :tag="'div'"
    )
      cell(
        :cols="['12', '6-mac']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Bid search images") }}
          +e.element.--initial.--green
            row(
              :space="'xs'"
            )
              cell(
                v-for='(image, index) in objectItem.bidSearch[0].bidSearchPhoto'
                :key='index'
              )
                +b.A(
                  download
                  :href='image.photoLink'
                )
                  +b.TEXT-BEMTO(
                      :weight="'bold'"
                      :config="['pointer-none']"
                      :size="'xs'"
                      :c="'green'"
                      :tag="'a'"
                    ) {{ _('Photo') + (index+1) }}
          //- file-demonstration(
          //-   :empty-data-title="_('Images not found')"
          //-   :object-item="objectItem.bidSearch[0].bidSearchPhoto"
          //-   :justify="'start'"
          //-   :space="'sm'"
          //-   :type="'images'"
          //- )
      cell(
        :cols="['12', '6-mac']"
        :tag="'div'"
      )
        +b.control-input
          +e.label {{ _("Bid search files") }}
          +e.element.--initial.--green
            row(
              :space="'xs'"
            )
              cell(
                v-for='(file, index) in objectItem.bidSearch[0].bidSearchFiles'
                :key='index'
              )
                +b.A(
                  download
                  :href='file.attachedFileLink'
                )
                  +b.TEXT-BEMTO(
                      :weight="'bold'"
                      :config="['pointer-none']"
                      :size="'xs'"
                      :c="'green'"
                      :tag="'a'"
                    ) {{ _('File') + (index+1) }}
          //- file-demonstration(
          //-   :empty-data-title="_('Files not found')"
          //-   :object-item="objectItem.bidSearch[0].bidSearchFiles"
          //-   :justify="'start'"
          //-   :space="'sm'"
          //-   :type="'files'"
          //- )
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: 'SearchOrderInfo',
  props: {
    objectItem: {},
    messageNotification: Number,
  },
  async created() {
    /** Set header name on the page. */
    await this.setHeaderTitleAction(
      /* eslint-disable prefer-template */
      this.objectItem.crmNumber
    )
  },
  methods: {
    ...mapActions('commonElement', [
      'setHeaderTitleAction',
    ]),
  },
}
</script>
