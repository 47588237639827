<template lang="pug">
include ../../../Bemto
+b.mobile-menu
  +b.el-circle--size_medium.--align_center.c--black.--hover_green.pointer(
    @click.prevent='toggle'
  )
    +b.icon-menu_open.TEXT-BEMTO(
      :size="'sm'"
      :tag="'i'"
    )
  +b.el-menu--routers(:class="drawer")
    +e.overlay(:class="overlay")
    +e.sidebar--router(:class="sidebar")
      +e.header.pa--l_md.--r_md
        +e.close--router.el-circle--size_medium.--align_center.c--black.--hover_green.pointer(
          @click.prevent='toggle'
        )
          +b.icon-menu_close.TEXT-BEMTO(
            :size="'sm'"
            :tag="'i'"
          )
      +e.body
        +b.EL-ROUTER()
</template>

<script>
export default {
  name: 'ElRouterMenu',
  data() {
    return {
      /** Menu vision. */
      vision: false,
    }
  },
  methods: {
    /** Switch !active state menu. */
    toggle() {
      this.vision = !this.vision
    },
  },
  computed: {
    /**
     * Handlers that affect the display
     * of the component.
     */
    button() {
      return (this.vision)
        ? 'icon-menu_close'
        : 'icon-menu_open'
    },
    drawer() {
      return (this.vision)
        ? 'el-menu--isactive'
        : 'el-menu--inactive'
    },
    overlay() {
      return (this.vision)
        ? 'el-menu__overlay--isactive'
        : 'el-menu__overlay--inactive'
    },
    sidebar() {
      return (this.vision)
        ? 'el-menu__sidebar--isactive-router'
        : 'el-menu__sidebar--inactive-router'
    },
  },
}
</script>
