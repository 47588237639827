import ActiveOrders from './active-orders/ActiveOrders'
import PaymentOrderInfo from './order-details/PaymentOrderInfo'
import RansomOrderInfo from './order-details/RansomOrderInfo'
import SearchOrderInfo from './order-details/SearchOrderInfo'
import ShippingOrderInfo from './order-details/ShippingOrderInfo'
import CommonPanel from './personal-info/CommonPanel'
import EmailPanel from './personal-info/EmailPanel'
import PassPanel from './personal-info/PassPanel'
import PhonePanel from './personal-info/PhonePanel'
import AboutPanel from './personal-info/AboutPanel'
import PickUp from './zebra/PickUp'
import Provider from './zebra/Provider'
import Courier from './zebra/Courier'
import Recipient from './zebra/Recipient'
import Ups from './zebra/Ups'
import Fba from './zebra/Fba'

export default function install(Vue) {
  Vue.component(ActiveOrders.name, ActiveOrders)
  Vue.component(PaymentOrderInfo.name, PaymentOrderInfo)
  Vue.component(RansomOrderInfo.name, RansomOrderInfo)
  Vue.component(SearchOrderInfo.name, SearchOrderInfo)
  Vue.component(ShippingOrderInfo.name, ShippingOrderInfo)
  Vue.component(CommonPanel.name, CommonPanel)
  Vue.component(EmailPanel.name, EmailPanel)
  Vue.component(PassPanel.name, PassPanel)
  Vue.component(PhonePanel.name, PhonePanel)
  Vue.component(AboutPanel.name, AboutPanel)
  Vue.component(PickUp.name, PickUp)
  Vue.component(Provider.name, Provider)
  Vue.component(Courier.name, Courier)
  Vue.component(Recipient.name, Recipient)
  Vue.component(Ups.name, Ups)
  Vue.component(Fba.name, Fba)
}
