<template lang="pug">
include ../../../Bemto
+b.el-header-navigation(
  @mouseleave="hideAction"
)
  +b.ROW(
    :appearance='["spaced"]'
    :align="'center'"
    :space="'md'"
    :tag="'div'"
  )
    +b.CELL(
      :cols="['narrow']"
    )
      +b.el-header-bell.pointer.bell-inamation.ROUTER-LINK(
        :to="{ name: 'notifications' }"
      )
        +b.pointer--initial.ROW(
          :appearance='["spaced"]'
          :align="'center'"
          :space="'md'"
          :tag="'div'"
        )
          +b.CELL(
            :style="'display: flex;'"
            :cols="['narrow']"
          )
            +b.icon-bell.TEXT-BEMTO(
              :weight="'medium'"
              :size="'lg'"
              :c="'grub'"
              :tag="'i'"
            )
        +e.counter
          +e.text.TEXT-BEMTO(
            :weight="'medium'"
            :height="'md'"
            :size="'xxs'"
            :c="'white'"
            :tag="'p'"
          ) {{ notify }}
    +b.pointer.CELL(
      :cols="['narrow']"
      @click.prevent="toggleAction"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :align="'center'"
        :space="'sm'"
        :tag="'div'"
      )
        +b.CELL(
          :cols="['narrow']"
        )
          +b.el-circle--size_medium.pointer--initial(
            :style="{position: 'relative'}"
            v-if="getAccountDetails"
          )
            +b.el-picture--absolute.PICTURE
              +e.element--cover.IMG(
                v-if="!getAccountDetails.avatar"
                :src="'/static/pictures/noimage.jpg'"
                :alt="'Profile avatar'"
              )
              +e.element--cover.IMG(
                v-if="getAccountDetails.avatar"
                :src="getAccountDetails.avatar"
                :alt="'Profile avatar'"
              )
        +b.CELL(
          :cols="['narrow']"
        )
          +b.name.TEXT-BEMTO(
            v-if="getAccountDetails"
            :weight="'medium'"
            :height="'lg'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ getAccountDetails.firstName }} {{ getAccountDetails.lastName }}
        +b.CELL(
          :cols="['narrow']"
        )
          +b.name-toggler__icon--toggle.toggle.TEXT-BEMTO(
            :class="dropdownIN(dropdown)"
            :weight="'medium'"
            :size="'xs'"
            :c="'grub'"
            :tag="'i'"
          )
  +e.dropdown(
    v-if="dropdown"
  )
    +b.ROW(
      :appearance='["spaced"]'
      :align="'center'"
      :space="'xs'"
      :tag="'div'"
    )
      +b.ROW(
        :appearance='["spaced"]'
        :align="'center'"
        :space="'xs'"
        :tag="'div'"
      )
        +b.CELL
          row(
            :align="'center'"
          )
            cell
              +b.TEXT-BEMTO(
                :weight="'medium'"
                :height="'xl'"
                :size="'sm'"
                :align="'center'"
                :c="'black'"
                :tag="'p'"
              ) {{ _('Language: ') }}
            cell(
              v-for="lang in languages"
              :key="lang.label"
              :cols="'1'"
            )
              +b.c--hover_hask.LINK-BEMTO(
                :weight="'medium'"
                :height="'xl'"
                :size="'sm'"
                :c="language === lang.label ? 'hask' : 'green'"
                :href="getLanguageLink(lang.label)"
                :tag="'a'"
              ) {{ lang.title }}
      +b.CELL(
        :cols="['12']"
      )
        +b.TEXT-BEMTO(
          :weight="'medium'"
          :height="'lg'"
          :size="'sm'"
          :align="'center'"
          :c="'black'"
          :tag="'p'"
        ) {{ getAccountDetails.email }}
      +b.CELL(
        :cols="['12']"
      )
        +b.BUTTON-BEMTO(
          :config="['anima', 'radius-xs', 'style-brd']"
          :vision="'default'"
          :align="'center'"
          :tag="'button'"
          @event="preparedBeforeSending()"
        )
          +b.button--vision_fulllink.ROUTER-LINK(
            to="person-info"
          )
          +b.pointer--initial.TEXT-BEMTO(
            :weight="'medium'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ _('Person info') }}
      +b.CELL(
        :cols="['12']"
      )
        +b.el-router__link--core.pointer(
          :style="'--bg: #46ceac'"
          @click.prevent="postLogoutAPI()"
        )
          +b.TEXT-BEMTO(
            :config="'full'"
            :weight="'medium'"
            :height="'md'"
            :size="'xs'"
            :align="'center'"
            :c="'white'"
            :tag="'p'"
          ) {{ _('Выход') }}
</template>

<script>
import { postLogoutAPI } from '@/javascript/requests/services/auth.service'
import { createSocketConnectionSync } from '@/javascript/utils/centrifuge'
import { notificationsList } from '@/javascript/requests/services/notifications.service'
import { mapGetters } from 'vuex'
import { prefixLanguage, CURRENT_LANGUAGE } from '@/javascript/utils/urls.util'

export default {
  name: 'ElNavigations',
  data() {
    return {
      notify: null,
      dropdown: false,
      language: CURRENT_LANGUAGE,
      languages: [
        { label: 'ru', title: this._('RU') },
        { label: 'uk', title: this._('UA') },
        { label: 'en', title: this._('EN') },
      ],
    }
  },
  mounted() {
    this.getNotify()
    setTimeout(() => {
      const centrifuge = createSocketConnectionSync()
      centrifuge.subscribe(window.USER_CHANNEL, this.handleEvent.bind(this))
    })
  },
  methods: {
    getLanguageLink(language) {
      return prefixLanguage(window.location.href, { language })
    },

    getNotify() {
      notificationsList.execute({}).then(({ data: { pagination: { count } } }) => {
        this.notify = count
      })
    },
    handleEvent(event) {
      if ('notification' === event.data.data.type) {
        this.notify++
      }
    },
    /**
     * The dispatch handler, if it is confirmed, then we make a request
     * to the server with parameters.
     * @param {Object} data [description]
     */
    postLogoutAPI() {
      return postLogoutAPI.execute({}).then(() => {
        document.location.reload()
      })
    },
    /**
     * Show or hide the Dropdown of items.
     */
    toggleAction() {
      this.dropdown = !this.dropdown
    },
    /**
     * Hide Dropdown after miss target.
     */
    hideAction() {
      this.dropdown = false
    },
    dropdownIN(side) {
      /* eslint-disable */
      return (side) ? 'icon-toggle-up'
                    : 'icon-toggle-down'
    },
  },
  computed: {
    ...mapGetters('personalInfo', [
      'getAccountDetails',
    ]),
  },
}
</script>
