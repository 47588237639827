<template lang="pug">
include ../../../../Bemto
div
  +b.el-zebra__element--black.ma--t_sm
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Страна') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ recipientCountry || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Область/штат') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ recipientRegion || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :size="'sm'"
          :tag="'p'"
        ) {{ _('Город') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ recipientCity || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Адрес') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ recipientAddress || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Индекс') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ recipientPostIndex || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Контактное лицо') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ recipientContactPerson || '-'}}
  +b.el-zebra__element--black
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Телефон') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto.zebra-indents(
          :weight="'bold'"
          :size="'sm'"
          :tag="'p'"
        ) {{ recipientPhone || '-'}}
  +b.el-zebra__element--white
    row(
      :style="'width: 100%'"
      :justify="'between'"
    )
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :tag="'p'"
          :size="'sm'"
        ) {{ _('Наличие дока') }}
      cell(
        :cols="['12', 'narrow-sm']"
      )
        text-bemto(
          :style="{transform: 'scale(2)'}"
          :c="'green'"
          :weight="'bold'"
          :tag="'p'"
        ) {{ recipientDockAvailability ? '+' : '-'}}
</template>

<script>
export default {
  name: 'recipient-panel',
  props: ['recipientCountry', 'recipientPhone', 'recipientContactPerson', 'recipientDockAvailability', 'recipientRegion', 'recipientCity', 'recipientAddress', 'recipientPostIndex'],
}
</script>
