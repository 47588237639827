<template lang="pug">
include ../../../Bemto
+b.mobile-menu
  +b.el-circle--size_medium.--align_center.c--black.--hover_green.pointer(
    @click.prevent='toggle'
  )
    +b.IMG(
      v-if='details.avatar'
      :src='details.avatar'
    )
    +b.IMG(
      v-else
      src='/static/pictures/noimage.jpg'
    )
  +b.el-menu(:class="drawer")
    +e.overlay(:class="overlay")
    +e.sidebar(:class="sidebar")
      +e.header.pa--l_md.--r_md
        +b.el-header-bell--menu.pointer.bell-inamation.ROUTER-LINK(
          :to="{ name: 'notifications' }"
        )
          +b.pointer--initial.ROW(
            :appearance='["spaced"]'
            :align="'center'"
            :space="'md'"
            :tag="'div'"
          )
            +b.CELL(
              :style="'display: flex;'"
              :cols="['narrow']"
            )
              +b.icon-bell.TEXT-BEMTO(
                :weight="'medium'"
                :size="'lg'"
                :c="'grub'"
                :tag="'i'"
              )
          +e.counter
            +e.text.TEXT-BEMTO(
              :weight="'medium'"
              :height="'md'"
              :size="'xxs'"
              :c="'white'"
              :tag="'p'"
            ) {{ notify }}
        +e.close.el-circle--size_medium.--align_center.c--black.--hover_green.pointer(
          @click.prevent='toggle'
        )
          +b.icon-menu_close.TEXT-BEMTO(
            :size="'sm'"
            :tag="'i'"
          )
        slot(name='fulltime-info')
      +e.body
        row.ma--t_sm.ma--b_sm(
          :align="'center'"
          :justify="'center'"
        )
          cell
            +b.TEXT-BEMTO(
              :weight="'medium'"
              :height="'xl'"
              :size="'sm'"
              :align="'center'"
              :c="'black'"
              :tag="'p'"
            ) {{ _('Language: ') }}
          cell(
            v-for="lang in languages"
            :key="lang.label"
            :cols="'1'"
          )
            +b.c--hover_hask.LINK-BEMTO(
              :weight="'medium'"
              :height="'xl'"
              :size="'sm'"
              :c="language === lang.label ? 'hask' : 'green'"
              :href="getLanguageLink(lang.label)"
              :tag="'a'"
            ) {{ lang.title }}
        slot(name='personal-info')
        slot(name='email')
        slot(name='phone')
        +b.ma--l_md.ma--t_sm.CELL(
          :cols="['10']"
        )
          +b.BUTTON-BEMTO(
            :config="['anima', 'radius-xs', 'style-brd']"
            :vision="'default'"
            :align="'center'"
            :tag="'button'"
            @event="preparedBeforeSending()"
          )
            +b.button--vision_fulllink.ROUTER-LINK(
              to="person-info"
            )
            +b.pointer--initial.TEXT-BEMTO(
              :weight="'medium'"
              :size="'sm'"
              :c="'black'"
              :tag="'p'"
            ) {{ _("Person info") }}
        slot(name='exit')
</template>

<script>

import { getAccountDetailsAPI } from '@/javascript/requests/services/account.service'
import { notificationsList } from '@/javascript/requests/services/notifications.service'
import { createSocketConnectionSync } from '@/javascript/utils/centrifuge'
import { prefixLanguage, CURRENT_LANGUAGE } from '@/javascript/utils/urls.util'

export default {
  name: 'ElCommonMenu',
  data() {
    return {
      notify: null,
      /** Menu vision. */
      vision: false,
      details: '',
      language: CURRENT_LANGUAGE,
      languages: [
        { label: 'ru', title: this._('RU') },
        { label: 'uk', title: this._('UA') },
        { label: 'en', title: this._('EN') },
      ],
    }
  },
  mounted() {
    this.accDetail()
    this.getNotify()
    setTimeout(() => {
      const centrifuge = createSocketConnectionSync()
      centrifuge.subscribe(window.USER_CHANNEL, this.handleEvent.bind(this))
    })
  },
  methods: {
    getLanguageLink(language) {
      return prefixLanguage(window.location.href, { language })
    },
    getNotify() {
      notificationsList.execute({}).then(({ data: { pagination: { count } } }) => {
        this.notify = count
      })
    },
    handleEvent(event) {
      if ('notification' === event.data.data.type) {
        this.notify++
      }
    },
    /** Switch !active state menu. */
    toggle() {
      this.vision = !this.vision
    },
    accDetail() {
      getAccountDetailsAPI.execute({}).then(({ data: { item } }) => {
        this.details = item
      })
    },
  },
  computed: {
    /**
     * Handlers that affect the display
     * of the component.
     */
    button() {
      return (this.vision)
        ? 'icon-menu_close'
        : 'icon-menu_open'
    },
    drawer() {
      return (this.vision)
        ? 'el-menu--isactive'
        : 'el-menu--inactive'
    },
    overlay() {
      return (this.vision)
        ? 'el-menu__overlay--isactive'
        : 'el-menu__overlay--inactive'
    },
    sidebar() {
      return (this.vision)
        ? 'el-menu__sidebar--isactive-common'
        : 'el-menu__sidebar--inactive-common'
    },
  },
}
</script>
