import { prefixLanguage } from '@/javascript/utils/urls.util'

export const COMMON_ROUTES = [
  // {
  //   component: () => import('@/javascript/components/pages/joint/bids-community/payment/PaymentRequest'),
  //   path: prefixLanguage('/cabinet/joint/product-payment/'),
  //   name: 'product-payment',
  //   meta: { permissions: ['administrator', 'manager', 'client'] },
  // },
  {
    component: () => import('@/javascript/components/pages/joint/bids-community/ransom/RedemptionGoods'),
    path: prefixLanguage('/cabinet/joint/product-ransom/'),
    name: 'product-ransom',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/joint/bids-community/search/ProductSearch'),
    path: prefixLanguage('/cabinet/joint/product-search/'),
    name: 'product-search',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/joint/bids-community/transport/TransportRequest'),
    path: prefixLanguage('/cabinet/joint/transport-request/'),
    name: 'transport-request',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/joint/apps-community/actives/ActiveOrders'),
    path: prefixLanguage('/cabinet/joint/active-orders/'),
    name: 'active-orders',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/joint/apps-community/dones/CompletedApp'),
    path: prefixLanguage('/cabinet/joint/done-orders/'),
    name: 'done-orders',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/joint/cooperations/Cooperation'),
    path: prefixLanguage('/cabinet/joint/cooperations/'),
    name: 'cooperations',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/joint/apps-community/details/OrderDetails'),
    path: prefixLanguage('/cabinet/joint/order-details/id/:id/'),
    name: 'order-details',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/joint/notifications/Notifications'),
    path: prefixLanguage('/cabinet/joint/notifications/'),
    name: 'notifications',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
  {
    component: () => import('@/javascript/components/pages/roles/Common'),
    path: prefixLanguage('/cabinet/joint/person-info/'),
    name: 'person-info',
    meta: { permissions: ['administrator', 'manager', 'client'] },
  },
]

export const CORE_MENU_ITEMS = self => [
  {
    roles: ['administrator', 'manager', 'client'],
    title: self._('Product search request'),
    name: 'product-search',
    icon: 'icon-search',
    bg: '#46ceac',
  },
  // {
  //   roles: ['administrator', 'manager', 'client'],
  //   title: self._('Product ransom request'),
  //   name: 'product-ransom',
  //   icon: 'icon-basket',
  //   bg: '#4fc0e8',
  // },
  // {
  //   roles: ['administrator', 'manager', 'client'],
  //   title: self._('Product pay request'),
  //   name: 'product-payment',
  //   icon: 'icon-paymen',
  //   bg: '#4080d3',
  // },
  {
    roles: ['administrator', 'manager', 'client'],
    title: self._('Product ransom request'),
    name: window.TG_BOT,
    icon: 'icon-paymen',
    bg: '#4080d3',
  },
  {
    roles: ['administrator', 'manager', 'client'],
    title: self._('Transport request'),
    name: 'transport-request',
    icon: 'icon-shipping',
    bg: '#9579da',
  },
]

export const SUBS_MENU_ITEMS = self => [
  {
    roles: ['administrator', 'manager', 'client'],
    title: self._('Active orders'),
    name: 'active-orders',
    icon: 'icon-active-order',
    bg: '#ffffff',
  },
  {
    roles: ['administrator', 'manager', 'client'],
    title: self._('Done orders'),
    name: 'done-orders',
    icon: 'icon-completed-app',
    bg: '#ffffff',
  },
  {
    roles: ['administrator', 'manager', 'client'],
    title: self._('Cooperations'),
    name: 'cooperations',
    icon: 'icon-cooperations',
    bg: '#ffffff',
  },
  {
    roles: ['administrator', 'manager', 'client'],
    title: self._('Person info'),
    name: 'person-info',
    icon: 'icon-personal-info',
    bg: '#ffffff',
  },
]
