<template lang="pug">
include ../../../../Bemto
+b.el-table
  +e.table.TABLE
    +e.thead.THEAD
      +e.tr.TR
        +e.th.TH
          +e.cell-wrapper
            +b.TEXT-BEMTO(
              :weight="'bold'"
              :size="'sm'"
              :c="'black'"
              :tag="'p'"
            ) {{ _("Date") }}
        +e.th.TH
          +e.cell-wrapper
            +b.TEXT-BEMTO(
              :weight="'bold'"
              :size="'sm'"
              :c="'black'"
              :tag="'p'"
            ) {{ _("Transit") }}
        +e.th.TH
          +e.cell-wrapper
            +b.TEXT-BEMTO(
              :weight="'bold'"
              :size="'sm'"
              :c="'black'"
              :tag="'p'"
            ) {{ _("Summ") }}
    +e.tbody.TBODY(
      v-if="!objectList.length"
    )
      +b.pa--f_md.TEXT-BEMTO(
        :weight="'regular'"
        :size="'sm'"
        :c="'black'"
        :tag="'p'"
      ) {{ _('Empty table') }}
    +e.tbody.TBODY(
      v-if="objectList.length"
    )
      +e.tr.TR(
        v-for="item in objectList"
      )
        +e.td.TD
          +b.TEXT-BEMTO(
            :weight="'regular'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ item.createAt || '-' }}
        +e.td.TD
          +b.TEXT-BEMTO(
            :weight="'regular'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ item.status || '-' }}
        +e.td.TD
          +b.TEXT-BEMTO(
            :weight="'regular'"
            :size="'sm'"
            :c="'black'"
            :tag="'p'"
          ) {{ item.amount || '-' }}
</template>

<script>
export default {
  name: 'JointEnrollmentTable',
  props: {
    objectList: {},
  },
}
</script>
