<template lang="pug">
  include ../../../../Bemto
  +b.div User test
</template>

<script>
export default {
  name: 'UserTest',
}
</script>
